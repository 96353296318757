var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("p", { staticClass: "aligncenter" }, [
        _c("img", {
          staticStyle: { height: "100px", "max-width": "300px" },
          attrs: { src: "data:image/jpg;base64," + _vm.logo }
        })
      ]),
      _vm._v(" "),
      [
        _c("h2", [
          _vm._v(
            "Thank you for completing the evaluation. Your Certificate will be emailed to you."
          )
        ])
      ]
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }