var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    _vm._l(_vm.templates, function(template) {
      return _c(
        "div",
        { key: template.emailType, attrs: { value: template } },
        [
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 4 } }, [
                _c("span", { staticStyle: { "font-weight": "bold" } }, [
                  _vm._v(_vm._s(template.emailType))
                ])
              ]),
              _vm._v(" "),
              _c("el-col", { attrs: { span: 20 } }, [
                _c(
                  "p",
                  {
                    staticStyle: {
                      "white-space": "pre",
                      "overflow-x": "scroll"
                    }
                  },
                  [_vm._v(_vm._s(template.emailText))]
                )
              ])
            ],
            1
          )
        ],
        1
      )
    })
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }