<template>
  <div class="app-container">
    <div v-if="participantsUploaded == false" style="margin: 20px">
      <div>
        <el-col :span="6" style="line-height: 30px">
          <span>Please upload participants list:</span>
        </el-col>
        <el-col :span="4">
          <el-upload
            :action="uploadBaseUrl + '/participant/UploadParticipants'"
            :multiple="false"
            :auto-upload="true"
            :show-file-list="false"
            :headers="headerInfo"
            :on-success="onParticipantsUploadSuccess"
            :on-error="onFileUploadError"
            class="upload"
            name="Content"
          >
            <el-button size="small" type="primary">Upload</el-button>
          </el-upload>
        </el-col>
      </div>
    </div>
    <div v-if="participantsUploaded">
      <div class="filter-container">
        <el-button
          class="filter-item"
          style="margin-left: 10px"
          type="primary"
          icon="el-icon-edit"
          @click="handleCreate"
          >{{ $t("table.add") }}</el-button
        >
      </div>
      <el-table
        key="participants"
        :data="participants"
        border
        fit
        highlight-current-row
        style="width: 100%"
      >
        <el-table-column label="Project #" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.project }}</span>
          </template>
        </el-table-column>

        <el-table-column label="Certificate Issued Date" align="center">
          <template slot-scope="scope">
            <span>{{
              scope.row.certificateIssuedDate
                ? new Date(scope.row.certificateIssuedDate).toLocaleDateString(
                    "en-US"
                  )
                : ""
            }}</span>
          </template>
        </el-table-column>

        <el-table-column label="Application/Participation Date" align="center">
          <template slot-scope="scope">
            <span>{{
              scope.row.applicationParticipationDate
                ? new Date(
                    scope.row.applicationParticipationDate
                  ).toLocaleDateString("en-US")
                : ""
            }}</span>
          </template>
        </el-table-column>

        <el-table-column label="End Date" align="center">
          <template slot-scope="scope">
            <span>{{
              scope.row.endDate
                ? new Date(scope.row.endDate).toLocaleDateString("en-US")
                : ""
            }}</span>
          </template>
        </el-table-column>

        <el-table-column label="Degree" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.degree }}</span>
          </template>
        </el-table-column>

        <el-table-column label="Credit Request Type" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.creditRequestType }}</span>
          </template>
        </el-table-column>

        <el-table-column label="Last Name" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.lastName }}</span>
          </template>
        </el-table-column>

        <el-table-column label="First Name" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.firstName }}</span>
          </template>
        </el-table-column>

        <el-table-column label="Email Address" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.emailAddress }}</span>
          </template>
        </el-table-column>

        <el-table-column label="Do Not Contact" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.doNotContact" style="color: green">X</span>
            <span v-else style="color: gray" />
          </template>
        </el-table-column>

        <el-table-column label="Credit Hrs Issued" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.creditHrsIssued }}</span>
          </template>
        </el-table-column>

        <el-table-column label="Location" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.location }}</span>
          </template>
        </el-table-column>

        <el-table-column label="Speaker's Name" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.speakersName }}</span>
          </template>
        </el-table-column>

        <el-table-column
          :label="$t('table.actions')"
          align="center"
          width="230"
          class-name="small-padding fixed-width"
        >
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="handleUpdate(scope.row)"
              >{{ $t("table.edit") }}</el-button
            >
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.row)"
              >{{ $t("table.delete") }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div style="margin-top: 40px">
        <el-form
          ref="dataForm"
          :rules="rules"
          :model="certificateData"
          label-position="left"
          label-width="170px"
          style="width: 800px"
        >
          <el-row>
            <el-col :span="12">
              <el-form-item label="Series/Activity" prop="seriesActivityName">
                <el-select
                  v-model="certificateData.seriesActivityName"
                  value-key="id"
                  filterable
                  placeholder="Select"
                >
                  <el-option
                    v-for="item in seriesActivities"
                    :key="item.id"
                    :label="item.name"
                    :value="item"
                  />
                </el-select>
              </el-form-item>
            </el-col>

            <el-col :span="11" class="columnMarginLeft">
              <el-form-item label="Date to generate cert" prop="generateDate">
                <el-date-picker
                  v-model="certificateData.generateDate"
                  type="date"
                  format="MM/dd/yyyy"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="Credits" prop="credits">
                <el-input-number
                  v-model="certificateData.credits"
                  :precision="2"
                  :step="0.25"
                  :min="0"
                  :max="1000"
                  style="vertical-align: top"
                  controls-position="right"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12" class="certificateContainer">
              <el-form-item label="Certificates" prop="availableCerts">
                <span
                  v-for="certificate in certificateData.availableCerts"
                  :key="certificate.id"
                  style="margin-left: 10px"
                  >{{ certificate.board }}</span
                >
                <!-- <el-checkbox-group v-model="checkedCertificates">
                  <el-checkbox
                    v-for="certificate in certificates"
                    :label="certificate.board"
                    :key="certificate.id"
                  >{{certificate.board}}</el-checkbox>
                </el-checkbox-group>-->
              </el-form-item>
            </el-col>
            <el-col :span="11" class="columnMarginLeft">
              <el-form-item
                label="Educational Partner"
                prop="educationalPartner"
              >
                <el-input v-model="certificateData.educationalPartner" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="Educational method" prop="educationalMethod">
                <el-input v-model="certificateData.educationalMethod" />
              </el-form-item>
            </el-col>
            <el-col :span="11" class="columnMarginLeft">
              <el-form-item label="Level" prop="level">
                <el-input v-model="certificateData.level" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="Completion code" prop="completionCode">
                <el-input v-model="certificateData.completionCode" />
              </el-form-item>
            </el-col>
            <el-col :span="11" class="columnMarginLeft">
              <el-form-item label="UAN " prop="uan">
                <el-input v-model="certificateData.uan" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="Learning Format" prop="learningFormat">
                <el-input v-model="certificateData.learningFormat" />
              </el-form-item>
            </el-col>
            <el-col :span="11" class="columnMarginLeft">
              <el-form-item label="Activity type" prop="activityType">
                <el-input v-model="certificateData.activityType" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="CDE Hours" prop="cdehours">
                <el-input-number
                  v-model="certificateData.cdehours"
                  :precision="2"
                  :step="0.25"
                  :min="0"
                  :max="1000"
                  style="vertical-align: top"
                  controls-position="right"
                />
              </el-form-item>
            </el-col>
            <el-col :span="11" class="columnMarginLeft" />
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="ANCC Rx" prop="anccRx">
                <el-input-number
                  v-model="certificateData.anccRx"
                  :precision="2"
                  :step="0.25"
                  :min="0"
                  :max="1000"
                  style="vertical-align: top"
                  controls-position="right"
                />
              </el-form-item>
            </el-col>
            <el-col :span="11" class="columnMarginLeft">
              <el-form-item label="AANP Rx" prop="aanpRx">
                <el-input-number
                  v-model="certificateData.aanpRx"
                  :precision="1"
                  :step="0.1"
                  :min="0"
                  :max="1000"
                  style="vertical-align: top"
                  controls-position="right"
                />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="Activity Title" prop="activityTitle">
                <el-input v-model="certificateData.activityTitle" />
              </el-form-item>
            </el-col>
            <el-col :span="11" class="columnMarginLeft">
              <el-form-item label="Activity Number" prop="activityNumber">
                <el-input v-model="certificateData.activityNumber" />
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item
                label="AND CDR Registration Number"
                prop="andCdrRegistrationNumber"
              >
                <el-input v-model="certificateData.andCdrRegistrationNumber" />
              </el-form-item>
            </el-col>
            <el-col :span="11" class="columnMarginLeft">
              <el-form-item
                label="Performance Indicator(s)"
                prop="performanceIndicator"
              >
                <el-input v-model="certificateData.performanceIndicator" />
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div style="margin-top: 20px">
        <el-button type="primary" @click="uploadData">Save</el-button>
      </div>
    </div>

    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible">
      <el-form
        ref="dataFormParticipant"
        :rules="participantRules"
        :model="temp"
        label-position="left"
        label-width="170px"
        style="width: 800px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="Project #" prop="project">
              <el-input v-model="temp.project" />
            </el-form-item>

            <el-form-item
              label="Certificate Issued Date"
              prop="certificateIssuedDate"
            >
              <el-date-picker
                v-model="temp.certificateIssuedDate"
                type="date"
                format="MM/dd/yyyy"
              />
            </el-form-item>

            <el-form-item
              label="Application/Participation Date"
              prop="applicationParticipationDate"
            >
              <el-date-picker
                v-model="temp.applicationParticipationDate"
                type="date"
                format="MM/dd/yyyy"
              />
            </el-form-item>

            <el-form-item label="End Date" prop="endDate">
              <el-date-picker
                v-model="temp.endDate"
                type="date"
                format="MM/dd/yyyy"
              />
            </el-form-item>

            <el-form-item label="Degree" prop="degree">
              <el-input v-model="temp.degree" />
            </el-form-item>

            <el-form-item label="Credit Request Type" prop="creditRequestType">
              <el-input v-model="temp.creditRequestType" />
            </el-form-item>

            <el-form-item label="Last Name" prop="lastName">
              <el-input v-model="temp.lastName" />
            </el-form-item>

            <el-form-item label="First Name" prop="firstName">
              <el-input v-model="temp.firstName" />
            </el-form-item>

            <el-form-item label="Email Address" prop="email">
              <el-input v-model="temp.emailAddress" />
            </el-form-item>

            <el-form-item label="Do Not Contact" prop="doNotContact">
              <el-checkbox v-model="temp.doNotContact" />
            </el-form-item>

            <el-form-item label="Credit Hrs Issued" prop="creditHrsIssued">
              <el-input-number v-model="temp.creditHrsIssued" />
            </el-form-item>
            <el-form-item label="Location" prop="location">
              <el-input v-model="temp.location" />
            </el-form-item>

            <el-form-item label="Speaker's Name" prop="speakersName">
              <el-input v-model="temp.speakersName" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">{{
          $t("table.cancel")
        }}</el-button>
        <el-button
          type="primary"
          @click="dialogStatus === 'create' ? createData() : updateData()"
          >{{ $t("table.confirm") }}</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<style>
.intervalsContainer .el-form-item__content {
  margin-left: 0 !important;
}

col[name="gutter"] {
  display: none !important;
}
</style>

<style scoped>
.grid-content {
  height: 36px;
  line-height: 36px;
}

.intervalsContainer {
  border: 1px solid #ebeef5;
  border-radius: 5px;
  padding: 0 10px;
  margin-left: 10px;
}

.columnMarginLeft {
  margin-left: 10px;
}

.certificateContainer .el-checkbox {
  margin-left: 0 !important;
  margin-right: 30px !important;
}

.intervalCheckbox {
  padding-left: 5px;
  margin-left: 5px;
  width: 100%;
  line-height: 40px;
  height: 40px;
}

.intervalCheckbox-even {
  background: #ebeef5;
}

.light-label {
  font-weight: 100 !important;
}
</style>

<script>
import { getToken } from "@/utils/auth";
import { getAllCertificates as GetAllCertificates } from "@/api/certificates";
import {
  getAllSeriesActivityNames as GetAllSeriesActivityNames,
  createSendJobs as CreateSendJobs,
} from "@/api/participants";
import waves from "@/directive/waves";
import { baseUrl } from "@/api/activities";
import moment from "moment";

export default {
  name: "ParticipantCertificateEmail",
  directives: { waves },
  data() {
    var validateEducationalMethod = (rule, value, callback) => {
      if (this.isCertificatesIncludeBoard("AGD")) {
        if (!this.certificateData.educationalMethod) {
          callback(new Error("Educational Method is required"));
        }
      }

      callback();
    };

    var validateLevel = (rule, value, callback) => {
      if (this.isCertificatesIncludeBoard("AND CDR")) {
        if (!this.certificateData.level) {
          callback(new Error("Level is required"));
        }
      }

      callback();
    };

    var validateCompletionCode = (rule, value, callback) => {
      if (this.isCertificatesIncludeBoard("AGD")) {
        if (!this.certificateData.completionCode) {
          callback(new Error("Completeon Code is required"));
        }
      }

      callback();
    };

    var validateUan = (rule, value, callback) => {
      if (this.isCertificatesIncludeBoard("ACPE")) {
        if (!this.certificateData.uan) {
          callback(new Error("UAN is required"));
        }
      }

      callback();
    };

    var validateLearningFormat = (rule, value, callback) => {
      if (this.isCertificatesIncludeBoard("ACCME Other")) {
        if (!this.certificateData.learningFormat) {
          callback(new Error("Learning Format is required"));
        }
      }

      callback();
    };

    var validateActivityType = (rule, value, callback) => {
      if (this.isCertificatesIncludeBoard("ACCME")) {
        if (!this.certificateData.activityType) {
          callback(new Error("Activity Type is required"));
        }
      }

      callback();
    };

    var validateCdehours = (rule, value, callback) => {
      if (
        this.isCertificatesIncludeBoard("ANDCDR") ||
        this.isCertificatesIncludeBoard("AGD")
      ) {
        if (!this.certificateData.cdehours) {
          callback(new Error("CDE Hours are required"));
        }
      }

      callback();
    };

    var validatePharmHours = (rule, value, callback) => {
      if (this.isCertificatesIncludeBoard("AANP")) {
        if (!this.certificateData.pharmHours) {
          callback(new Error("Pharm. Hours are required"));
        }
      }
      callback();
    };

    var validateAndCdrRegistratoinNumber = (rule, value, callback) => {
      if (this.isCertificatesIncludeBoard("ANDCDR")) {
        if (!this.certificateData.pharmHours) {
          callback(new Error("AND CDR Registration Number is required"));
        }
      }
      callback();
    };

    var validateAndCdrPerformanceIndicator = (rule, value, callback) => {
      if (this.isCertificatesIncludeBoard("ANDCDR")) {
        if (!this.certificateData.performanceIndicator) {
          callback(new Error("Performance Indicator is required"));
        }
      }
      callback();
    };

    return {
      uploadBaseUrl: baseUrl,
      activeParticipant: null,
      dialogFormVisible: false,
      dialogStatus: "",
      textMap: {
        update: "Edit",
        create: "Create",
      },
      participantsUploaded: false,
      certificateData: {
        generateDate: "",
        seriesActivityName: "",
        educationalMethod: "",
        completionCode: "",
        activityType: "",
        level: "",
        uan: "",
        cdehours: 0,
        pharmHours: 0,
        availableCerts: [],
        activityTitle: "",
        activityNumber: "",
      },
      temp: {
        project: "",
        certificateIssuedDate: null,
        endDate: null,
        applicationParticipationDate: null,
        degree: "",
        creditRequestType: "",
        lastName: "",
        firstName: "",
        emailAddress: "",
        doNotContact: "",
        creditHrsIssued: 0,
        location: "",
        speakersName: "",
        credits: 0,
        activityTitle: "",
        activityNumber: "",
      },
      certificates: [],
      participants: [],
      seriesActivities: [],
      headerInfo: {
        Authorization: "Bearer " + getToken(),
      },
      rules: {
        credits: [
          {
            required: true,
            message: "Please fill Credits",
            trigger: "blur",
          },
        ],
        seriesActivityName: [
          {
            required: true,
            message: "Select activity/series",
            trigger: "blur",
          },
        ],
        generateDate: [
          {
            required: true,
            message: "Generate date is required",
            trigger: "blur",
          },
        ],
        activityTitle: [
          {
            required: true,
            message: "Fill Activity Title",
            trigger: "blur",
          },
        ],
        educationalMethod: [
          {
            validator: validateEducationalMethod,
            trigger: "blur",
          },
        ],
        level: [
          {
            validator: validateLevel,
            trigger: "blur",
          },
        ],
        completionCode: [
          {
            validator: validateCompletionCode,
            trigger: "blur",
          },
        ],
        uan: [
          {
            validator: validateUan,
            trigger: "blur",
          },
        ],
        learningFormat: [
          {
            validator: validateLearningFormat,
            trigger: "blur",
          },
        ],
        activityType: [
          {
            validator: validateActivityType,
            trigger: "blur",
          },
        ],
        cdehours: [
          {
            validator: validateCdehours,
            trigger: "blur",
          },
        ],
        pharmHours: [
          {
            validator: validatePharmHours,
            trigger: "blur",
          },
        ],
        andCdrRegistrationNumber: [
          {
            validator: validateAndCdrRegistratoinNumber,
            trigger: "blur",
          },
        ],
        performanceIndicator: [
          {
            validator: validateAndCdrPerformanceIndicator,
            trigger: "blur",
          },
        ],
        availableCerts: [
          {
            type: "array",
            required: true,
            message: "No certificate types provided",
            trigger: "change",
          },
        ],
      },
    };
  },
  created() {
    this.GetCertificates();
    this.GetSeriesActivities();
  },
  methods: {
    isCertificatesIncludeBoard: function (board) {
      if (
        this.certificateData.availableCerts.filter(
          (c) => c.board.toLowerCase() === board.toLowerCase()
        ).length > 0
      ) {
        return true;
      }
      return false;
    },
    moment: function (date) {
      if (!date) {
        return new Date();
      } else {
        return new Date(date);
      }
    },
    GetCertificates: function () {
      var $this = this;
      GetAllCertificates().then((response) => {
        $this.certificates = response.data;
      });
    },
    GetSeriesActivities: function () {
      var $this = this;
      GetAllSeriesActivityNames().then((response) => {
        $this.seriesActivities = response.data;
      });
    },
    onParticipantsUploadSuccess(response) {
      if (response.length) {
        this.participants = response;
        this.participantsUploaded = true;
        this.recalculateCertificates();
      } else {
        this.$notify({
          title: "File upload error",
          message: response.error,
          type: "error",
          duration: 5000,
        });
      }
    },
    onFileUploadError(err) {
      this.$notify({
        title: "File upload error",
        message: err,
        type: "error",
        duration: 5000,
      });
    },
    handleUpdate(row) {
      this.activeParticipant = row;
      this.temp = Object.assign({}, row);
      this.dialogStatus = "update";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["dataFormParticipant"].clearValidate();
      });
    },
    handleCreate() {
      this.resetTemp();
      this.dialogStatus = "create";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["dataFormParticipant"].clearValidate();
      });
    },
    resetTemp() {
      this.temp = {
        project: "",
        certificateIssuedDate: null,
        endDate: null,
        applicationParticipationDate: null,
        degree: "",
        creditRequestType: "",
        lastName: "",
        firstName: "",
        emailAddress: "",
        doNotContact: "",
        creditHrsIssued: 0,
        location: "",
        speakersName: "",
        credits: 0,
        activityTitle: "",
        activityNumber: "",
      };
    },
    createData() {
      this.participants.push(this.temp);
      this.dialogFormVisible = false;
      this.recalculateCertificates();
    },
    updateData() {
      Object.assign(this.activeParticipant, this.temp);
      this.dialogFormVisible = false;
      this.recalculateCertificates();
    },
    handleDelete(row) {
      var index = this.participants.indexOf(row);
      if (index !== -1) this.participants.splice(index, 1);
      this.recalculateCertificates();
    },
    recalculateCertificates() {
      var participantsCerts = [];
      for (var i = 0; i < this.participants.length; i++) {
        var participant = this.participants[i];
        var cert = participant.creditRequestType;
        if (participantsCerts.indexOf(cert) === -1) {
          participantsCerts.push(cert);
        }
      }

      var resultCerts = [];
      for (var i = 0; i < this.certificates.length; i++) {
        var cert = this.certificates[i];
        for (var j = 0; j < participantsCerts.length; j++) {
          var participantCert = participantsCerts[j];
          if (cert.board.toLowerCase() == participantCert.toLowerCase()) {
            if (resultCerts.indexOf(cert) === -1) {
              resultCerts.push(cert);
            }
          }
        }
      }

      this.certificateData.availableCerts = resultCerts;
    },
    uploadData() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          CreateSendJobs({
            participantCertificateData: this.certificateData,
            participants: this.participants,
          }).then((response) => {
            this.$notify({
              title: "Done",
              message: response.error,
              type: "success",
              duration: 5000,
            });
            this.$router.go(0);
          });
        }
      });
    },
  },
};
</script>
