var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("p", { staticClass: "aligncenter" }, [
        _c("img", {
          staticStyle: { height: "100px" },
          attrs: { src: "data:image/jpg;base64," + _vm.logo }
        })
      ]),
      _vm._v(" "),
      _c("div", [
        _c("p", { staticStyle: { "text-align": "center", padding: "30px" } }, [
          _vm._v(_vm._s(_vm.evaluation.intro))
        ])
      ]),
      _vm._v(" "),
      _vm._l(_vm.evaluation.questions, function(question, index) {
        return _c("div", { key: question.Key }, [
          _c("div", { attrs: { stle: "margin-top:10px;" } }, [
            _c(
              "div",
              {
                class: { required: question.Required },
                staticStyle: { "margin-top": "10px", "margin-bottom": "10px" }
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(index + 1) +
                    ". " +
                    _vm._s(question.Question) +
                    "\n      "
                )
              ]
            ),
            _vm._v(" "),
            question.NeedAnswer
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      "margin-top": "5px",
                      "margin-bottom": "5px",
                      color: "red"
                    }
                  },
                  [_vm._v("\n        This is required question\n      ")]
                )
              : _vm._e(),
            _vm._v(" "),
            question.ResponseType == "Single"
              ? _c(
                  "div",
                  [
                    _c(
                      "el-radio-group",
                      {
                        model: {
                          value: question.checkedAnswers,
                          callback: function($$v) {
                            _vm.$set(question, "checkedAnswers", $$v)
                          },
                          expression: "question.checkedAnswers"
                        }
                      },
                      _vm._l(question.answers, function(answer) {
                        return _c(
                          "el-radio",
                          { key: answer.value, attrs: { label: answer.label } },
                          [_vm._v(_vm._s(answer.label))]
                        )
                      })
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            question.ResponseType == "Multiple"
              ? _c(
                  "div",
                  [
                    _c(
                      "el-checkbox-group",
                      {
                        model: {
                          value: question.checkedAnswers,
                          callback: function($$v) {
                            _vm.$set(question, "checkedAnswers", $$v)
                          },
                          expression: "question.checkedAnswers"
                        }
                      },
                      _vm._l(question.answers, function(answer) {
                        return _c(
                          "el-checkbox",
                          { key: answer.value, attrs: { label: answer.label } },
                          [_vm._v(_vm._s(answer.label))]
                        )
                      })
                    )
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            question.ResponseType == "Textarea"
              ? _c(
                  "div",
                  [
                    _c("el-input", {
                      attrs: { type: "textarea", placeholder: "Your answer" },
                      model: {
                        value: question.textAnswer,
                        callback: function($$v) {
                          _vm.$set(question, "textAnswer", $$v)
                        },
                        expression: "question.textAnswer"
                      }
                    })
                  ],
                  1
                )
              : _vm._e()
          ]),
          _vm._v(" "),
          _c("hr")
        ])
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }