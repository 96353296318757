import request from '@/utils/request'

export function getUsers(query) {
  return request({
    url: '/users/getList',
    method: 'Get',
    params: query
  })
}

export function getCurrentUser(query) {
  return request({
    url: '/users/getCurrentUser',
    method: 'Get',
    params: query
  })
}

export function getOrganizations(query) {
  return request({
    url: '/organizations/getAll',
    method: 'Get',
    params: query
  })
}

export function create(data) {
  return request({
    url: '/users/create',
    method: 'Post',
    data
  })
}

export function update(data) {
  return request({
    url: '/users/update',
    method: 'Post',
    data
  })
}

export function get(query) {
  return request({
    url: '/users/get',
    method: 'Get',
    params: query
  })
}

export function setPassword(data) {
  return request({
    url: '/users/setPassword',
    method: 'Post',
    data
  })
}

export function forgotPassword(data) {
  return request({
    url: '/users/forgotPassword',
    method: 'Post',
    data
  })
}