<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input
        v-model="listQuery.term"
        placeholder="Activity Name/Series ID"
        style="width: 200px"
        class="filter-item"
        @keyup.enter.native="handleFilter"
      />
      <el-button
        v-waves
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        @click="handleFilter"
        >{{ $t("table.search") }}</el-button
      >
    </div>

    <el-table
      v-loading="listLoading"
      :key="tableKey"
      :data="list"
      border
      fit
      highlight-current-row
      style="width: 100%"
    >
      <el-table-column
        label="Activity Name/Series ID"
        align="center"
        min-width="150px"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.seriesActivityName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Date" min-width="130px" align="center">
        <template slot-scope="scope">
          <span>{{
            new Date(scope.row.generateDate).toLocaleDateString("en-US")
          }}</span>
        </template>
      </el-table-column>

      <el-table-column
        :label="$t('table.actions')"
        align="center"
        min-width="260"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <div>
            <el-button
              type="primary"
              size="mini"
              @click="showDetails(scope.row)"
              >Details</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />

    <el-dialog :visible.sync="dialogFormVisible" title="Details">
      <el-table
        :key="temp.seriesActivityName"
        :data="temp.items"
        border
        fit
        highlight-current-row
        style="width: 100%"
      >
        <el-table-column label="First Name" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.firstName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Last Name" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.lastName }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Email" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.emailAddress }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Degree" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.degree }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Credit Request Type" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.creditRequestType }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Credit Hrs Issued" align="center">
          <template slot-scope="scope">
            <span>{{ scope.row.creditHrsIssued }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Sent" align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.isSent" style="color: green">yes</span>
            <span v-else style="color: gray">no</span>
          </template>
        </el-table-column>
        <el-table-column label="Certificate" align="center">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="downloadCertificate(scope.row.id)"
              >Download</el-button
            >
            <el-button
              type="primary"
              size="mini"
              @click="sendCertificate(scope.row.id)"
              >Send Manually</el-button
            >
          </template>
        </el-table-column>
      </el-table>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">Close</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<style>
.intervalsContainer .el-form-item__content {
  margin-left: 0 !important;
}
</style>

<style>
col[name="gutter"] {
  display: none !important;
}
</style>

<style scoped>
.grid-content {
  height: 36px;
  line-height: 36px;
}

.intervalsContainer {
  border: 1px solid #ebeef5;
  border-radius: 5px;
  padding: 0 10px;
  margin-left: 10px;
}

.intervalCheckbox {
  padding-left: 5px;
  margin-left: 5px;
  width: 100%;
  line-height: 40px;
  height: 40px;
}

.intervalCheckbox-even {
  background: #ebeef5;
}

.light-label {
  font-weight: 100 !important;
}
</style>

<script>
import FileSaver from "file-saver";
import { getToken } from "@/utils/auth";
import {
  getList as GetList,
  downloadCertificate as DownloadCertificate,
  sendCertificateForParticipant as SendCertificateForParticipant,
} from "@/api/participants";
import waves from "@/directive/waves"; // Waves directive
import Pagination from "@/components/Pagination"; // Secondary package based on el-pagination

export default {
  name: "ActivitySeriesGrid",
  components: { Pagination },
  directives: { waves },
  data() {
    return {
      tableKey: 0,
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 20,
        term: "",
      },
      temp: {
        seriesActivityName: "",
        generateDate: null,
        items: [],
      },
      dialogFormVisible: false,
      headerInfo: {
        Authorization: "Bearer " + getToken(),
      },
      downloadLoading: false,
    };
  },
  created() {
    this.getList();
  },
  methods: {
    b64toBlob: function (b64Data, contentType, sliceSize) {
      contentType = contentType || "";
      sliceSize = sliceSize || 512;

      var byteCharacters = atob(b64Data);
      var byteArrays = [];

      for (
        var offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      var blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
    getList() {
      this.listLoading = true;

      GetList(this.listQuery).then((response) => {
        this.list = response.data.items;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    handleSearchtype() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    showDetails(row) {
      this.temp = Object.assign({}, row);
      this.dialogFormVisible = true;
    },
    resetTemp() {
      this.temp = {
        seriesActivityName: "",
        generateDate: null,
        items: [],
      };
    },
    isNumber: function (evt) {
      evt = evt || window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    downloadCertificate(participantId) {
      DownloadCertificate({ participantId: participantId }).then((response) => {
        var blob = this.b64toBlob(response.data.body);
        FileSaver.saveAs(blob, "certificate.pdf");
      });
    },
    sendCertificate(participantId) {
      SendCertificateForParticipant({
        participantCertificateId: participantId,
      }).then((response) => {
        this.getList();
      });
    },
  },
};
</script>
