<template>
  <div class="app-container">
    <el-form
      ref="dataForm"
      :rules="rules"
      :model="activity"
      label-position="left"
      label-width="170px"
      style="width: 800px"
    >
      <el-row>
        <el-col :span="12">
          <el-form-item
            v-if="activity.seriesId > 0"
            :label="$t('activity.activityId')"
          >
            <label class="light-label">{{ activity.activityId }}</label>
          </el-form-item>
          <el-form-item
            v-else
            :label="$t('activity.activityId')"
            prop="activityId"
          >
            <el-input v-model="activity.activityId" />
          </el-form-item>
          <el-form-item :label="$t('activity.name')" prop="name">
            <el-input v-model="activity.name" />
          </el-form-item>
          <el-form-item
            :label="$t('activity.additionToTitle')"
            prop="additionToTitle"
          >
            <el-input v-model="activity.additionToTitle" />
          </el-form-item>
          <el-form-item :label="$t('activity.startDate')" prop="startDate">
            <el-date-picker
              v-model="activity.startDate"
              format="MM/dd/yyyy"
              value-format="yyyy-MM-dd"
              type="date"
            />
          </el-form-item>
          <el-form-item :label="$t('activity.endDate')" prop="endDate">
            <el-date-picker
              v-model="activity.endDate"
              format="MM/dd/yyyy"
              value-format="yyyy-MM-dd"
              type="date"
            />
          </el-form-item>
          <el-form-item v-if="activity.seriesId > 0" :label="$t('series.area')">
            <label class="light-label">{{ activity.area.name }}</label>
          </el-form-item>
          <el-form-item v-else :label="$t('series.area')" prop="area">
            <el-select
              v-model="activity.area"
              class="filter-item"
              value-key="id"
              placeholder="Please select area"
              style="width: 100%"
            >
              <el-option
                v-for="item in areas"
                :key="item.id"
                :label="item.name"
                :value="item"
              />
            </el-select>
          </el-form-item>
          <el-form-item
            v-if="activity.seriesId > 0"
            :label="$t('series.educationalPartner')"
          >
            <label class="light-label">{{ activity.educationalPartner }}</label>
          </el-form-item>
          <el-form-item v-else :label="$t('series.educationalPartner')">
            <el-input v-model="activity.educationalPartner" />
          </el-form-item>
          <el-form-item
            v-if="activity.seriesId > 0"
            :label="$t('series.commercialPartner')"
          >
            <label class="light-label">{{ activity.commercialPartner }}</label>
          </el-form-item>
          <el-form-item v-else :label="$t('series.commercialPartner')">
            <el-input v-model="activity.commercialPartner" />
          </el-form-item>
          <el-form-item :label="$t('activity.description')" prop="description">
            <el-input v-model="activity.description" type="textarea" />
          </el-form-item>
        </el-col>

        <el-col
          v-if="activity.seriesId > 0 || activity.questionsFile != null"
          :span="8"
        >
          <el-form-item
            :label="$t('series.interval')"
            class="intervalsContainer"
            style="margin-left: 10"
          >
            <div style="display: block">
              <div v-for="interval in activity.intervals" :key="interval">
                <div class="el-form-item__content" style="clear: both">
                  <label class="light-label">{{
                    intervals.find((item) => item.id == interval).name
                  }}</label>
                </div>
              </div>
            </div>
          </el-form-item>
        </el-col>

        <el-col v-else :span="8">
          <el-form-item
            :label="$t('series.interval')"
            class="intervalsContainer"
            prop="intervals"
            style="margin-left: 10"
          >
            <div style="display: block">
              <el-checkbox-group v-model="activity.intervals">
                <el-checkbox
                  v-for="(interval, $index) in intervals"
                  :label="interval.id"
                  :key="interval.id"
                  :class="{ 'intervalCheckbox-even': $index % 2 === 0 }"
                  class="intervalCheckbox"
                  >{{ interval.name }}</el-checkbox
                >
              </el-checkbox-group>
            </div>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="20">
          <div
            style="
              width: 100%;
              border-bottom: 1px solid black;
              padding-bottom: 10px;
            "
          >
            <span style="font-weight: bold">Attendees</span>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-row>
          <el-col :span="1">
            <div class="grid-content">
              <svg-icon
                v-if="activity.responsesFile != null"
                icon-class="documentation"
              />
            </div>
          </el-col>
          <el-col :span="3">
            <div class="grid-content">
              <label>Attendees</label>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content">
              <span v-if="activity.attendeesFile != null">{{
                activity.attendeesFile != null
                  ? moment(activity.attendeesFile.createdDate).format(
                      "MM/DD/YYYY h:mm a"
                    )
                  : ""
              }}</span>
            </div>
          </el-col>
          <el-col :span="4">
            <div>
              <div
                v-if="activity.attendeesFile == null"
                :disabled="activity.attendeesFile == null"
                class="grid-content"
              >
                <el-upload
                  :action="uploadBaseUrl + '/activities/uploadFile'"
                  :multiple="false"
                  :auto-upload="true"
                  :show-file-list="false"
                  :headers="headerInfo"
                  :data="fileDataAttendees()"
                  :on-success="onAttendeeUploadSuccess"
                  :on-error="onFileUploadError"
                  class="upload"
                  name="Content"
                >
                  <el-button size="small" type="primary">Upload</el-button>
                </el-upload>
              </div>
              <div v-if="activity.attendeesFile != null" class="grid-content">
                <el-button
                  size="small"
                  type="danger"
                  @click="handleAttendeeDeleteFile()"
                  >Delete</el-button
                >
              </div>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="20">
            <div
              style="
                width: 100%;
                border-bottom: 1px solid black;
                padding-bottom: 10px;
              "
            >
              <span style="font-weight: bold">Survey</span>
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="1">
            <div class="grid-content">
              <svg-icon
                v-if="activity.questionsFile != null"
                icon-class="documentation"
              />
            </div>
          </el-col>
          <el-col :span="3">
            <div class="grid-content">
              <label>Questions</label>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content">
              <div v-if="activity.questionsFile != null">
                {{
                  activity.questionsFile != null
                    ? moment(activity.questionsFile.createdDate).format(
                        "MM/DD/YYYY h:mm a"
                      )
                    : ""
                }}
              </div>
            </div>
          </el-col>
          <el-col :span="3">
            <div v-if="activity.questionsFile == null" class="grid-content">
              <el-upload
                :action="uploadBaseUrl + '/activities/uploadFile'"
                :multiple="false"
                :auto-upload="true"
                :show-file-list="false"
                :headers="headerInfo"
                :data="fileDataQuestions()"
                :on-success="onQuestionsUploadSuccess"
                :on-error="onFileUploadError"
                class="upload"
                name="Content"
              >
                <el-button size="small" type="primary">Upload</el-button>
              </el-upload>
            </div>
            <div v-else class="grid-content">
              <el-button
                v-if="activity.responsesFile == null"
                size="small"
                type="danger"
                @click="handleQuestionsDeleteFile()"
                >Delete</el-button
              >
              <el-button
                v-else
                hint="Please remove responses file first"
                size="small"
                type="danger"
                disabled
                >Delete</el-button
              >
            </div>
          </el-col>
          <el-col :span="3">
            <div v-if="activity.questionsFile != null" class="grid-content">
              <el-button
                size="small"
                type="warning"
                @click="exportQuestions(activity.id)"
                >Export</el-button
              >
            </div>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="1">
            <div class="grid-content">
              <svg-icon
                v-if="activity.responsesFile != null"
                icon-class="documentation"
              />
            </div>
          </el-col>
          <el-col :span="3">
            <div class="grid-content">
              <label>Responses</label>
            </div>
          </el-col>
          <el-col :span="6">
            <div class="grid-content">
              <span v-if="activity.responsesFile != null">{{
                activity.responsesFile != null
                  ? moment(activity.responsesFile.createdDate).format(
                      "MM/DD/YYYY h:mm a"
                    )
                  : ""
              }}</span>
            </div>
          </el-col>
          <el-col :span="3">
            <div v-if="activity.questionsFile == null" class="grid-content">
              <el-button
                title="Please upload questions first"
                class="upload"
                size="small"
                type="danger"
                disabled
                >Upload</el-button
              >
            </div>
            <div v-else>
              <div
                v-if="activity.responsesFile == null"
                :disabled="activity.questionsFile == null"
                class="grid-content"
              >
                <el-upload
                  :action="uploadBaseUrl + '/activities/uploadFile'"
                  :multiple="false"
                  :auto-upload="true"
                  :show-file-list="false"
                  :headers="headerInfo"
                  :data="fileDataResponses()"
                  :on-success="onResponsesUploadSuccess"
                  :on-error="onFileUploadError"
                  class="upload"
                  name="Content"
                >
                  <el-button size="small" type="primary">Upload</el-button>
                </el-upload>
              </div>
              <div v-if="activity.responsesFile != null" class="grid-content">
                <el-button
                  size="small"
                  type="danger"
                  @click="handleResponsesDeleteFile()"
                  >Delete</el-button
                >
              </div>
            </div>
          </el-col>
          <el-col :span="3">
            <div v-if="activity.questionsFile != null" class="grid-content">
              <el-button
                size="small"
                type="warning"
                @click="exportResponses(activity.id)"
                >Export</el-button
              >
            </div>
          </el-col>
        </el-row>
      </el-row>
    </el-form>
    <div slot="footer" class="dialog-footer">
      <el-button @click="dialogFormVisible = false">{{
        $t("table.cancel")
      }}</el-button>
      <el-button type="primary" @click="updateData()">{{
        $t("table.confirm")
      }}</el-button>
    </div>
  </div>
</template>

<style>
.intervalsContainer .el-form-item__content {
  margin-left: 0 !important;
}
</style>

<style scoped>
.grid-content {
  height: 36px;
  line-height: 36px;
}

.intervalsContainer {
  border: 1px solid #ebeef5;
  border-radius: 5px;
  padding: 0 10px;
  margin-left: 10px;
}

.intervalCheckbox {
  padding-left: 5px;
  margin-left: 5px;
  width: 100%;
  line-height: 40px;
  height: 40px;
}

.intervalCheckbox-even {
  background: #ebeef5;
}

.light-label {
  font-weight: 100 !important;
}
</style>

<script>
import FileSaver from "file-saver";
import { getToken } from "@/utils/auth";
import { getAll as IntervalsGetAll } from "@/api/intervals";
import { getAll as AreasGetAll } from "@/api/areas";
import {
  baseUrl,
  search,
  getActivity as GetActivityById,
  update as UpdateActivity,
  deleteFile as DeleteFile,
  exportQuestions as ExportQuestions,
  exportResponses as ExportResponses,
} from "@/api/activities";
import waves from "@/directive/waves"; // Waves directive
import { parseTime } from "@/utils";
import moment from "moment";

export default {
  name: "EditActivityForm",
  directives: { waves },
  data() {
    var validateStartDate = (rule, value, callback) => {
      if (this.activity.endDate) {
        if (new Date(value) > new Date(this.activity.endDate)) {
          callback(new Error("Start Date must be before than End Date"));
        }
      }

      callback();
    };

    var validateEndDate = (rule, value, callback) => {
      if (this.activity.startDate && value) {
        if (new Date(this.activity.startDate) > new Date(value)) {
          callback(new Error("End Date must be after than Start Date"));
        }
      }

      callback();
    };
    return {
      uploadBaseUrl: baseUrl,
      areas: null,
      intervals: null,
      activity: {
        id: undefined,
        seriesId: undefined,
        name: "",
        startDate: "",
        endDate: null,
        description: "",
        commercialPartner: "",
        educationalPartner: "",
        intervals: [],
        activitiesCount: 0,
        questionsFile: null,
        responsesFile: null,
        attendeesFile: null,
      },
      headerInfo: {
        Authorization: "Bearer " + getToken(),
      },
      rules: {
        activityId: [
          { required: true, message: "Name is required", trigger: "change" },
        ],
        startDate: [
          {
            required: true,
            validator: validateStartDate,
            trigger: "blur",
          },
        ],
        endDate: [
          {
            validator: validateEndDate,
            trigger: "blur",
          },
        ],
        area: [
          {
            required: true,
            message: "Therapeutic area is required",
            trigger: "change",
          },
        ],
        intervals: [
          {
            type: "array",
            required: true,
            message: "Please select at least one interval",
            trigger: "change",
          },
        ],
      },
      downloadLoading: false,
    };
  },
  created() {
    AreasGetAll()
      .then((response) => {
        this.areas = response.data;
      })
      .then((_) =>
        IntervalsGetAll().then((response) => {
          this.intervals = response.data;
        })
      )
      .then((_) => this.getActivity());
  },
  methods: {
    getActivity: function () {
      var $this = this;
      GetActivityById({ activityId: this.$route.query.activityId }).then(
        (response) => {
          $this.activity = response.data;
          var activityData = {
            activity: response.data,
            intervals: $this.intervals,
          };
          this.$emit("activityLoaded", activityData);
        }
      );
    },
    b64toBlob: function (b64Data, contentType, sliceSize) {
      contentType = contentType || "";
      sliceSize = sliceSize || 512;

      var byteCharacters = atob(b64Data);
      var byteArrays = [];

      for (
        var offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      var blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
    moment: function (date) {
      if (!date) {
        return moment();
      } else {
        return moment(date);
      }
    },
    momentutc: function (date) {
      if (!date) {
        return moment.utc();
      } else {
        return moment.utc(date);
      }
    },
    handleAttendeeDeleteFile(fileId) {
      this.activity.attendeesFile = null;
    },
    handleResponsesDeleteFile(fileId) {
      this.activity.responsesFile = null;
    },
    handleQuestionsDeleteFile(fileId) {
      this.activity.questionsFile = null;
    },
    onAttendeeUploadSuccess(response, file, fileList) {
      if (response.success) {
        this.activity.attendeesFile = response.fileDTO;
      } else {
        this.$notify({
          title: "File upload error",
          message: response.error,
          type: "error",
          duration: 5000,
        });
      }
    },
    onResponsesUploadSuccess(response, file, fileList) {
      if (response.success) {
        this.activity.responsesFile = response.fileDTO;
      } else {
        this.$notify({
          title: "File upload error",
          message: response.error,
          type: "error",
          duration: 5000,
        });
      }
    },
    onQuestionsUploadSuccess(response, file, fileList) {
      if (response.success) {
        console.log("File upload success!");
        this.activity.questionsFile = response.fileDTO;
      } else {
        this.$notify({
          title: "File upload error",
          message: response.error,
          type: "error",
          duration: 5000,
        });
      }
    },
    onFileUploadError(err) {
      this.$notify({
        title: "File upload error",
        message: err,
        type: "error",
        duration: 5000,
      });
    },
    fileDataQuestions() {
      return {
        ActivityId: this.activity.id,
        FileType: "Questions",
      };
    },
    fileDataResponses() {
      return {
        questionsFileId: this.activity.questionsFile.id,
        ActivityId: this.activity.id,
        FileType: "Responses",
      };
    },
    fileDataAttendees() {
      return {
        ActivityId: this.activity.id,
        FileType: "Attendees",
      };
    },
    updateData() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          UpdateActivity(this.activity).then(() => {
            this.$notify({
              title: "Activity updated",
              message: "",
              type: "success",
              duration: 1000,
            });
            this.getActivity();
          });
        } else {
          console.log("NOT VALID FORM!");
        }
      });
    },
    isNumber: function (evt) {
      evt = evt || window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    exportQuestions(activityId) {
      ExportQuestions({ activityId: activityId }).then((response) => {
        var blob = this.b64toBlob(
          response.data.body,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
        FileSaver.saveAs(blob, "Questions.xlsx");
      });
    },
    exportResponses(activityId) {
      ExportResponses({ activityId: activityId }).then((response) => {
        var blob = this.b64toBlob(
          response.data.body,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
        FileSaver.saveAs(blob, "Responses.xlsx");
      });
    },
  },
};
</script>
