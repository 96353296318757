<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input
        :placeholder="getSearchTypePlaceholder()"
        v-model="listQuery.term"
        style="width: 200px"
        class="filter-item"
        @keyup.enter.native="handleFilter"
      />
      <el-select
        v-model="listQuery.searchBy"
        style="width: 140px"
        class="filter-item"
        @change="handleSearchtype"
      >
        <el-option
          v-for="item in searchTypes"
          :key="item.key"
          :label="item.label"
          :value="item.key"
        />
      </el-select>
      <el-button
        v-waves
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        @click="handleFilter"
        >{{ $t("table.search") }}</el-button
      >
      <el-button
        class="filter-item"
        style="margin-left: 10px"
        type="primary"
        icon="el-icon-edit"
        @click="handleCreate"
        >{{ $t("table.add") }}</el-button
      >
    </div>

    <el-table
      v-loading="listLoading"
      :key="tableKey"
      :data="list"
      border
      fit
      highlight-current-row
      style="width: 100%"
    >
      <el-table-column label="First Name" align="center" width="150px">
        <template slot-scope="scope">
          <span>{{ scope.row.firstName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Last Name" width="130px" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.lastName }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Email" min-width="200px" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.email }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Phone Number" width="130px" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.phoneNumber }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Degree" min-width="200px" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.degree }}</span>
        </template>
      </el-table-column>
      <el-table-column label="WebSurvey Link" min-width="100px" align="center">
        <template slot-scope="scope">
          <a
            target="_blank"
            v-if="scope.row.webSurveyLink"
            :href="scope.row.webSurveyLink"
            >link</a
          >
        </template>
      </el-table-column>
      <el-table-column label="PreTest Link" min-width="200px" align="center">
        <template slot-scope="scope">
          <a
            target="_blank"
            v-if="scope.row.preTestLink"
            :href="scope.row.preTestLink"
            >link</a
          >
        </template>
      </el-table-column>
      <el-table-column label="Evaluation Link" min-width="200px" align="center">
        <template slot-scope="scope">
          <a
            target="_blank"
            v-if="scope.row.evaluationLink"
            :href="scope.row.evaluationLink"
            >link</a
          >
        </template>
      </el-table-column>
      <el-table-column label="Active" width="100px">
        <template slot-scope="scope">
          <span v-if="scope.row.isActive" style="color: green">active</span>
          <span v-else style="color: gray">inactive</span>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('table.actions')"
        align="center"
        width="230"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="small"
            @click="handleUpdate(scope.row)"
            >{{ $t("table.edit") }}</el-button
          >
          <el-button
            v-if="!scope.row.evaluationLink"
            type="primary"
            size="small"
            @click="handleManualSend(scope.row)"
            >{{ $t("table.manualSend") }}</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />

    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible">
      <el-form
        ref="dataForm"
        :rules="rules"
        :model="temp"
        label-position="left"
        label-width="170px"
        style="width: 800px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="Active" prop="isActive">
              <el-checkbox v-model="temp.isActive"></el-checkbox>
            </el-form-item>
            <el-form-item label="First Name" prop="firstName">
              <el-input v-model="temp.firstName" />
            </el-form-item>
            <el-form-item label="Last Name" prop="lastName">
              <el-input v-model="temp.lastName" />
            </el-form-item>
            <el-form-item label="Email" prop="email">
              <el-input v-model="temp.email" />
            </el-form-item>
            <el-form-item label="Phone" prop="phone">
              <el-input v-model="temp.phoneNumber" />
            </el-form-item>
            <el-form-item label="Degree" prop="degree">
              <el-input v-model="temp.degree" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">{{
          $t("table.cancel")
        }}</el-button>
        <el-button
          type="primary"
          @click="dialogStatus === 'create' ? createData() : updateData()"
          >{{ $t("table.confirm") }}</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<style scoped>
.grid-content {
  height: 36px;
  line-height: 36px;
}

.intervalCheckbox-even {
  background: #ebeef5;
}

.light-label {
  font-weight: 100 !important;
}
</style>

<script>
import { getToken } from "@/utils/auth";
import {
  baseUrl,
  search,
  create as CreateAttendee,
  update as UpdateAttendee,
  manualSend as ManualSend,
} from "@/api/attendees";
import waves from "@/directive/waves"; // Waves directive
import { parseTime } from "@/utils";
import Pagination from "@/components/Pagination"; // Secondary package based on el-pagination
import moment from "moment";

export default {
  name: "AttendeesGrid",
  components: { Pagination },
  directives: { waves },
  data() {
    return {
      uploadBaseUrl: baseUrl,
      tableKey: 0,
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 20,
        activityId: null,
        searchBy: "FirstName",
        term: "",
      },
      searchTypes: [
        { label: "First Name", key: "FirstName" },
        { label: "Last Name", key: "LastName" },
        { label: "Email", key: "Email" },
        { label: "PhoneNumber", key: "Phone Number" },
        { label: "Degree", key: "Degree" },
      ],
      temp: {
        id: undefined,
        firstName: undefined,
        lastName: "",
        degree: "",
        email: "",
        phoneNumber: "",
        isActive: false,
      },
      dialogFormVisible: false,
      dialogStatus: "",
      textMap: {
        update: "Edit Attendee",
        create: "Create Attendee",
      },
      headerInfo: {
        Authorization: "Bearer " + getToken(),
      },
      rules: {
        firstName: [
          {
            required: true,
            message: "First Name is required",
            trigger: "change",
          },
        ],
        lastName: [
          {
            required: true,
            message: "Last Name is required",
            trigger: "change",
          },
        ],
        email: [
          { required: true, message: "Email is required", trigger: "change" },
          {
            type: "email",
            message: "Incorrect email address",
            trigger: ["blur", "change"],
          },
        ],
      },
      downloadLoading: false,
    };
  },
  created() {
    this.listQuery.activityId = this.$route.query.activityId;
    this.getList();
  },
  methods: {
    getSearchTypePlaceholder() {
      var result = "";
      if (this.listQuery.searchBy == "FirstName") {
        result = "First Name";
      } else if (this.listQuery.searchBy == "LastName") {
        result = "Last Name";
      } else if (this.listQuery.searchBy == "Email") {
        result = "Email";
      } else if (this.listQuery.searchBy == "PhoneNumber") {
        result = "Phone Number";
      } else if (this.listQuery.searchBy == "Degree") {
        result = "Degree";
      }

      return result;
    },
    getList() {
      this.listLoading = true;
      search(this.listQuery).then((response) => {
        this.list = response.data.items;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    handleSearchtype() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    resetTemp() {
      this.temp = {
        id: undefined,
        firstName: undefined,
        lastName: "",
        degree: "",
        email: "",
        phoneNumber: "",
        isActive: false,
      };
    },
    handleCreate() {
      this.resetTemp();
      this.dialogStatus = "create";
      this.dialogFormVisible = true;
      this.temp.activityId = this.$route.query.activityId;
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },
    createData() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          CreateAttendee(this.temp).then(() => {
            this.dialogFormVisible = false;
            this.$notify({
              title: "Attendee created",
              message: "",
              type: "success",
              duration: 1000,
            });
            this.getList();
          });
        }
      });
    },
    handleUpdate(row) {
      this.temp = Object.assign({}, row);
      this.temp.activityId = this.$route.query.activityId;
      this.dialogStatus = "update";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },
    updateData() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          UpdateAttendee(this.temp).then(() => {
            this.dialogFormVisible = false;
            this.$notify({
              title: "Attendee updated",
              message: "",
              type: "success",
              duration: 1000,
            });
            this.getList();
          });
        }
      });
    },
    handleDelete(row) {
      DeleteAttendee({ id: row.id }).then(() => {
        this.$notify({
          title: "Attendee deleted",
          message: "",
          type: "success",
          duration: 2000,
        });
        const index = this.list.indexOf(row);
        this.list.splice(index, 1);
      });
    },
    handleManualSend(row) {
      ManualSend({ id: row.id }).then(() => {
        this.$notify({
          title: "Email was sent to the attendee",
          message: "",
          type: "success",
          duration: 2000,
        });
        this.getList();
      });
    },
    isNumber: function (evt) {
      evt = evt || window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>
