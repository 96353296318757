<template>
  <div class="app-container">
    <p class="aligncenter">
      <img style="height: 100px;max-width: 300px;" :src="'data:image/jpg;base64,'+logo">
    </p>   
    <template>
      <h2>Thank you for completing the evaluation. Your Certificate will be emailed to you.</h2>
    </template>
  </div>
</template>

<style scoped>
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 3em 0;
  padding: 0;
}
.aligncenter {
  text-align: center;
}
.app-container {
  background: white;
}

.intervalCheckbox {
  line-height: 30px;
  height: 30px;
  margin-left: 0;
  display: block;
}

.el-checkbox {
  margin-left: 0px !important;
  margin-right: 30px !important;
}

.el-checkbox + .el-checkbox {
  margin-left: 0px !important;
  margin-right: 30px !important;
}
</style>

<script>
import { getToken } from "@/utils/auth";
import { getLogo, baseUrl } from "@/api/evaluation";
import waves from "@/directive/waves";
export default {
  name: "Thank you",
  directives: { waves },
  data() {
    return {
      logo: "",
      baseUrl: baseUrl,
      loading: false,      
    };
  },
  created() {
    this.getLogo();
  },
  methods: {
    getLocation() {
      return this.baseUrl.replace("api", "");
    },
    getLogo() {
      getLogo({
        uid: this.$route.query.uid
      }).then(response => {
        this.logo = response.data;
      });
    }
  }
};
</script>
