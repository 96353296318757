<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input
        v-if="listQuery.searchBy != 'TimePeriod'"
        :placeholder="$t(getSearchTypePlaceholder())"
        v-model="listQuery.term"
        style="width: 200px"
        class="filter-item"
        @keyup.enter.native="handleFilter"
      />
      <el-date-picker
        v-if="listQuery.searchBy == 'TimePeriod'"
        :placeholder="$t('activity.startDate')"
        v-model="listQuery.startDate"
        type="date"
        format="MM/dd/yyyy"
        style="width: 200px"
        class="filter-item"
      />
      <el-date-picker
        v-if="listQuery.searchBy == 'TimePeriod'"
        :placeholder="$t('activity.endDate')"
        v-model="listQuery.endDate"
        type="date"
        format="MM/dd/yyyy"
        style="width: 200px; margin-left: 20px"
        class="filter-item"
      />
      <el-select
        v-model="listQuery.searchBy"
        style="width: 140px"
        class="filter-item"
        @change="handleSearchtype"
      >
        <el-option
          v-for="item in searchTypes"
          :key="item.key"
          :label="item.label"
          :value="item.key"
        />
      </el-select>
      <el-button
        v-waves
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        @click="handleFilter"
        >{{ $t("table.search") }}</el-button
      >
      <el-button
        class="filter-item"
        style="margin-left: 10px"
        type="primary"
        icon="el-icon-edit"
        @click="handleCreate"
        >{{ $t("table.add") }}</el-button
      >
    </div>

    <el-table
      v-loading="listLoading"
      :key="tableKey"
      :data="list"
      border
      fit
      highlight-current-row
      style="width: 100%"
    >
      <el-table-column
        :label="$t('activity.activityId')"
        align="center"
        min-width="150px"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.activityId }}</span>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('activity.name')"
        min-width="130px"
        align="center"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('activity.timePeriod')"
        min-width="230px"
        align="center"
      >
        <template slot-scope="scope">
          <span
            >{{
              scope.row.startDate
                ? moment(scope.row.startDate).format("MM/DD/YYYY")
                : ""
            }}
            -
            {{
              scope.row.endDate
                ? moment(scope.row.endDate).format("MM/DD/YYYY")
                : ""
            }}</span
          >
        </template>
      </el-table-column>
      <el-table-column :label="$t('activity.area')" min-width="220px">
        <template slot-scope="scope">
          <span>{{ scope.row.area.name }}</span>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('activity.series')"
        min-width="160px"
        align="center"
      >
        <template slot-scope="scope">
          <span>{{
            scope.row.series != null ? scope.row.series.name : ""
          }}</span>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('table.actions')"
        align="center"
        min-width="260"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <div>
            <el-button
              type="primary"
              size="mini"
              @click="handleUpdate(scope.row)"
              >{{ $t("table.edit") }}</el-button
            >
            <el-button
              v-if="scope.row.series == null"
              size="mini"
              type="danger"
              @click="handleDelete(scope.row)"
              >{{ $t("table.delete") }}</el-button
            >
          </div>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />

    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible">
      <el-form
        ref="dataForm"
        :rules="rules"
        :model="temp"
        label-position="left"
        label-width="170px"
        style="width: 800px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item
              v-if="temp.seriesId > 0"
              :label="$t('activity.activityId')"
            >
              <label class="light-label">{{ temp.activityId }}</label>
            </el-form-item>
            <el-form-item
              v-else
              :label="$t('activity.activityId')"
              prop="activityId"
            >
              <el-input v-model="temp.activityId" />
            </el-form-item>
            <el-form-item :label="$t('activity.name')" prop="name">
              <el-input v-model="temp.name" />
            </el-form-item>
            <el-form-item
              :label="$t('activity.additionToTitle')"
              prop="additionToTitle"
            >
              <el-input v-model="temp.additionToTitle" />
            </el-form-item>
            <el-form-item :label="$t('activity.startDate')" prop="startDate">
              <el-date-picker
                v-model="temp.startDate"
                format="MM/dd/yyyy"
                type="date"
              />
            </el-form-item>
            <el-form-item :label="$t('activity.endDate')" prop="endDate">
              <el-date-picker
                v-model="temp.endDate"
                format="MM/dd/yyyy"
                type="date"
              />
            </el-form-item>
            <el-form-item v-if="temp.seriesId > 0" :label="$t('series.area')">
              <label class="light-label">{{ temp.area.name }}</label>
            </el-form-item>
            <el-form-item v-else :label="$t('series.area')" prop="area">
              <el-select
                v-model="temp.area"
                class="filter-item"
                value-key="id"
                placeholder="Please select area"
                style="width: 100%"
              >
                <el-option
                  v-for="item in areas"
                  :key="item.id"
                  :label="item.name"
                  :value="item"
                />
              </el-select>
            </el-form-item>
            <el-form-item
              v-if="temp.seriesId > 0"
              :label="$t('series.educationalPartner')"
            >
              <label class="light-label">{{ temp.educationalPartner }}</label>
            </el-form-item>
            <el-form-item v-else :label="$t('series.educationalPartner')">
              <el-input v-model="temp.educationalPartner" />
            </el-form-item>
            <el-form-item
              v-if="temp.seriesId > 0"
              :label="$t('series.commercialPartner')"
            >
              <label class="light-label">{{ temp.commercialPartner }}</label>
            </el-form-item>
            <el-form-item v-else :label="$t('series.commercialPartner')">
              <el-input v-model="temp.commercialPartner" />
            </el-form-item>
            <el-form-item
              :label="$t('activity.description')"
              prop="description"
            >
              <el-input v-model="temp.description" type="textarea" />
            </el-form-item>
          </el-col>

          <el-col
            v-if="temp.seriesId > 0 || temp.questionsFile != null"
            :span="8"
          >
            <el-form-item
              :label="$t('series.interval')"
              class="intervalsContainer"
              style="margin-left: 10"
            >
              <div style="display: block">
                <div v-for="interval in temp.intervals" :key="interval">
                  <div class="el-form-item__content" style="clear: both">
                    <label class="light-label">{{
                      intervals.find((item) => item.id == interval).name
                    }}</label>
                  </div>
                </div>
              </div>
            </el-form-item>
          </el-col>

          <el-col v-else :span="8">
            <el-form-item
              :label="$t('series.interval')"
              class="intervalsContainer"
              prop="intervals"
              style="margin-left: 10"
            >
              <div style="display: block">
                <el-checkbox-group v-model="temp.intervals">
                  <el-checkbox
                    v-for="(interval, $index) in intervals"
                    :label="interval.id"
                    :key="interval.id"
                    :class="{ 'intervalCheckbox-even': $index % 2 === 0 }"
                    class="intervalCheckbox"
                    >{{ interval.name }}</el-checkbox
                  >
                </el-checkbox-group>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- <el-row>
          <el-col :span="20">
            <div style="width: 100%; border-bottom:1px solid black; padding-bottom: 10px;">
              <span style="font-weight:bold;">Attendees</span>
            </div>
          </el-col>
        </el-row>-->
        <el-row>
          <!-- <el-row>
            <el-col :span="1">
              <div class="grid-content">
                <svg-icon v-if="temp.responsesFile!=null" icon-class="documentation"/>
              </div>
            </el-col>
            <el-col :span="3">
              <div class="grid-content">
                <label>Attendees</label>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content">
                <span
                  v-if="temp.attendeesFile!=null"
                >{{ temp.attendeesFile!=null?new Date(temp.attendeesFile.createdDate).format("MM/DD/YYYY h:mm a"):"" }}</span>
              </div>
            </el-col>
            <el-col :span="4">
              <div>
                <div
                  v-if="temp.attendeesFile==null"
                  :disabled="temp.attendeesFile==null"
                  class="grid-content"
                >
                  <el-upload
                    class="upload"
                    :action="uploadBaseUrl+'/activities/uploadFile'"
                    :multiple="false"
                    :auto-upload="true"
                    :show-file-list="false"
                    name="Content"
                    :headers="headerInfo"
                    :data="fileDataAttendees()"
                    :on-success="onAttendeeUploadSuccess"
                    :on-error="onFileUploadError"
                  >
                    <el-button size="small" type="primary">Upload</el-button>
                  </el-upload>
                </div>
                <div v-if="temp.attendeesFile!=null" class="grid-content">
                  <el-button size="small" type="danger" @click="handleAttendeeDeleteFile()">Delete</el-button>
                </div>
              </div>
            </el-col>
          </el-row>-->
          <el-row>
            <el-col :span="20">
              <div
                style="
                  width: 100%;
                  border-bottom: 1px solid black;
                  padding-bottom: 10px;
                "
              >
                <span style="font-weight: bold">Survey</span>
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="1">
              <div class="grid-content">
                <svg-icon
                  v-if="temp.questionsFile != null"
                  icon-class="documentation"
                />
              </div>
            </el-col>
            <el-col :span="3">
              <div class="grid-content">
                <label>Questions</label>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content">
                <div v-if="temp.questionsFile != null">
                  {{
                    temp.questionsFile != null
                      ? moment(temp.questionsFile.createdDate).format(
                          "MM/DD/YYYY h:mm a"
                        )
                      : ""
                  }}
                </div>
              </div>
            </el-col>
            <el-col :span="3">
              <div v-if="temp.questionsFile == null" class="grid-content">
                <el-upload
                  :action="uploadBaseUrl + '/activities/uploadFile'"
                  :multiple="false"
                  :auto-upload="true"
                  :show-file-list="false"
                  :headers="headerInfo"
                  :data="fileDataQuestions()"
                  :on-success="onQuestionsUploadSuccess"
                  :on-error="onFileUploadError"
                  class="upload"
                  name="Content"
                >
                  <el-button size="small" type="primary">Upload</el-button>
                </el-upload>
              </div>
              <div v-else class="grid-content">
                <el-button
                  v-if="temp.responsesFile == null"
                  size="small"
                  type="danger"
                  @click="handleQuestionsDeleteFile()"
                  >Delete</el-button
                >
                <el-button
                  v-else
                  hint="Please remove responses file first"
                  size="small"
                  type="danger"
                  disabled
                  >Delete</el-button
                >
              </div>
            </el-col>
            <el-col :span="3">
              <div v-if="temp.questionsFile != null" class="grid-content">
                <el-button
                  size="small"
                  type="warning"
                  @click="exportQuestions(temp.id)"
                  >Export</el-button
                >
              </div>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="1">
              <div class="grid-content">
                <svg-icon
                  v-if="temp.responsesFile != null"
                  icon-class="documentation"
                />
              </div>
            </el-col>
            <el-col :span="3">
              <div class="grid-content">
                <label>Responses</label>
              </div>
            </el-col>
            <el-col :span="6">
              <div class="grid-content">
                <span v-if="temp.responsesFile != null">{{
                  temp.responsesFile != null
                    ? new Date(temp.responsesFile.createdDate).format(
                        "MM/DD/YYYY h:mm a"
                      )
                    : ""
                }}</span>
              </div>
            </el-col>
            <el-col :span="3">
              <div v-if="temp.questionsFile == null" class="grid-content">
                <el-button
                  title="Please upload questions first"
                  class="upload"
                  size="small"
                  type="danger"
                  disabled
                  >Upload</el-button
                >
              </div>
              <div v-else>
                <div
                  v-if="temp.responsesFile == null"
                  :disabled="temp.questionsFile == null"
                  class="grid-content"
                >
                  <el-upload
                    :action="uploadBaseUrl + '/activities/uploadFile'"
                    :multiple="false"
                    :auto-upload="true"
                    :show-file-list="false"
                    :headers="headerInfo"
                    :data="fileDataResponses()"
                    :on-success="onResponsesUploadSuccess"
                    :on-error="onFileUploadError"
                    class="upload"
                    name="Content"
                  >
                    <el-button size="small" type="primary">Upload</el-button>
                  </el-upload>
                </div>
                <div v-if="temp.responsesFile != null" class="grid-content">
                  <el-button
                    size="small"
                    type="danger"
                    @click="handleResponsesDeleteFile()"
                    >Delete</el-button
                  >
                </div>
              </div>
            </el-col>
            <el-col :span="3">
              <div v-if="temp.questionsFile != null" class="grid-content">
                <el-button
                  size="small"
                  type="warning"
                  @click="exportResponses(temp.id)"
                  >Export</el-button
                >
              </div>
            </el-col>
          </el-row>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">{{
          $t("table.cancel")
        }}</el-button>
        <el-button
          type="primary"
          @click="dialogStatus === 'create' ? createData() : updateData()"
          >{{ $t("table.confirm") }}</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<style>
.intervalsContainer .el-form-item__content {
  margin-left: 0 !important;
}
</style>

<style scoped>
.grid-content {
  height: 36px;
  line-height: 36px;
}

.intervalsContainer {
  border: 1px solid #ebeef5;
  border-radius: 5px;
  padding: 0 10px;
  margin-left: 10px;
}

.intervalCheckbox {
  padding-left: 5px;
  margin-left: 5px;
  width: 100%;
  line-height: 40px;
  height: 40px;
}

.intervalCheckbox-even {
  background: #ebeef5;
}

.light-label {
  font-weight: 100 !important;
}
</style>

<script>
import FileSaver from "file-saver";
import { getToken } from "@/utils/auth";
import { getAll as IntervalsGetAll } from "@/api/intervals";
import { getAll as AreasGetAll } from "@/api/areas";
import {
  baseUrl,
  search,
  create as CreateActivity,
  update as UpdateActivity,
  remove as DeleteActivity,
  deleteFile as DeleteFile,
  exportQuestions as ExportQuestions,
  exportResponses as ExportResponses,
} from "@/api/activities";
import waves from "@/directive/waves"; // Waves directive
import { parseTime } from "@/utils";
import Pagination from "@/components/Pagination"; // Secondary package based on el-pagination
import moment from "moment";

export default {
  name: "ActivityGrid",
  components: { Pagination },
  directives: { waves },
  data() {
    var validateStartDate = (rule, value, callback) => {
      if (this.temp.endDate) {
        if (new Date(value) > new Date(this.temp.endDate)) {
          callback(new Error("Start Date must be before than End Date"));
        }
      }

      callback();
    };

    var validateEndDate = (rule, value, callback) => {
      if (this.temp.startDate && value) {
        if (new Date(this.temp.startDate) > new Date(value)) {
          callback(new Error("End Date must be after than Start Date"));
        }
      }

      callback();
    };
    return {
      uploadBaseUrl: baseUrl,
      tableKey: 0,
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 20,
        searchBy: "ActivityId",
        term: "",
        startDate: null,
        endDate: null,
      },
      searchTypes: [
        { label: "Activity ID", key: "ActivityId" },
        { label: "Series Name", key: "SeriesName" },
        { label: "Time Period", key: "TimePeriod" },
      ],
      areas: null,
      intervals: null,
      temp: {
        id: undefined,
        seriesId: undefined,
        name: "",
        startDate: "",
        endDate: null,
        description: "",
        commercialPartner: "",
        educationalPartner: "",
        intervals: [],
        activitiesCount: 0,
        questionsFile: null,
        responsesFile: null,
        attendeesFile: null,
      },
      dialogFormVisible: false,
      dialogStatus: "",
      textMap: {
        update: "Edit Activity",
        create: "Create Activity",
      },
      headerInfo: {
        Authorization: "Bearer " + getToken(),
      },
      rules: {
        activityId: [
          { required: true, message: "Name is required", trigger: "change" },
        ],
        startDate: [
          {
            required: true,
            validator: validateStartDate,
            trigger: "blur",
          },
        ],
        endDate: [
          {
            validator: validateEndDate,
            trigger: "blur",
          },
        ],
        area: [
          {
            required: true,
            message: "Therapeutic area is required",
            trigger: "change",
          },
        ],
        intervals: [
          {
            type: "array",
            required: true,
            message: "Please select at least one interval",
            trigger: "change",
          },
        ],
      },
      downloadLoading: false,
    };
  },
  created() {
    this.getAllAreas(), this.getAllIntervals(), this.getList();
  },
  methods: {
    b64toBlob: function (b64Data, contentType, sliceSize) {
      contentType = contentType || "";
      sliceSize = sliceSize || 512;

      var byteCharacters = atob(b64Data);
      var byteArrays = [];

      for (
        var offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      var blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
    moment: function (date) {
      if (!date) {
        return moment();
      } else {
        return moment(date);
      }
    },
    momentutc: function (date) {
      if (!date) {
        return moment.utc();
      } else {
        return moment.utc(date);
      }
    },
    handleAttendeeDeleteFile(fileId) {
      this.temp.attendeesFile = null;
    },
    handleResponsesDeleteFile(fileId) {
      this.temp.responsesFile = null;
    },
    handleQuestionsDeleteFile(fileId) {
      this.temp.questionsFile = null;
    },
    openReport(row) {
      this.$router.push({ path: "/reports/edit?activityId=" + row.id });
    },
    openAttendees(row) {
      this.$router.push({ path: "/attendees/list?activityId=" + row.id });
    },
    openWebSurvey(row) {
      this.$router.push({ path: "/reports/webedit?activityId=" + row.id });
    },
    openEvaluation(row) {
      this.$router.push({ path: "/evaluation/edit?activityId=" + row.id });
    },
    openCertificates(row) {
      this.$router.push({
        path: "/activities/certificates?activityId=" + row.id,
      });
    },
    onAttendeeUploadSuccess(response, file, fileList) {
      if (response.success) {
        this.temp.attendeesFile = response.fileDTO;
      } else {
        this.$notify({
          title: "File upload error",
          message: response.error,
          type: "error",
          duration: 5000,
        });
      }
    },
    onResponsesUploadSuccess(response, file, fileList) {
      if (response.success) {
        this.temp.responsesFile = response.fileDTO;
      } else {
        this.$notify({
          title: "File upload error",
          message: response.error,
          type: "error",
          duration: 5000,
        });
      }
    },
    onQuestionsUploadSuccess(response, file, fileList) {
      if (response.success) {
        this.temp.questionsFile = response.fileDTO;
      } else {
        this.$notify({
          title: "File upload error",
          message: response.error,
          type: "error",
          duration: 5000,
        });
      }
    },
    onFileUploadError(err) {
      this.$notify({
        title: "File upload error",
        message: err,
        type: "error",
        duration: 5000,
      });
    },
    fileDataQuestions() {
      return {
        ActivityId: this.temp.id == undefined ? 0 : this.temp.id,
        FileType: "Questions",
      };
    },
    fileDataResponses() {
      return {
        questionsFileId: this.temp.questionsFile.id,
        ActivityId: this.temp.id == undefined ? 0 : this.temp.id,
        FileType: "Responses",
      };
    },
    fileDataAttendees() {
      return {
        ActivityId: this.temp.id == undefined ? 0 : this.temp.id,
        FileType: "Attendees",
      };
    },
    getSearchTypePlaceholder() {
      var result = "";
      if (this.listQuery.searchBy == "ActivityId") {
        result = "searchType.activityId";
      } else if (this.listQuery.searchBy == "SeriesName") {
        result = "searchType.seriesName";
      } else if (this.listQuery.searchBy == "TimePeriod") {
        result = "searchType.timePeriod";
      }

      return result;
    },
    getAllAreas() {
      AreasGetAll().then((response) => {
        this.areas = response.data;
      });
    },
    getAllIntervals() {
      IntervalsGetAll().then((response) => {
        this.intervals = response.data;
      });
    },
    getList() {
      this.listLoading = true;

      search(this.listQuery).then((response) => {
        this.list = response.data.items;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    handleSearchtype() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    resetTemp() {
      this.temp = {
        id: undefined,
        seriesId: undefined,
        name: "",
        startDate: "",
        endDate: null,
        description: "",
        intervals: [],
        commercialPartner: "",
        educationalPartner: "",
        questionsFile: null,
        responsesFile: null,
        attendeesFile: null,
      };
    },
    handleCreate() {
      this.resetTemp();
      this.dialogStatus = "create";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },
    createData() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          console.log(JSON.stringify(this.tmp));
          CreateActivity(this.temp).then(() => {
            this.dialogFormVisible = false;
            this.$notify({
              title: "Activity created1",
              message: "",
              type: "success",
              duration: 1000,
            });
            this.getList();
          });
        }
      });
    },
    handleUpdate(row) {
      this.$router.push({
        path: "/activities/activityDetails?activityId=" + row.id,
      });
    },
    updateData() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          UpdateActivity(this.temp).then(() => {
            this.dialogFormVisible = false;
            this.$notify({
              title: "Activity updated",
              message: "",
              type: "success",
              duration: 1000,
            });
            this.getList();
          });
        }
      });
    },
    handleDelete(row) {
      DeleteActivity({ id: row.id }).then(() => {
        this.$notify({
          title: "Activity deleted",
          message: "",
          type: "success",
          duration: 2000,
        });
        const index = this.list.indexOf(row);
        this.list.splice(index, 1);
      });
    },
    isNumber: function (evt) {
      evt = evt || window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    exportQuestions(activityId) {
      ExportQuestions({ activityId: activityId }).then((response) => {
        var blob = this.b64toBlob(
          response.data,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
        FileSaver.saveAs(blob, "Questions.xlsx");
      });
    },
    exportResponses(activityId) {
      ExportResponses({ activityId: activityId }).then((response) => {
        var blob = this.b64toBlob(
          response.data,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
        FileSaver.saveAs(blob, "Responses.xlsx");
      });
    },
  },
};
</script>
