<template>
  <div class="app-container">
    <div class="filter-container">
      <el-input
        :placeholder="$t(getSearchTypePlaceholder())"
        v-model="listQuery.term"
        style="width: 200px;"
        class="filter-item"
        @keyup.enter.native="handleFilter"
      />

      <el-select
        v-model="listQuery.searchBy"
        style="width: 140px"
        class="filter-item"
        @change="handleSearchtype"
      >
        <el-option
          v-for="item in searchTypes"
          :key="item.key"
          :label="item.label"
          :value="item.key"
        />
      </el-select>
      <el-button
        v-waves
        class="filter-item"
        type="primary"
        icon="el-icon-search"
        @click="handleFilter"
      >{{ $t('table.search') }}</el-button>
      <el-button
        class="filter-item"
        style="margin-left: 10px;"
        type="primary"
        icon="el-icon-edit"
        @click="handleCreate"
      >{{ $t('table.add') }}</el-button>
    </div>

    <el-table
      v-loading="listLoading"
      :key="tableKey"
      :data="list"
      border
      fit
      highlight-current-row
      style="width: 100%;"
    >
      <el-table-column :label="$t('series.activityId')" align="center" width="150px">
        <template slot-scope="scope">
          <span>{{ scope.row.activityId }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('series.name')" min-width="200px" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.name }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('series.seriesNumber')" min-width="200px" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.number }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('series.area')" width="220px">
        <template slot-scope="scope">
          <span>{{ scope.row.area.name }}</span>
        </template>
      </el-table-column>
      <el-table-column :label="$t('series.activitiesCount')" width="130px" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.activitiesCount }}</span>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('table.actions')"
        align="center"
        width="230"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            @click="handleView(scope.row)"
          >{{ $t('table.view') }}</el-button>
          <el-button
            v-if="scope.row.canDelete"
            size="mini"
            type="danger"
            @click="handleDelete(scope.row)"
          >{{ $t('table.delete') }}</el-button>
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total>0"
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />

    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogViewFormVisible">
      <el-form
        ref="dataForm"
        :rules="rules"
        :model="temp"
        label-position="left"
        label-width="170px"
        style="width: 800px;"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item :label="$t('series.activityId')">
              <label class="light-label">{{ temp.activityId }}</label>
            </el-form-item>
            <el-form-item :label="$t('series.name')">
              <label class="light-label">{{ temp.name }}</label>
            </el-form-item>
            <el-form-item :label="$t('series.seriesNumber')">
              <label class="light-label">{{ temp.number }}</label>
            </el-form-item>
            <el-form-item :label="$t('series.activitiesCountFull')">
              <label class="light-label">{{ temp.activitiesCount }}</label>
            </el-form-item>
            <el-form-item :label="$t('series.area')">
              <label class="light-label">{{ temp.area.name }}</label>
            </el-form-item>
            <el-form-item :label="$t('series.educationalPartner')">
              <label class="light-label">{{ temp.educationalPartner }}</label>
            </el-form-item>
            <el-form-item :label="$t('series.commercialPartner')">
              <label class="light-label">{{ temp.commercialPartner }}</label>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item
              :label="$t('series.interval')"
              class="intervalsContainer"
              style="margin-left: 10;"
            >
              <div style="display: block;">
                <div v-for="interval in temp.intervals" :key="interval">
                  <div class="el-form-item__content" style="clear: both;">
                    <label class="light-label">{{ intervals.find(item=>item.id==interval).name }}</label>
                  </div>
                </div>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="closeViewDialog()">{{ $t('tagsView.close') }}</el-button>
      </div>
    </el-dialog>

    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogCreateFormVisible">
      <el-form
        ref="dataForm"
        :rules="rules"
        :model="temp"
        label-position="left"
        label-width="170px"
        style="width: 800px;"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item :label="$t('series.activityId')" prop="activityId">
              <el-input v-model="temp.activityId"/>
            </el-form-item>
            <el-form-item :label="$t('series.name')" prop="name">
              <el-input v-model="temp.name"/>
            </el-form-item>
            <el-form-item :label="$t('series.seriesNumber')" prop="seriesNumber">
              <el-input v-model="temp.number"/>
            </el-form-item>
            <el-form-item :label="$t('series.activitiesCountFull')" prop="activitiesCount">
              <el-input v-model="temp.activitiesCount" @keypress.native="isNumber"/>
            </el-form-item>
            <el-form-item :label="$t('series.area')" prop="area">
              <el-select
                v-model="temp.area"
                value-key="id"
                class="filter-item"
                placeholder="Please select area"
                style="width: 100%"
              >
                <el-option v-for="item in areas" :key="item.id" :label="item.name" :value="item"/>
              </el-select>
            </el-form-item>
            <el-form-item :label="$t('series.commercialPartner')" prop="commercialPartner">
              <el-input v-model="temp.commercialPartner"/>
            </el-form-item>
            <el-form-item :label="$t('series.educationalPartner')" prop="educationalPartner">
              <el-input v-model="temp.educationalPartner"/>
            </el-form-item>
          </el-col>

          <el-col :span="8">
            <el-form-item
              :label="$t('series.interval')"
              class="intervalsContainer"
              prop="intervals"
              style="margin-left: 10;"
            >
              <div style="display: block;">
                <el-checkbox-group v-model="temp.intervals">
                  <el-checkbox
                    v-for="(interval, $index) in intervals"
                    :label="interval.id"
                    :key="interval.id"
                    :class="{'intervalCheckbox-even': $index % 2 === 0}"
                    class="intervalCheckbox"
                  >{{ interval.name }}</el-checkbox>
                </el-checkbox-group>
              </div>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogCreateFormVisible = false">{{ $t('table.cancel') }}</el-button>
        <el-button type="primary" @click="createData">{{ $t('table.confirm') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<style>
.intervalsContainer .el-form-item__content {
  margin-left: 0 !important;
}
</style>
<style scoped>
.intervalsContainer {
  border: 1px solid #ebeef5;
  border-radius: 5px;
  padding: 0 10px;
  margin-left: 10px;
}

.intervalCheckbox {
  padding-left: 5px;
  margin-left: 5px;
  width: 100%;
  line-height: 40px;
  height: 40px;
}

.intervalCheckbox-even {
  background: #ebeef5;
}

.light-label {
  font-weight: 100 !important;
}
</style>

<script>
import { getAll as IntervalsGetAll } from "@/api/intervals";
import { getAll as AreasGetAll } from "@/api/areas";
import { search, create as CreateSeries, update as UpdateSeries, remove as DeleteSeries, checkActivityIdUnique as CheckActivityIdUnique } from "@/api/series";
import waves from "@/directive/waves"; // Waves directive
import { parseTime } from "@/utils";
import Pagination from "@/components/Pagination"; // Secondary package based on el-pagination

var checkActivityIdUnique = (rule, value, callback) => {
  CheckActivityIdUnique({ activityId: value }).then(response => {
    if (!response.data) {
      callback(new Error("Activity ID is not unique"));
    } else {
      callback();
    }
  });
};

var validateActivitiesCount = (rule, value, callback) => {
  if (1 > value || value > 99 || value == 0) {
    callback(new Error("Enter a number between 1-99"));
  } else {
    callback();
  }
};

export default {
  name: "SeriesGrid",
  components: { Pagination },
  directives: { waves },
  data() {
    return {
      tableKey: 0,
      list: null,
      total: 0,
      listLoading: true,
      listQuery: {
        page: 1,
        limit: 20,
        searchBy: "Name",
        term: ""
      },
      searchTypes: [{ label: "Name", key: "Name" }, { label: "Activity ID", key: "ActivityId" }],
      areas: null,
      intervals: null,
      temp: {
        id: undefined,
        name: "",
        activityId: "",
        area: "",
        seriesNumber: "",
        educationalPartner: "",
        commercialPartner: "",
        intervals: [],
        activitiesCount: 0,
        canDelete: false
      },
      dialogCreateFormVisible: false,
      dialogViewFormVisible: false,
      dialogStatus: "",
      textMap: {
        view: "Series",
        create: "Create Series"
      },
      dialogPvVisible: false,
      pvData: [],
      rules: {
        name: [{ required: true, message: "Name is required", trigger: "change" }],
        activityId: [{ required: true, message: "Activity ID is required", trigger: "change" }, { validator: checkActivityIdUnique, trigger: "change" }],
        activitiesCount: [
          {
            required: true,
            message: "Number of Activities is required",
            trigger: "change"
          },
          { validator: validateActivitiesCount, trigger: "change" },
          { validator: validateActivitiesCount, trigger: "blur" }
        ],
        area: [
          {
            required: true,
            message: "Therapeutic area is required",
            trigger: "change"
          }
        ],
        intervals: [
          {
            type: "array",
            required: true,
            message: "Please select at least one interval",
            trigger: "change"
          }
        ]
      },
      downloadLoading: false
    };
  },
  created() {
    this.getAllAreas(), this.getAllIntervals(), this.getList();
  },
  methods: {
    getSearchTypePlaceholder() {
      if (this.listQuery.searchBy == "Name") {
        return "searchType.name";
      } else if (this.listQuery.searchBy == "ActivityId") {
        return "searchType.activityId";
      }
      return this.listQuery.searchType;
    },
    getAllAreas() {
      AreasGetAll().then(response => {
        this.areas = response.data;
      });
    },
    getAllIntervals() {
      IntervalsGetAll().then(response => {
        this.intervals = response.data;
      });
    },
    getList() {
      this.listLoading = true;

      search(this.listQuery).then(response => {
        this.list = response.data.items;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    handleSearchtype() {
      this.listQuery.page = 1;
      this.getList();
    },
    handleFilter() {
      this.listQuery.page = 1;
      this.getList();
    },
    resetTemp() {
      this.temp = {
        id: undefined,
        name: "",
        activityId: "",
        area: "",
        seriesNumber: "",
        intervals: [],
        activitiesCount: 0,
        canDelete: false
      };
    },
    handleCreate() {
      this.resetTemp();
      this.dialogStatus = "create";
      this.dialogCreateFormVisible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },
    createData() {
      this.$refs["dataForm"].validate(valid => {
        if (valid) {
          CreateSeries(this.temp).then(() => {
            this.dialogCreateFormVisible = false;
            this.$notify({
              title: "Series created",
              message: "",
              type: "success",
              duration: 1000
            });
            this.getList();
          });
        }
      });
    },
    handleView(row) {
      this.temp = Object.assign({}, row);
      this.dialogStatus = "view";
      this.dialogViewFormVisible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },
    closeViewDialog() {
      this.dialogViewFormVisible = false;
    },
    updateData() {
      this.$refs["dataForm"].validate(valid => {
        if (valid) {
          UpdateSeries(this.temp).then(() => {
            this.dialogCreateFormVisible = false;
            this.$notify({
              title: "Series updated",
              message: "",
              type: "success",
              duration: 1000
            });
            this.getList();
          });
        }
      });
    },
    handleDelete(row) {
      DeleteSeries({ id: row.id }).then(() => {
        this.$notify({
          title: "Series deleted",
          message: "",
          type: "success",
          duration: 2000
        });
        const index = this.list.indexOf(row);
        this.list.splice(index, 1);
      });
    },
    isNumber: function(evt) {
      evt = evt || window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    }
  }
};
</script>
