<template>
  <div class="app-container">
    <el-tabs
      v-if="selectedEvaluationType"
      v-model="selectedEvaluationType"
      type="card"
    >
      <el-tab-pane
        v-for="evaluation in evaluations"
        :key="evaluation.evaluationType.id"
        :label="getEvaluationName(evaluation.evaluationType.name)"
        :name="evaluation.evaluationType.name"
      >
        <div class="filter-container">
          <el-button
            class="filter-item"
            style="margin-left: 10px"
            type="primary"
            icon="el-icon-edit"
            @click="handleAddQuestion"
            >Add question</el-button
          >
          <el-button
            v-if="evaluation.id > 0"
            class="filter-item"
            style="margin-left: 10px"
            type="primary"
            icon="el-icon-share"
            @click="handlePreview"
            >Preview</el-button
          >
          <el-button
            v-if="!evaluation.isSent"
            class="filter-item"
            style="margin-left: 10px"
            type="primary"
            icon="el-icon-check"
            @click="handleSave"
            >Save</el-button
          >
        </div>
        <div>
          <el-input
            v-model="evaluation.intro"
            type="textarea"
            placeholder="Intro"
            style="width: 400px"
          />
          <el-date-picker
            v-if="evaluation.evaluationType.name != 'Post'"
            v-model="evaluation.date"
            type="date"
            placeholder="Date"
            format="MM/dd/yyyy"
            style="width: 200px; vertical-align: top"
          />
        </div>
        <div
          v-if="evaluation.evaluationType.name == 'Post'"
          style="margin-top: 10px"
        >
          <span style="vertical-align: bottom">Passing percent:</span>
          <el-input-number
            v-model="evaluation.passingPercent"
            :min="0"
            :max="100"
            style="vertical-align: top"
            controls-position="right"
          />
        </div>
        <div
          v-if="evaluation.evaluationType.name == 'Evaluation'"
          style="margin-top: 10px"
        >
          <span style="vertical-align: bottom"
            >Include Post Test in Evaluation:</span
          >
          <el-checkbox v-model="evaluation.includePostTest" />
        </div>
        <div
          v-if="evaluation.evaluationType.name == 'Evaluation'"
          style="margin-top: 10px"
        />
        <el-table
          v-loading="evaluation.loading"
          :data="evaluation.questions"
          :row-class-name="tableRowClassName"
          style="width: 100%"
        >
          <el-table-column prop="Include" label="Include" width="75">
            <template slot-scope="scope">
              <el-checkbox
                :checked="scope.row.Include"
                @change="handleIncludeQuestion(scope.row, $event)"
              />
            </template>
          </el-table-column>
          <el-table-column prop="Required" label="Required" width="85">
            <template slot-scope="scope">
              <el-checkbox
                :checked="scope.row.Required"
                @change="handleRequiredQuestion(scope.row, $event)"
              />
            </template>
          </el-table-column>
          <el-table-column
            prop="ResponseType"
            label="Response Type"
            width="130"
          />
          <el-table-column prop="Interval" label="Interval" width="80" />
          <el-table-column prop="Question" label="Question" width="250" />
          <el-table-column
            prop="PreferredAnswer"
            label="Correct Answer"
            width="130"
          />
          <el-table-column prop="Answer1" label="Answer 1" width="85" />
          <el-table-column prop="Answer2" label="Answer 2" width="85" />
          <el-table-column prop="Answer3" label="Answer 3" width="85" />
          <el-table-column prop="Answer4" label="Answer 4" width="85" />
          <el-table-column prop="Answer5" label="Answer 5" width="85" />
          <el-table-column prop="Answer6" label="Answer 6" width="85" />
          <el-table-column prop="Answer7" label="Answer 7" width="85" />
          <el-table-column prop="Answer8" label="Answer 8" width="85" />
          <el-table-column prop="Answer9" label="Answer 9" width="85" />
          <el-table-column prop="Answer10" label="Answer 10" width="85" />
          <el-table-column prop="Answer11" label="Answer 11" width="85" />
          <el-table-column prop="Answer12" label="Answer 12" width="85" />
          <el-table-column prop="Answer13" label="Answer 13" width="85" />
          <el-table-column prop="Answer14" label="Answer 14" width="85" />
          <el-table-column prop="Answer15" label="Answer 15" width="85" />
          <el-table-column prop="Answer16" label="Answer 16" width="85" />
          <el-table-column prop="Answer17" label="Answer 17" width="85" />
          <el-table-column prop="Answer18" label="Answer 18" width="85" />
          <el-table-column prop="Answer19" label="Answer 19" width="85" />
          <el-table-column prop="Answer20" label="Answer 20" width="85" />
          <el-table-column prop="Answer21" label="Answer 21" width="85" />
          <el-table-column prop="Answer22" label="Answer 22" width="85" />
          <el-table-column prop="Answer23" label="Answer 23" width="85" />
          <el-table-column prop="Answer24" label="Answer 24" width="85" />
          <el-table-column prop="Answer25" label="Answer 25" width="85" />
          <el-table-column prop="Answer26" label="Answer 26" width="85" />
          <el-table-column prop="Answer27" label="Answer 27" width="85" />
          <el-table-column prop="Answer28" label="Answer 28" width="85" />
          <el-table-column prop="Answer29" label="Answer 29" width="85" />
          <el-table-column prop="Answer30" label="Answer 30" width="85" />
        </el-table>
      </el-tab-pane>
    </el-tabs>
    <el-dialog
      :visible.sync="dialogFormVisible"
      title="Add Question"
      widht="80%"
    >
      <el-form
        ref="questionForm"
        :model="tempQuestion"
        label-position="left"
        label-width="150px"
      >
        <el-row>
          <el-form-item label="Question" prop="Question">
            <el-input v-model="tempQuestion.Question" type="textarea" />
          </el-form-item>
        </el-row>
        <el-row>
          <el-col :span="13">
            <el-form-item label="Response Type" prop="ResponseType">
              <el-select
                v-model="tempQuestion.ResponseType"
                placeholder="Resposne Type"
              >
                <el-option
                  v-for="responseType in responseTypes"
                  :key="responseType.name"
                  :label="responseType.name"
                  :value="responseType.name"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="Interval" prop="Interval">
              <span>{{ tempQuestion.interval }}</span>
            </el-form-item>
            <el-form-item label="Correct Answer" prop="PreferredAnswer">
              <el-input
                v-model="tempQuestion.PreferredAnswer"
                type="text"
                style="width: 50px"
              />
            </el-form-item>
          </el-col>
          <el-col :span="11">
            <el-form-item label="Answer 1" prop="Answer1">
              <el-input v-model="tempQuestion.Answer1" type="textarea" />
            </el-form-item>
            <el-form-item label="Answer 2" prop="Answer2">
              <el-input v-model="tempQuestion.Answer2" type="textarea" />
            </el-form-item>
            <el-form-item label="Answer 3" prop="Answer3">
              <el-input v-model="tempQuestion.Answer3" type="textarea" />
            </el-form-item>
            <el-form-item label="Answer 4" prop="Answer4">
              <el-input v-model="tempQuestion.Answer4" type="textarea" />
            </el-form-item>
            <el-form-item label="Answer 5" prop="Answer5">
              <el-input v-model="tempQuestion.Answer5" type="textarea" />
            </el-form-item>
            <el-form-item label="Answer 6" prop="Answer6">
              <el-input v-model="tempQuestion.Answer6" type="textarea" />
            </el-form-item>
            <el-form-item label="Answer 7" prop="Answer7">
              <el-input v-model="tempQuestion.Answer7" type="textarea" />
            </el-form-item>
            <el-form-item label="Answer 8" prop="Answer8">
              <el-input v-model="tempQuestion.Answer8" type="textarea" />
            </el-form-item>
            <el-form-item label="Answer 9" prop="Answer9">
              <el-input v-model="tempQuestion.Answer9" type="textarea" />
            </el-form-item>
            <el-form-item label="Answer 10" prop="Answer10">
              <el-input v-model="tempQuestion.Answer10" type="textarea" />
            </el-form-item>
            <el-form-item label="Answer 11" prop="Answer11">
              <el-input v-model="tempQuestion.Answer11" type="textarea" />
            </el-form-item>
            <el-form-item label="Answer 12" prop="Answer12">
              <el-input v-model="tempQuestion.Answer12" type="textarea" />
            </el-form-item>
            <el-form-item label="Answer 13" prop="Answer13">
              <el-input v-model="tempQuestion.Answer13" type="textarea" />
            </el-form-item>
            <el-form-item label="Answer 14" prop="Answer14">
              <el-input v-model="tempQuestion.Answer14" type="textarea" />
            </el-form-item>
            <el-form-item label="Answer 15" prop="Answer15">
              <el-input v-model="tempQuestion.Answer15" type="textarea" />
            </el-form-item>
            <el-form-item label="Answer 16" prop="Answer16">
              <el-input v-model="tempQuestion.Answer16" type="textarea" />
            </el-form-item>
            <el-form-item label="Answer 17" prop="Answer17">
              <el-input v-model="tempQuestion.Answer17" type="textarea" />
            </el-form-item>
            <el-form-item label="Answer 18" prop="Answer18">
              <el-input v-model="tempQuestion.Answer18" type="textarea" />
            </el-form-item>
            <el-form-item label="Answer 19" prop="Answer19">
              <el-input v-model="tempQuestion.Answer19" type="textarea" />
            </el-form-item>
            <el-form-item label="Answer 20" prop="Answer20">
              <el-input v-model="tempQuestion.Answer20" type="textarea" />
            </el-form-item>
            <el-form-item label="Answer 21" prop="Answer21">
              <el-input v-model="tempQuestion.Answer21" type="textarea" />
            </el-form-item>
            <el-form-item label="Answer 22" prop="Answer22">
              <el-input v-model="tempQuestion.Answer22" type="textarea" />
            </el-form-item>
            <el-form-item label="Answer 23" prop="Answer23">
              <el-input v-model="tempQuestion.Answer23" type="textarea" />
            </el-form-item>
            <el-form-item label="Answer 24" prop="Answer24">
              <el-input v-model="tempQuestion.Answer24" type="textarea" />
            </el-form-item>
            <el-form-item label="Answer 25" prop="Answer25">
              <el-input v-model="tempQuestion.Answer25" type="textarea" />
            </el-form-item>
            <el-form-item label="Answer 26" prop="Answer26">
              <el-input v-model="tempQuestion.Answer26" type="textarea" />
            </el-form-item>
            <el-form-item label="Answer 27" prop="Answer27">
              <el-input v-model="tempQuestion.Answer27" type="textarea" />
            </el-form-item>
            <el-form-item label="Answer 28" prop="Answer28">
              <el-input v-model="tempQuestion.Answer28" type="textarea" />
            </el-form-item>
            <el-form-item label="Answer 29" prop="Answer29">
              <el-input v-model="tempQuestion.Answer29" type="textarea" />
            </el-form-item>
            <el-form-item label="Answer 30" prop="Answer30">
              <el-input v-model="tempQuestion.Answer30" type="textarea" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="handleSaveQuestion">Save</el-button>
        <el-button @click="handleCancel">Cancel</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<style>
.hiddenRow {
  display: none;
}
</style>

<style scoped>
.app-container {
  background: white;
}

.intervalCheckbox {
  line-height: 30px;
  height: 30px;
  margin-left: 0;
  display: block;
}
</style>

<script>
import Vue from "vue";
import { getToken } from "@/utils/auth";
import {
  baseUrl,
  getEvaluationsForActivity,
  saveEvaluation,
  getEvaluationById,
} from "@/api/evaluation";
import { getAll as ResponseTypesGetAll } from "@/api/responseTypes";
import waves from "@/directive/waves";
import { parseTime } from "@/utils";
import moment from "moment";
export default {
  name: "EditEvaluation",
  directives: { waves },
  data() {
    return {
      selectedEvaluationType: null,
      evaluations: [],
      baseUrl: baseUrl,
      loading: false,
      webSurveyId: null,
      intro: "",
      date: null,
      intervals: [],
      responseTypes: [],
      activity: {},
      questions: [],
      isAddingMode: false,
      dialogFormVisible: false,
      tempQuestion: {
        Include: false,
        Required: false,
        ResponseType: null,
        Interval: null,
        Question: "",
        PreferredAnswer: null,
        Answer1: null,
        Answer2: null,
        Answer3: null,
        Answer4: null,
        Answer5: null,
        Answer6: null,
        Answer7: null,
        Answer8: null,
        Answer9: null,
        Answer10: null,
        Answer11: null,
        Answer12: null,
        Answer13: null,
        Answer14: null,
        Answer15: null,
        Answer16: null,
        Answer17: null,
        Answer18: null,
        Answer19: null,
        Answer20: null,
        Answer21: null,
        Answer22: null,
        Answer23: null,
        Answer24: null,
        Answer25: null,
        Answer26: null,
        Answer27: null,
        Answer28: null,
        Answer29: null,
        Answer30: null,
      },
    };
  },
  created() {
    this.getAllResponseTypes().then((_) => this.getEvaluations());
  },
  methods: {
    tableRowClassName({ row, rowIndex }) {
      if (row.hide) {
        return "hiddenRow";
      }
      return "";
    },
    getLocation() {
      return this.baseUrl.replace("api", "");
    },
    getEvaluationName(intervalName) {
      if (intervalName.toLowerCase() == "pre") {
        return "Pre Test";
      } else if (intervalName.toLowerCase() == "post") {
        return "Post Test";
      } else if (intervalName.toLowerCase() == "evaluation") {
        return "Evaluation";
      }
    },
    getEvaluationById(evaluation) {
      var $this = this;
      return new Promise((resolve, reject) =>
        getEvaluationById({ evaluationId: evaluation.id }).then((response) => {
          var evaluationType = evaluation.evaluationType.name;
          var questions = JSON.parse(response.data.questions);
          questions.forEach((q) => {
            if (typeof q.Include === "undefined" && evaluation.id <= 0) {
              // && q.Interval == evaluationType) {
              q.Include = true;
            }
            if (!q.Include) q.Include = false;
            if (!q.Required) q.Required = false;

            q.hide = false;
          });

          for (var i = 0; i < questions.length; i++) {
            var question = questions[i];

            if (question.Interval == evaluationType) {
              for (var j = 0; j < questions.length; j++) {
                if (i == j) {
                  continue;
                }
                var nextQuestion = questions[j];
                if (question.Parent && question.Parent == nextQuestion.Key) {
                  nextQuestion.hide = true;
                }
              }
            }
          }

          while (evaluation.questions.length) {
            evaluation.questions.pop();
          }

          Vue.nextTick(() => {
            questions.forEach((q) => {
              evaluation.questions.push(q);
            });
            evaluation.loading = false;
          });

          resolve();
        })
      );
    },
    getEvaluations() {
      var $this = this;
      return new Promise((resolve, reject) =>
        getEvaluationsForActivity({
          activityId: $this.$route.query.activityId,
        }).then((response) => {
          var evaluations = [];
          for (var evaluationType in response.data) {
            var evaluation = response.data[evaluationType];
            var questions = JSON.parse(response.data[evaluationType].questions);
            questions.forEach((q) => {
              if (
                typeof q.Include === "undefined" &&
                evaluation.id <= 0 &&
                q.Interval == evaluationType
              ) {
                q.Include = true;
              }
              if (!q.Include) q.Include = false;
              if (!q.Required) q.Required = false;

              q.hide = false;
            });

            for (var i = 0; i < questions.length; i++) {
              var question = questions[i];

              if (question.Interval == evaluationType) {
                for (var j = 0; j < questions.length; j++) {
                  if (i == j) {
                    continue;
                  }
                  var nextQuestion = questions[j];
                  if (question.Parent && question.Parent == nextQuestion.Key) {
                    nextQuestion.hide = true;
                  }
                }
              }
            }

            // questions.sort(function(a, b) {
            //   return a.Include === b.Include ? 0 : a.Include ? -1 : 1;
            // });

            if (evaluation.date) {
              evaluation.date = moment
                .utc(evaluation.date)
                .format("MM/DD/YYYY");
            }
            evaluation.loading = false;
            response.data[evaluationType].questions = questions;
            evaluations.push(evaluation);
          }

          var pre = evaluations.find(
            (e) => e.evaluationType.name.toLowerCase() == "pre"
          );
          var ev = evaluations.find(
            (e) => e.evaluationType.name.toLowerCase() == "evaluation"
          );
          var post = evaluations.find(
            (e) => e.evaluationType.name.toLowerCase() == "post"
          );

          $this.evaluations.push(pre);
          $this.evaluations.push(ev);
          $this.evaluations.push(post);

          $this.selectedEvaluationType =
            $this.evaluations[0].evaluationType.name;
          resolve();
        })
      );
    },
    getAllResponseTypes() {
      var $this = this;
      return new Promise((resolve, reject) =>
        ResponseTypesGetAll().then((response) => {
          $this.responseTypes = response.data;
          resolve();
        })
      );
    },
    isNumeric: function (n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    handleIncludeQuestion: function (question, checked) {
      if (!checked) {
        question.Include = false;
      } else {
        question.Include = true;
      }
    },
    handleRequiredQuestion: function (question, checked) {
      if (!checked) {
        question.Required = false;
      } else {
        question.Required = true;
      }
    },
    getCurrentEvaluation() {
      var $this = this;
      var currentEvaluation = this.evaluations.find(
        (a) => a.evaluationType.name == $this.selectedEvaluationType
      );
      return currentEvaluation;
    },
    handleSaveQuestion() {
      var currentEvaluation = this.getCurrentEvaluation();
      this.tempQuestion.Key = "Q" + currentEvaluation.questions.length + 1;
      this.tempQuestion.Interval = currentEvaluation.evaluationType.name;
      currentEvaluation.questions.push(this.tempQuestion);
      this.resettempQuestion();
      this.dialogFormVisible = false;
    },
    handleCancel() {
      this.resettempQuestion();
      this.dialogFormVisible = false;
    },
    handleAddQuestion() {
      this.resettempQuestion();
      this.dialogFormVisible = true;
    },
    resettempQuestion() {
      this.tempQuestion = {
        ResponseType: null,
        Interval: null,
        Question: "",
        PreferredAnswer: null,
        Answer1: null,
        Answer2: null,
        Answer3: null,
        Answer4: null,
        Answer5: null,
        Answer6: null,
        Answer7: null,
        Answer8: null,
        Answer9: null,
        Answer10: null,
        Answer11: null,
        Answer12: null,
        Answer13: null,
        Answer14: null,
        Answer15: null,
        Answer16: null,
        Answer17: null,
        Answer18: null,
        Answer19: null,
        Answer20: null,
        Answer21: null,
        Answer22: null,
        Answer23: null,
        Answer24: null,
        Answer25: null,
        Answer26: null,
        Answer27: null,
        Answer28: null,
        Answer29: null,
        Answer30: null,
      };
    },
    handlePreview() {
      var currentEvaluation = this.getCurrentEvaluation();
      window.open(
        this.getLocation() +
          "#/evaluation/preview?evaluationId=" +
          currentEvaluation.id
      );
    },
    handleSave() {
      var currentEvaluation = this.getCurrentEvaluation();
      currentEvaluation.loading = true;
      var data = {
        id: currentEvaluation.id > 0 ? currentEvaluation.id : -1,
        activity: currentEvaluation.activity,
        date: currentEvaluation.date
          ? new Date(currentEvaluation.date).toLocaleDateString("en-US")
          : null,
        intro: currentEvaluation.intro,
        evaluationType: currentEvaluation.evaluationType,
        credits: currentEvaluation.credits,
        passingPercent: currentEvaluation.passingPercent,
        includePostTest: currentEvaluation.includePostTest,
        questions: JSON.stringify(currentEvaluation.questions),
      };

      saveEvaluation(data)
        .then((response) => {
          currentEvaluation.id = response.data;
        })
        .then((_) => this.getEvaluationById(currentEvaluation));
    },
  },
};
</script>
