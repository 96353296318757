<template>
  <div class="app-container">
    <p class="aligncenter">
      <img style="height: 100px" :src="'data:image/jpg;base64,' + logo" />
    </p>
    <div>
      <p style="text-align: center; padding: 30px">Certificates</p>
    </div>
    <template v-if="!certificateExists">
      <p>
        I would like to claim
        <el-input-number
          v-model="credits"
          :precision="2"
          :step="0.25"
          :min="0.25"
          :max="maxCredits"
        ></el-input-number>
        of credit (0.25 - {{ maxCredits }})
      </p>
    </template>
    <div style="margin-top: 30px">
      <el-button type="primary" @click="handleDownload()"
        >Download certificate</el-button
      >
    </div>
    <div style="margin-top: 30px">
      <el-button type="primary" @click="handleEmail()"
        >Email certificate</el-button
      >
    </div>
  </div>
</template>

<style scoped>
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 3em 0;
  padding: 0;
}

.aligncenter {
  text-align: center;
}
.app-container {
  background: white;
}

.intervalCheckbox {
  line-height: 30px;
  height: 30px;
  margin-left: 0;
  display: block;
}

.el-checkbox {
  margin-left: 0px !important;
  margin-right: 30px !important;
}

.el-checkbox + .el-checkbox {
  margin-left: 0px !important;
  margin-right: 30px !important;
}
</style>

<script>
import FileSaver from "file-saver";
import Downloader from "downloadjs";
import { getToken } from "@/utils/auth";
import {
  baseUrl,
  getEvaluationByUid,
  submitPreEvaluationAnswers,
  emailCertificate,
  getCertificateInfo,
  downloadCertificate,
  getLogo,
} from "@/api/evaluation";
import { getAll as ResponseTypesGetAll } from "@/api/responseTypes";
import waves from "@/directive/waves";
import { parseTime } from "@/utils";
import moment from "moment";
export default {
  name: "PreEvaluation",
  directives: { waves },
  data() {
    return {
      logo: "",
      baseUrl: baseUrl,
      loading: false,
      certificateExists: true,
      credits: 0.25,
      maxCredits: 0,
    };
  },
  created() {
    this.getLogo();
    this.getInfo();
  },
  methods: {
    getLocation() {
      return this.baseUrl.replace("api", "");
    },
    getLogo() {
      getLogo({
        uid: this.$route.query.uid,
      }).then((response) => {
        this.logo = response.data;
      });
    },
    getInfo() {
      getCertificateInfo({
        uid: this.$route.query.uid,
      }).then((response) => {
        if (!response.data) {
          this.$router.push({
            path: "/evaluation/thankYou?uid=" + this.$route.query.uid,
          });
          return;
        }
        this.maxCredits = response.data.maxCredits;
        this.certificateExists = response.data.certificateExists;
      });
    },
    isNumeric: function (n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    handleDownload: function () {
      downloadCertificate({
        uid: this.$route.query.uid,
        credits: this.credits,
      }).then((response) => {
        var blob = this.b64toBlob(response.data.body);
        FileSaver.saveAs(blob, "certificate.pdf");
      });
    },
    handleEmail: function () {
      emailCertificate({
        uid: this.$route.query.uid,
        credits: this.credits,
      }).then((response) => {
        this.$notify({
          title: "Done",
          message: "",
          type: "success",
          duration: 1000,
        });
      });
    },
    b64toBlob: function (b64Data, contentType, sliceSize) {
      contentType = contentType || "";
      sliceSize = sliceSize || 512;

      var byteCharacters = atob(b64Data);
      var byteArrays = [];

      for (
        var offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      var blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
  },
};
</script>
