<template>
  <el-scrollbar wrap-class="scrollbar-wrapper">
    <img :src="'data:image/jpg;base64,'+logo" style="width: 100%">
    <el-menu
      :show-timeout="200" 
      :default-active="$route.path"
      :collapse="isCollapse"
      mode="vertical"
      background-color="#304156"
      text-color="#bfcbd9"
      active-text-color="#409EFF"
    >
      <sidebar-item v-for="route in permission_routers" :key="route.path" :item="route" :base-path="route.path"/>
    </el-menu>
  </el-scrollbar>
</template>

<script>
import { baseUrl } from "@/api/survey";
import { getLogo } from "@/api/organization";
import { mapGetters } from 'vuex'
import SidebarItem from './SidebarItem'

export default {
  components: { SidebarItem },
  computed: {
    ...mapGetters([
      'permission_routers',
      'sidebar'
    ]),
    isCollapse() {
      return !this.sidebar.opened
    } 
  },
  created() {
    this.getLogo();
  },
  methods: {
    getLogo() {
      getLogo({        
      }).then(response => {
        this.logo = response.data;
      });
    },
    getLocation() {
      return this.baseUrl.replace('api','');
    }
  },
  data() {
    return {
      logo: '',
      baseUrl: baseUrl
    }
  }
}
</script>
