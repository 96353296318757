var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.survey && _vm.survey.activity
    ? _c(
        "div",
        { staticClass: "app-container" },
        [
          _c("div", { staticClass: "header-right" }, [
            _c("img", {
              staticStyle: {
                left: "0px",
                "max-width": "300px",
                "max-height": "110px"
              },
              attrs: { src: "data:image/jpg;base64," + _vm.logo }
            })
          ]),
          _vm._v(" "),
          _c("div", { staticStyle: { clear: "both" } }, [
            _c(
              "div",
              {
                staticStyle: {
                  "text-align": "center",
                  "font-weight": "bold",
                  "font-size": "24px",
                  "margin-top": "100mm"
                }
              },
              [_c("span", [_vm._v(_vm._s(_vm.survey.activity.name))])]
            ),
            _vm._v(" "),
            _vm.survey.activity.educationalPartner
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      "margin-top": "15px",
                      "font-size": "18px",
                      "text-align": "center"
                    }
                  },
                  [
                    _c("span", [
                      _vm._v(
                        "Educational Partner: " +
                          _vm._s(_vm.survey.activity.educationalPartner)
                      )
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.survey.activity.commercialPartner
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      "margin-top": "15px",
                      "font-size": "18px",
                      "text-align": "center"
                    }
                  },
                  [
                    _c("span", [
                      _vm._v(
                        "Commercial Supporter: " +
                          _vm._s(_vm.survey.activity.commercialPartner)
                      )
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.survey.date
              ? _c(
                  "div",
                  {
                    staticStyle: {
                      "text-align": "center",
                      "margin-top": "15px",
                      "font-size": "22px"
                    }
                  },
                  [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          _vm.survey.date
                            ? new Date(_vm.survey.date).toLocaleDateString(
                                "en-US"
                              )
                            : ""
                        )
                      )
                    ])
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  "text-align": "center",
                  "margin-top": "15px",
                  "font-size": "22px"
                }
              },
              [_c("p", [_vm._v(_vm._s(_vm.survey.notes))])]
            )
          ]),
          _vm._v(" "),
          _vm._l(_vm.survey.report, function(report) {
            return _c(
              "div",
              { key: report.Key, staticStyle: { "margin-top": "30px" } },
              [
                report.Question && report.visible
                  ? _c(
                      "div",
                      { staticStyle: { "page-break-before": "always" } },
                      [
                        _c(
                          "el-row",
                          [
                            _c("el-col", { attrs: { span: 24 } }, [
                              _c(
                                "p",
                                {
                                  staticStyle: {
                                    "font-size": "22px",
                                    "text-align": "left",
                                    margin: "10px"
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(report.Question) +
                                      "\n          "
                                  )
                                ]
                              )
                            ])
                          ],
                          1
                        ),
                        _vm._v(" "),
                        report.chartData.data && report.chartData.data.length
                          ? _c(
                              "el-row",
                              [
                                report.ResponseType != "Textarea"
                                  ? [
                                      _c(
                                        "el-col",
                                        {
                                          staticStyle: { width: "500px" },
                                          attrs: { span: 12 }
                                        },
                                        [
                                          report.chartType === "Bar"
                                            ? _c("d3-vertical-bar", {
                                                attrs: {
                                                  options: _vm.vBarChartOptions,
                                                  data: report.chartData,
                                                  width: "500px",
                                                  height: "400px"
                                                }
                                              })
                                            : _c("d3-pie", {
                                                attrs: {
                                                  options: _vm.pieChartOptions,
                                                  data: report.chartData.data,
                                                  width: "500px",
                                                  height: "400px"
                                                }
                                              })
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "el-col",
                                        {
                                          staticStyle: {
                                            width: "150px",
                                            position: "relative",
                                            height: "370px"
                                          },
                                          attrs: { span: 12 }
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                position: "absolute",
                                                bottom: "0",
                                                "font-weight": "bold"
                                              }
                                            },
                                            _vm._l(
                                              report.chartData.rightLegend,
                                              function(legend) {
                                                return _c(
                                                  "div",
                                                  { key: legend },
                                                  [
                                                    _c(
                                                      "div",
                                                      {
                                                        style: {
                                                          color: legend.color
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                  " +
                                                            _vm._s(
                                                              legend.text
                                                            ) +
                                                            "\n                "
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                )
                                              }
                                            )
                                          )
                                        ]
                                      )
                                    ]
                                  : _vm._e()
                              ],
                              2
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        report.chartData.data && report.chartData.data.length
                          ? _c(
                              "el-row",
                              [
                                report.ResponseType == "Textarea"
                                  ? [
                                      _c(
                                        "el-table",
                                        {
                                          key: report.Key,
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            data: report.chartData.legendData,
                                            fit: "",
                                            "highlight-current-row": ""
                                          }
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "Value",
                                              align: "left"
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function(scope) {
                                                  return [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "white-space":
                                                            "pre-wrap",
                                                          "word-break":
                                                            "normal",
                                                          "line-height":
                                                            "normal"
                                                        },
                                                        style: {
                                                          "font-weight":
                                                            scope.row
                                                              .question &&
                                                            scope.row.question.endsWith(
                                                              "(Correct Answer)"
                                                            )
                                                              ? "bold"
                                                              : "normal"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            scope.row.question
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ])
                                          })
                                        ],
                                        1
                                      )
                                    ]
                                  : [
                                      _c(
                                        "el-table",
                                        {
                                          key: report.Key,
                                          staticStyle: { width: "100%" },
                                          attrs: {
                                            data: report.chartData.legendData,
                                            fit: "",
                                            "highlight-current-row": ""
                                          }
                                        },
                                        [
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "Value",
                                              align: "left",
                                              width: "250px"
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function(scope) {
                                                  return [
                                                    _c(
                                                      "span",
                                                      {
                                                        staticStyle: {
                                                          "white-space":
                                                            "pre-wrap",
                                                          "word-break":
                                                            "normal",
                                                          "line-height":
                                                            "normal"
                                                        }
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            scope.row.question
                                                          )
                                                        )
                                                      ]
                                                    )
                                                  ]
                                                }
                                              }
                                            ])
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "",
                                              width: "150px",
                                              align: "center"
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function(scope) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticStyle: {
                                                          "background-color":
                                                            "#f1f1f1",
                                                          position: "relative",
                                                          height: "25px"
                                                        }
                                                      },
                                                      [
                                                        _vm._l(
                                                          scope.row.values,
                                                          function(value) {
                                                            return [
                                                              _c("div", {
                                                                key:
                                                                  value.color,
                                                                staticStyle: {
                                                                  height:
                                                                    "25px",
                                                                  position:
                                                                    "absolute"
                                                                },
                                                                style: {
                                                                  "background-color": report
                                                                    .chartData
                                                                    .multiple
                                                                    ? value.groupColor
                                                                    : value.color,
                                                                  width:
                                                                    value.percent +
                                                                    "%"
                                                                }
                                                              })
                                                            ]
                                                          }
                                                        )
                                                      ],
                                                      2
                                                    )
                                                  ]
                                                }
                                              }
                                            ])
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "Percent",
                                              width: "170px",
                                              align: "right"
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function(scope) {
                                                  return [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          scope.row.percentsText
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                }
                                              }
                                            ])
                                          }),
                                          _vm._v(" "),
                                          _c("el-table-column", {
                                            attrs: {
                                              label: "Responses",
                                              width: "100px",
                                              align: "right"
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "default",
                                                fn: function(scope) {
                                                  return [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          scope.row.valuesText
                                                        )
                                                      )
                                                    ])
                                                  ]
                                                }
                                              }
                                            ])
                                          })
                                        ],
                                        1
                                      )
                                    ]
                              ],
                              2
                            )
                          : _vm._e()
                      ],
                      1
                    )
                  : _vm._e()
              ]
            )
          }),
          _vm._v(" "),
          _c("footer")
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }