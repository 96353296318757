<template>
  <div class="app-container">
    <el-table
      :key="tableKey"
      :data="list"
      border
      fit
      highlight-current-row
      style="width: 100%;">
      <el-table-column label="Template Name" align="center" width="300px">
        <template slot-scope="scope">
          <span>{{ scope.row.Name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="" min-width="150px" align="center">
        <template slot-scope="scope">
          <el-button type="primary" @click="downloadTemplate(scope.row)" :loading="scope.row.Downloading" >{{ scope.row.Text }}</el-button>
        </template>
      </el-table-column>      
    </el-table>

    
  </div>
</template>
<script>
import { getToken } from "@/utils/auth";
import FileSaver from "file-saver";
import Downloader from "downloadjs";
import {
  baseUrl,
  questions as DownloadQuestions,
  responses as DownloadResponses,
  attendees as DownloadAttendees,
  participants as DownloadParticipants
} from "@/api/templates";
import waves from "@/directive/waves";

export default {
  name: "Templates",
  directives: { waves },
  data() {
    return {
      tableKey: 0,
      list: null,
      total: 0,
      downloadLoading: false
    };
  },
  created() {
    this.list = [
      { Name: "Questions", Text: "Download", Downloading: false },
      { Name: "Responses", Text: "Download", Downloading: false },
      { Name: "Attendees", Text: "Download", Downloading: false },
      { Name: "Participant List", Text: "Download", Downloading: false }
    ];
  },
  methods: {
    b64toBlob: function(b64Data, contentType, sliceSize) {
      contentType = contentType || "";
      sliceSize = sliceSize || 512;

      var byteCharacters = atob(b64Data);
      var byteArrays = [];

      for (
        var offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      var blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
    downloadTemplate(row) {
       row.Downloading = true;
      if (row.Name == "Questions") {
        DownloadQuestions().then(response => {
          var blob = this.b64toBlob(
            response.data.body,
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          );
          FileSaver.saveAs(blob, row.Name.toLowerCase() + "_template.xlsx");
          row.Downloading = false;
        });
      } else if (row.Name == "Responses") {
        DownloadResponses().then(response => {
          var blob = this.b64toBlob(
            response.data.body,
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          );
          FileSaver.saveAs(blob, row.Name.toLowerCase() + "_template.xlsx");
          row.Downloading = false;          
        });
      } else if (row.Name == "Attendees") {
        DownloadAttendees().then(response => {
          var blob = this.b64toBlob(
            response.data.body,
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          );
          FileSaver.saveAs(blob, row.Name.toLowerCase() + "_template.csv");
          row.Downloading = false;          
        });
      }else if (row.Name == "Participant List") {
        DownloadParticipants().then(response => {
          var blob = this.b64toBlob(
            response.data.body,
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          );
          FileSaver.saveAs(blob, row.Name.toLowerCase() + " template.xlsx");
          row.Downloading = false;          
        });
      }
    }
  }
};
</script>
