import request from '@/utils/request'

const baseUrl = request.baseUrl

export { baseUrl }

export function getEvaluationsForActivity(query) {
  return request({
    url: '/evaluation/GetEvaluationsForActivity',
    method: 'Get',
    params: query
  })
}

export function getPreEvaluationByUid(query) {
  return request({
    url: '/evaluation/getPreEvaluationByUid',
    method: 'Get',
    params: query
  })
}

export function getPostEvaluationByUid(query) {
  return request({
    url: '/evaluation/getPostEvaluationByUid',
    method: 'Get',
    params: query
  })
}

export function getEvaluationById(query) {
  return request({
    url: '/evaluation/getEvaluationById',
    method: 'Get',
    params: query
  })
}

export function getEvaluationByUid(query) {
  return request({
    url: '/evaluation/getEvaluationByUid',
    method: 'Get',
    params: query
  })
}

export function saveEvaluation(data){
  return request({
    url: '/evaluation/saveEvaluation',
    method: 'Post',
    data
  });
}

export function submitPreEvaluationAnswers(data){
  return request({
    url: '/evaluation/SubmitPreEvaluationAnswers',
    method: 'Post',
    data
  })
}

export function submitPostEvaluationAnswers(data){
  return request({
    url: '/evaluation/SubmitPostEvaluationAnswers',
    method: 'Post',
    data
  })
}

export function submitEvaluationAnswers(data){
  return request({
    url: '/evaluation/SubmitEvaluationAnswers',
    method: 'Post',
    data
  })
}

export function getCertificateInfo(query) {
  return request({
    url: '/evaluation/getCertificateInfo',
    method: 'Get',
    params: query
  })
}

export function downloadCertificate(query) {
  return request({
    url: '/evaluation/downloadCertificate',
    method: 'Get',
    params: query
  })
}

export function emailCertificate(query) {
  return request({
    url: '/evaluation/emailCertificate',
    method: 'Get',
    params: query
  })
}

export function getLogo(query) {
  return request({
    url: '/evaluation/getLogo',
    method: 'Get',
    params: query
  })
}

export function getLogoByOrganizationId(query) {
  return request({
    url: '/evaluation/getLogoByOrganizationId',
    method: 'Get',
    params: query
  })
}
