import request from '@/utils/request'

export function getAllSeriesActivityNames(query) {
  return request({
    url: '/participant/getAllSeriesActivityNames',
    method: 'Get',
    params: query
  })
}

export function createSendJobs(data) {
  return request({
    url: '/participant/createSendJobs',
    method: 'Post',
    data
  })
}

export function getList(query) {
  return request({
    url: '/participant/getList',
    method: 'Get',
    params: query
  })
}

export function downloadCertificate(query) {
  return request({
    url: '/participant/downloadCertificate',
    method: 'Get',
    params: query
  })
}

export function sendCertificateForParticipant(query) {
  return request({
    url: '/participant/sendCertificateForParticipant',
    method: 'Get',
    params: query
  })
}
