import request from '@/utils/request'

export function search(query) {
  return request({
    url: '/series/search',
    method: 'Get',
    params: query
  })
}

export function create(data) {
  return request({
    url: '/series/create',
    method: 'Post',
    data
  })
}

export function update(data) {
  return request({
    url: '/series/update',
    method: 'Post',
    data
  })
}

export function remove(id) {
  return request({
    url: '/series/delete',
    method: 'Delete',
    params: id
  })
}

export function checkActivityIdUnique(activityId) {
  return request({
    url: '/series/CheckActivityIdUnique',
    method: 'Get',
    params: activityId
  })
}
