import request from '@/utils/loginrequest'

export function loginByUsername(username, password, rememberMe) {
  const data = {
    username,
    password,
    rememberMe
  }
  return request({
    url: '/users/Authenticate',
    method: 'post',
    data
  })
}

export function logout() {
  return request({
    url: '/login/logout',
    method: 'post'
  })
}

export function getUserInfo() {
  return request({
    url: '/users/getinfo',
    method: 'get',
    params: { }
  })
}

