<template>
  <div class="app-container">
    <div class="filter-container">
      <el-button
        class="filter-item"
        style="margin-left: 10px"
        type="primary"
        icon="el-icon-edit"
        @click="handleCreate"
        >{{ $t("table.add") }}</el-button
      >
    </div>

    <el-table
      v-loading="listLoading"
      :key="tableKey"
      :data="list"
      border
      fit
      highlight-current-row
      style="width: 100%"
    >
      <el-table-column label="User Name" align="center" width="150px">
        <template slot-scope="scope">
          <span>{{ scope.row.username }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Organization" width="130px" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.organization.name }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Email" min-width="200px" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.email }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Active" width="100px">
        <template slot-scope="scope">
          <span v-if="scope.row.isActive" style="color: green">active</span>
          <span v-else style="color: gray">inactive</span>
        </template>
      </el-table-column>
      <el-table-column
        :label="$t('table.actions')"
        align="center"
        width="230"
        class-name="small-padding fixed-width"
      >
        <template slot-scope="scope">
          <el-button
            type="primary"
            size="mini"
            @click="handleUpdate(scope.row)"
            >{{ $t("table.edit") }}</el-button
          >
        </template>
      </el-table-column>
    </el-table>

    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="listQuery.page"
      :limit.sync="listQuery.limit"
      @pagination="getList"
    />

    <el-dialog :title="textMap[dialogStatus]" :visible.sync="dialogFormVisible">
      <el-form
        ref="dataForm"
        :rules="rules"
        :model="temp"
        label-position="left"
        label-width="170px"
        style="width: 800px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="Name" prop="username">
              <el-input v-model="temp.username" />
            </el-form-item>
            <el-form-item label="Email" prop="email">
              <el-input v-model="temp.email" />
            </el-form-item>
            <el-form-item
              v-if="currentUser.isSuperUser"
              label="Organization"
              prop="organization"
            >
              <el-select
                v-model="temp.organization"
                class="filter-item"
                value-key="id"
                placeholder="Please select organization"
                style="width: 100%"
              >
                <el-option
                  v-for="item in orgs"
                  :key="item.id"
                  :label="item.name"
                  :value="item"
                />
              </el-select>
            </el-form-item>

            <el-form-item label="Active" prop="isActive">
              <el-checkbox v-model="temp.isActive"></el-checkbox>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">{{
          $t("table.cancel")
        }}</el-button>
        <el-button
          type="primary"
          @click="dialogStatus === 'create' ? createData() : updateData()"
          >{{ $t("table.confirm") }}</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<style scoped>
.grid-content {
  height: 36px;
  line-height: 36px;
}

.intervalCheckbox-even {
  background: #ebeef5;
}

.light-label {
  font-weight: 100 !important;
}
</style>

<script>
import { getToken } from "@/utils/auth";
import {
  baseUrl,
  getUsers,
  getOrganizations,
  update as UpdateUser,
  create as CreateUser,
  getCurrentUser,
} from "@/api/users";
import waves from "@/directive/waves"; // Waves directive
import { parseTime } from "@/utils";
import Pagination from "@/components/Pagination"; // Secondary package based on el-pagination

export default {
  name: "UsersGrid",
  components: { Pagination },
  directives: { waves },
  data() {
    return {
      uploadBaseUrl: baseUrl,
      tableKey: 0,
      list: null,
      orgs: [],
      dialogStatus: "",
      total: 0,
      currentUser: {},
      textMap: {
        update: "Edit User",
        create: "Create User",
      },
      listQuery: {
        page: 1,
        limit: 20,
      },
      listLoading: true,
      temp: {
        id: undefined,
        username: undefined,
        organization: {},
        email: "",
        isActive: false,
      },
      dialogFormVisible: false,
      headerInfo: {
        Authorization: "Bearer " + getToken(),
      },
      rules: {
        username: [
          {
            required: true,
            message: "User Name is required",
            trigger: "change",
          },
        ],
        organization: [
          {
            required: true,
            message: "Organization is required",
            trigger: "change",
          },
        ],
        email: [
          { required: true, message: "Name is required", trigger: "change" },
          {
            type: "email",
            message: "Incorrect email address",
            trigger: ["blur", "change"],
          },
        ],
      },
      downloadLoading: false,
    };
  },
  created() {
    this.getUser();
    this.getOrgs();
    this.getList();
  },
  methods: {
    getOrgs() {
      getOrganizations().then((response) => {
        this.orgs = response.data;
      });
    },
    getUser() {
      getCurrentUser().then((response) => {
        this.currentUser = response.data;
      });
    },
    getList() {
      this.listLoading = true;
      getUsers(this.listQuery).then((response) => {
        this.list = response.data.items;
        this.total = response.data.total;
        this.listLoading = false;
      });
    },
    resetTemp() {
      this.temp = {
        id: undefined,
        username: undefined,
        organization: {},
        email: "",
        isActive: false,
      };
    },
    handleCreate() {
      this.resetTemp();
      this.dialogFormVisible = true;
      this.dialogStatus = "create";
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },
    handleUpdate(row) {
      this.temp = Object.assign({}, row);
      this.dialogStatus = "update";
      this.dialogFormVisible = true;
      this.$nextTick(() => {
        this.$refs["dataForm"].clearValidate();
      });
    },
    createData() {
      if (!this.currentUser.isSuperUser) {
        this.temp.organization = {};
        this.temp.organization.id = this.currentUser.organization.id;
        this.temp.organization.name = this.currentUser.organization.name;
      }
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          CreateUser(this.temp).then(() => {
            this.dialogFormVisible = false;
            this.$notify({
              title: "User created",
              message: "",
              type: "success",
              duration: 1000,
            });
            this.getList();
          });
        }
      });
    },
    updateData() {
      this.$refs["dataForm"].validate((valid) => {
        if (valid) {
          UpdateUser(this.temp).then(() => {
            this.dialogFormVisible = false;
            this.$notify({
              title: "User updated",
              message: "",
              type: "success",
              duration: 1000,
            });
            this.getList();
          });
        }
      });
    },
  },
};
</script>
