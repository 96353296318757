<template>
  <div class="tab-container">
    <el-tabs v-model="activeName" style="margin-top:15px;" type="border-card">
      <el-tab-pane label="Activity" name="Activity">
        <keep-alive>
          <activityTab v-if="activeName=='Activity'" @activityLoaded="onActivityLoaded"/>
        </keep-alive>
      </el-tab-pane>
      <el-tab-pane
        label="Report"
        name="Report"
        v-if="activity != null && activity.questionsFile!=null"
      >
        <keep-alive>
          <reportTab v-if="activeName=='Report'"/>
        </keep-alive>
      </el-tab-pane>
      <el-tab-pane
        label="Survey"
        name="Survey"
        v-if="activity != null && activity.questionsFile!=null && surveyTabVisisble"
      >
        <keep-alive>
          <surveyTab v-if="activeName=='Survey'"/>
        </keep-alive>
      </el-tab-pane>
      <el-tab-pane
        label="Attendees"
        name="Attendees"
        v-if="activity != null && activity.attendeesFile!=null"
      >
        <keep-alive>
          <attendeesTab v-if="activeName=='Attendees'"/>
        </keep-alive>
      </el-tab-pane>
      <el-tab-pane
        label="Evaluation"
        name="Evaluation"
        v-if="activity != null && activity.questionsFile!=null"
      >
        <keep-alive>
          <evaluationTab v-if="activeName=='Evaluation'"/>
        </keep-alive>
      </el-tab-pane>
      <el-tab-pane label="Certificates" name="Certificates">
        <keep-alive>
          <certificatesTab v-if="activeName=='Certificates'"/>
        </keep-alive>
      </el-tab-pane>
      <el-tab-pane label="Email Templates" name="EmailTemplates">
        <keep-alive>
          <emailTemplateTab v-if="activeName=='EmailTemplates'"/>
        </keep-alive>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>
import activityTab from "../activities/editActivity";
import reportTab from "../reports/edit";
import surveyTab from "../reports/webedit";
import attendeesTab from "../attendees/attendeesGrid";
import evaluationTab from "../evaluation/edit";
import certificatesTab from "./certificates";
import emailTemplateTab from "../activities/emailTemplates";

export default {
  name: "Tab",
  components: { activityTab, reportTab, surveyTab, attendeesTab, evaluationTab, certificatesTab, emailTemplateTab },
  data() {
    return {
      activity: null,
      surveyTabVisisble: false,
      intervals: [],
      activeName: "Activity"
    };
  },
  methods: {
    onActivityLoaded(activityData) {
      var $this = this;
      $this.activity = activityData.activity;
      $this.intervals = activityData.intervals;

      for(var i=0; i<$this.activity.intervals.length; i++){
        var activityInterval = $this.activity.intervals[i];
        for(var j=0; j<$this.intervals.length; j++){
          var interval = $this.intervals[j];
          if(interval.id == activityInterval){
            if(interval.name.indexOf("45") > -1 || interval.name.indexOf("90") > -1 || interval.name.indexOf("180") > -1)
            {
              $this.surveyTabVisisble = true;
            }
          }
        }
      }
    }
  }
};
</script>

<style scoped>
.tab-container {
  margin: 30px;
}
</style>
