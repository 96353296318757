<template>
  <div class="app-container" v-if="survey && survey.activity">
    <div
      style="
        display: block;
        width: 300px;
        position: absolute;
        top: 10px;
        right: 200px;
      "
    >
      <img
        :src="'data:image/jpg;base64,' + logo"
        style="left: 0px; max-width: 300px; height: 110px"
      />
    </div>
    <div
      style="
        width: 700px;
        min-height: 130px;
        margin-left: 100px;
        margin-right: 100px;
        display: inline-block;
      "
    >
      <div style="text-align: center; font-weight: bold; font-size: 20px">
        <span>{{ survey.activity.name }}</span>
      </div>
      <div
        v-if="survey.activity.educationalPartner"
        style="margin-top: 15px; font-size: 18px; text-align: center"
      >
        <span
          >Educational Partner: {{ survey.activity.educationalPartner }}</span
        >
      </div>
      <div
        v-if="survey.activity.commercialPartner"
        style="margin-top: 15px; font-size: 18px; text-align: center"
      >
        <span
          >Commercial Supporter: {{ survey.activity.commercialPartner }}</span
        >
      </div>
      <div
        v-if="survey.date"
        style="margin-top: 15px; font-size: 18px; text-align: center"
      >
        <span>{{
          survey.date ? new Date(survey.date).toLocaleDateString("en-US") : ""
        }}</span>
      </div>
      <div style="margin-top: 15px; font-size: 18px; text-align: left">
        <p>{{ survey.notes }}</p>
      </div>
    </div>
    <hr />
    <div
      v-for="report in survey.report"
      :key="report.Key"
      style="margin-top: 30px"
    >
      <template v-if="report.Question && report.visible">
        <el-row>
          <el-col :span="12">
            <p style="margin: 10px">{{ report.Question }}</p>
          </el-col>
        </el-row>
        <el-row>
          <template
            v-if="report.chartData.data && report.chartData.data.length"
          >
            <template v-if="report.ResponseType != 'Textarea'">
              <el-col :span="12" style="width: 500px; !important;">
                <d3-vertical-bar
                  v-if="report.chartType === 'Bar'"
                  :options="vBarChartOptions"
                  :data="report.chartData"
                  width="500px"
                  height="400px"
                ></d3-vertical-bar>
                <d3-pie
                  v-else
                  :options="pieChartOptions"
                  :data="report.chartData.data"
                  width="500px"
                  height="400px"
                ></d3-pie>
              </el-col>
              <el-col
                :span="12"
                style="width: 150px; position: relative; height: 370px"
              >
                <div style="position: absolute; bottom: 0; font-weight: bold">
                  <div
                    v-for="legend in report.chartData.rightLegend"
                    :key="legend"
                  >
                    <div v-bind:style="{ color: legend.color }">
                      {{ legend.text }}
                    </div>
                  </div>
                </div>
              </el-col>
            </template>
          </template>
          <template v-else>
            <el-col :span="12">
              <p style="margin: 10px">No data</p>
            </el-col>
          </template>
        </el-row>
        <el-row>
          <el-table
            :key="report.Key"
            :data="report.chartData.legendData"
            fit
            highlight-current-row
            style="width: 100%"
          >
            <el-table-column label="Value" align="left" width="250px">
              <template slot-scope="scope">
                <span
                  style="
                    white-space: pre-wrap;
                    word-break: normal;
                    line-height: normal;
                  "
                  >{{ scope.row.question }}</span
                >
              </template>
            </el-table-column>
            <el-table-column label width="150px" align="center">
              <template slot-scope="scope">
                <div
                  style="
                    background-color: #f1f1f1;
                    position: relative;
                    height: 25px;
                  "
                >
                  <template v-for="value in scope.row.values">
                    <div
                      :key="value.color"
                      v-bind:style="{
                        'background-color': report.chartData.multiple
                          ? value.groupColor
                          : value.color,
                        width: value.percent + '%',
                      }"
                      style="height: 25px; position: absolute"
                    ></div>
                  </template>
                </div>
              </template>
            </el-table-column>
            <el-table-column label="Percent" width="170px" align="right">
              <template slot-scope="scope">
                <span>{{ scope.row.percentsText }}</span>
              </template>
            </el-table-column>
            <el-table-column label="Responses" width="100px" align="right">
              <template slot-scope="scope">
                <span>{{ scope.row.valuesText }}</span>
              </template>
            </el-table-column>
          </el-table>
        </el-row>
        <hr />
      </template>
    </div>
  </div>
</template>

<style>
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 3em 0;
  padding: 0;
}
#app-root {
  display: none;
}
.app-container {
  background: white;
}
</style>

<script>
import { getToken } from "@/utils/auth";
import { baseUrl, getSurvey, getLogoByOrganizationId } from "@/api/survey";
import waves from "@/directive/waves"; // Waves directive
import { parseTime } from "@/utils";
import moment from "moment";

export default {
  name: "ViewReport",
  directives: { waves },
  data() {
    return {
      logo: "",
      colors: [
        "#f37b79",
        "#799bf3",
        "#bef379",
        "#f379e2",
        "#79f3e0",
        "#f3bd79",
        "#9979f3",
        "#7cf379",
        "#f379a0",
        "#79c3f3",
        "#e7f379",
        "#db79f3",
        "#79f3b8",
        "#f39479",
        "#7981f3",
        "#a5f379",
        "#f379c8",
        "#79ecf3",
        "#f3d679",
        "#b379f3",
        "#79f38f",
        "#f37987",
        "#79aaf3",
        "#cef379",
        "#f379f1",
        "#79f3d1",
      ],
      pieChartOptions: {
        innerRadius: 40,
        cornerRadius: 0,
        padAngle: 0.01,
        axisXLabelHeight: 30,
        axisXLabel: "",
        arcLabel: "",
        axisXLabelFontSize: 12,
        axisXLabelFontWeight: 400,
        axisXLabelFontOpacity: 1,
        arcLabelFontSize: 14,
        arcLabelFontOpacity: 1,
        animationDuration: 1000,
      },
      vBarChartOptions: {
        axisXLaneHeight: 10,
        fill: "#6eadc1",
        stroke: "#6eadc1",
        fillOpacity: 1,
        strokeOpacity: 1,
        axisFontSize: 12,
        axisFontWeight: 400,
        axisYLabel: "",
        axisXLabel: "",
        axisYTickFormat: "d",
        axisXLabelHeight: 12,
        axisYLabelWidth: 12,
        axisLabelFontSize: 12,
        axisLabelFontWeight: 400,
        axisLabelFontOpacity: 0.5,
        axisXHeight: 12,
        axisYWidth: 12,
        isVertical: false,
      },
      baseUrl: baseUrl,
      loading: false,
      chartTypes: [
        { label: "Bar", key: "Bar" },
        { label: "Pie", key: "Pie" },
      ],
      survey: {},
    };
  },
  created() {
    this.getSurvey();
  },
  methods: {
    getLocation() {
      return this.baseUrl.replace("api", "");
    },
    getSurvey() {
      getSurvey({ surveyId: this.$route.query.surveyId }).then((response) => {
        response.data.report = JSON.parse(response.data.report);
        this.survey = response.data;
        getLogoByOrganizationId({
          organizationId: this.survey.activity.organizationId,
        }).then((response) => {
          this.logo = response.data;
        });
      });
    },
  },
};
</script>
