import request from '@/utils/request'

const baseUrl = request.baseUrl

export { baseUrl }

export function search(query) {
  return request({
    url: '/attendees/search',
    method: 'Get',
    params: query
  })
}

export function create(data) {
  return request({
    url: '/attendees/create',
    method: 'Post',
    data
  })
}

export function update(data) {
  return request({
    url: '/attendees/update',
    method: 'Post',
    data
  })
}

export function manualSend(query) {
  return request({
    url: '/attendees/manualSend',
    method: 'Get',
    params: query
  })
}