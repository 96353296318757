<template>
  <div v-if="survey && survey.activity" class="app-container">
    <div>
      <el-input v-model="survey.activity.name" style="width: 400px" />
      <el-date-picker
        v-model="survey.date"
        format="MM/dd/yyyy"
        type="date"
        style="width: 200px"
      />
      <el-button type="primary" size="mini" @click="handlePublish()"
        >Publish</el-button
      >
      <el-button
        :loading="pdfInProgress"
        type="primary"
        size="mini"
        @click="handlePDF()"
        >{{ pdfButtonName }}</el-button
      >
      <span v-if="pdfInProgress">{{ pdfProgress }}</span>
      <a
        v-if="survey.reportId > 0"
        :href="getLocation() + '#/reports/view?surveyId=' + survey.reportId"
        class="link-type"
        target="_blank"
        >View Report</a
      >
    </div>
    <div style="margin-top: 5px">
      <el-input
        :rows="2"
        v-model="survey.notes"
        type="textarea"
        placeholder="Notes"
        style="width: 605px"
      />
    </div>
    <div
      v-for="question in survey.questions"
      :key="question.Key"
      :id="question.Key + '_content'"
      style="margin-top: 30px"
    >
      <template v-if="question.Question && question.visible">
        <el-row>
          <el-col :span="12">
            <p style="margin: 10px">{{ question.Question }}</p>
          </el-col>
          <el-col :span="12">
            <el-button
              style="margin-top: 10px; margin-left: 8px"
              size="mini"
              type="danger"
              @click="handleDelete(question.Key)"
              >{{ $t("table.delete") }}</el-button
            >
          </el-col>
        </el-row>
        <el-row>
          <template v-if="question.ResponseType != 'Textarea'">
            <template
              v-if="question.chartData.data && question.chartData.data.length"
            >
              <el-col :span="12">
                <d3-vertical-bar
                  v-if="question.chartType === 'Bar'"
                  :options="vBarChartOptions"
                  :data="question.chartData"
                  width="500px"
                  height="400px"
                />
                <d3-pie
                  v-else
                  :options="pieChartOptions"
                  :data="question.chartData.data"
                  width="500px"
                  height="400px"
                />
              </el-col>
            </template>
            <template v-else>
              <el-col :span="12">
                <p style="margin: 10px">No data</p>
              </el-col>
            </template>
            <el-col :span="12" style="position: relative; height: 370px">
              <template v-if="question.chartData.data.length > 0">
                <span style="margin-left: 8px">Chart type:</span>
                <el-select
                  v-model="question.chartType"
                  style="width: 140px; margin-top: 15px"
                  class="filter-item"
                  @change="handleChartTypeChange(question, $event)"
                >
                  <el-option
                    v-for="item in chartTypes"
                    :key="item.key"
                    :label="item.label"
                    :value="item.key"
                  />
                </el-select>
              </template>
              <template
                v-if="
                  question.availableIntervals.length > 0 &&
                  question.chartType == 'Bar'
                "
              >
                <div style="margin-top: 10px">
                  <span style="margin-left: 8px">Intervals:</span>
                  <el-checkbox-group
                    v-model="question.selectedIntervals"
                    @change="handleChangeInterval(question, $event)"
                  >
                    <div>
                      <el-checkbox
                        v-for="interval in question.availableIntervals"
                        :key="interval"
                        :label="interval"
                        class="intervalCheckbox"
                        >{{ interval }}</el-checkbox
                      >
                    </div>
                  </el-checkbox-group>
                </div>
              </template>
              <div style="position: absolute; bottom: 0; font-weight: bold">
                <div
                  v-for="legend in question.chartData.rightLegend"
                  :key="legend"
                >
                  <div :style="{ color: legend.color }">{{ legend.text }}</div>
                </div>
              </div>
            </el-col>
          </template>
        </el-row>
        <el-row>
          <div v-if="question.ResponseType == 'Textarea'">
            <el-table
              :key="question.Key"
              :data="question.chartData.legendData"
              fit
              highlight-current-row
              style="width: 100%"
            >
              <el-table-column label="Value" align="left">
                <template slot-scope="scope">
                  <span
                    :style="{
                      'font-weight':
                        scope.row.question &&
                        scope.row.question.endsWith('(Correct Answer)')
                          ? 'bold'
                          : 'normal',
                    }"
                    style="
                      white-space: pre-wrap;
                      word-break: normal;
                      line-height: normal;
                    "
                    >{{ scope.row.question }}</span
                  >
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div v-else :id="question.Key + 'legend_table'">
            <el-table
              :key="question.Key"
              :data="question.chartData.legendData"
              fit
              highlight-current-row
              style="width: 100%"
            >
              <el-table-column label="Value" align="left" width="250px">
                <template slot-scope="scope">
                  <span
                    :style="{
                      'font-weight':
                        scope.row.question &&
                        scope.row.question.endsWith('(Correct Answer)')
                          ? 'bold'
                          : 'normal',
                    }"
                    style="
                      white-space: pre-wrap;
                      word-break: normal;
                      line-height: normal;
                    "
                    >{{ scope.row.question }}</span
                  >
                </template>
              </el-table-column>
              <el-table-column label width="150px" align="center">
                <template slot-scope="scope">
                  <div
                    style="
                      background-color: #f1f1f1;
                      position: relative;
                      height: 25px;
                    "
                  >
                    <template v-for="value in scope.row.values">
                      <div
                        :key="value.color"
                        :style="{
                          'background-color': question.chartData.multiple
                            ? value.groupColor
                            : value.color,
                          width: value.percent + '%',
                        }"
                        style="height: 25px; position: absolute"
                      />
                    </template>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="Percent" width="170px" align="right">
                <template slot-scope="scope">
                  <span>{{ scope.row.percentsText }}</span>
                </template>
              </el-table-column>
              <el-table-column label="Responses" width="100px" align="right">
                <template slot-scope="scope">
                  <span>{{ scope.row.valuesText }}</span>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </el-row>
      </template>
    </div>
  </div>
</template>

<style scoped>
.app-container {
  background: white;
}

.intervalCheckbox {
  line-height: 30px;
  height: 30px;
  margin-left: 0;
  display: block;
}
</style>

<script>
import { getToken } from "@/utils/auth";
import { baseUrl, editSurvey, publishSurvey, downloadPdf } from "@/api/survey";
import waves from "@/directive/waves"; // Waves directive
import { parseTime } from "@/utils";
import moment from "moment";
import FileSaver from "file-saver";
import Downloader from "downloadjs";
export default {
  name: "Report",
  directives: { waves },
  data() {
    return {
      logo: null,
      pdfButtonName: "PDF",
      pdfProgress: "",
      pdfInProgress: false,
      colors: [
        "#f37b79",
        "#799bf3",
        "#bef379",
        "#f379e2",
        "#79f3e0",
        "#f3bd79",
        "#9979f3",
        "#7cf379",
        "#f379a0",
        "#79c3f3",
        "#e7f379",
        "#db79f3",
        "#79f3b8",
        "#f39479",
        "#7981f3",
        "#a5f379",
        "#f379c8",
        "#79ecf3",
        "#f3d679",
        "#b379f3",
        "#79f38f",
        "#f37987",
        "#79aaf3",
        "#cef379",
        "#f379f1",
        "#79f3d1",
      ],
      pieChartOptions: {
        innerRadius: 40,
        cornerRadius: 0,
        padAngle: 0.01,
        axisXLabelHeight: 30,
        axisXLabel: "",
        arcLabel: "",
        axisXLabelFontSize: 12,
        axisXLabelFontWeight: 400,
        axisXLabelFontOpacity: 1,
        arcLabelFontSize: 14,
        arcLabelFontOpacity: 1,
        animationDuration: 1000,
      },
      vBarChartOptions: {
        axisXLaneHeight: 10,
        fill: "#6eadc1",
        stroke: "#6eadc1",
        fillOpacity: 1,
        strokeOpacity: 1,
        axisFontSize: 12,
        axisFontWeight: 400,
        axisYLabel: "",
        axisXLabel: "",
        axisYTickFormat: "d",
        axisXLabelHeight: 12,
        axisYLabelWidth: 12,
        axisLabelFontSize: 12,
        axisLabelFontWeight: 400,
        axisLabelFontOpacity: 0.5,
        axisXHeight: 12,
        axisYWidth: 12,
        isVertical: false,
      },
      baseUrl: baseUrl,
      loading: false,
      chartTypes: [
        { label: "Bar", key: "Bar" },
        { label: "Pie", key: "Pie" },
      ],
      survey: {},
    };
  },
  created() {
    this.getSurvey();
  },
  methods: {
    b64toBlob: function (b64Data, contentType, sliceSize) {
      contentType = contentType || "";
      sliceSize = sliceSize || 512;

      var byteCharacters = atob(b64Data);
      var byteArrays = [];

      for (
        var offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        var slice = byteCharacters.slice(offset, offset + sliceSize);

        var byteNumbers = new Array(slice.length);
        for (var i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        var byteArray = new Uint8Array(byteNumbers);

        byteArrays.push(byteArray);
      }

      var blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
    getLocation() {
      return this.baseUrl.replace("api", "");
    },
    getSurvey() {
      editSurvey({ activityId: this.$route.query.activityId }).then(
        (response) => {
          var questions = JSON.parse(response.data.questions);
          var responses = JSON.parse(response.data.responses);

          var data = response.data;
          data.questions = [];
          data.responses = [];

          var surveyData = [];

          var $this = this;
          questions.forEach(function (question) {
            question.questionAnswers = $this.getAnswers(question);
            question.visible = true;
          });

          for (var i = 0; i < questions.length; i++) {
            var question = questions[i];
            question.selectedIntervals = [];
            this.fillParentChildData(question, questions);
            var key = { key: question["Key"], interval: question["Interval"] };
            var mainData = this.getChartDataFromResponses(
              responses,
              questions,
              [key],
              question.questionAnswers
            );

            question.chartType = this.chartTypes[0].key;
            var legendData = this.getLegendData(mainData);
            question.chartData = {
              multiple: false,
              data: mainData,
              legendData: legendData,
              prefAnswer: question["PreferredAnswer"],
              rightLegend: [],
            };

            this.setChartRightLegend(question);
          }

          for (var i = 0; i < questions.length; i++) {
            var q1 = questions[i];
            for (var j = i; j < questions.length; j++) {
              var q2 = questions[j];
              if (q1.Question == q2.Question && q1.Key != q2.Key) {
                q2.visible = false;
              }
            }
          }

          data.questions = questions;
          data.responses = responses;
          this.survey = data;

          for (var i = 0; i < questions.length; i++) {
            var question = questions[i];
            question.selectedIntervals = question.availableIntervals;
            this.handleChangeInterval(question);
          }
        }
      );
    },
    fillParentChildData: function (question, questions) {
      var questionKey = question["Key"];
      var parent = question["Parent"];
      question.parentChildData = [];

      var $this = this;
      questions.forEach(function (q) {
        if (
          (parent && q["Key"] == parent) ||
          (q["Parent"] && q["Parent"] == questionKey)
        ) {
          question.parentChildData.push({
            interval: q["Interval"],
            key: q["Key"],
            questionAnswers: $this.getAnswers(q),
          });
        }
      });

      var availableIntervals = question.parentChildData.reduce(function (
        arr,
        item
      ) {
        var found = false;
        for (var n = 0; n < arr.length; n++) {
          if (arr[n].interval === item.interval) found = true;
        }
        if (!found && item.interval != question["Interval"]) {
          arr.push(item.interval);
        }
        return arr;
      },
      []);

      availableIntervals.push(question.Interval);

      question.availableIntervals = availableIntervals;
    },

    getChartDataFromResponses: function (
      responses,
      questions,
      questionKeys,
      questionAnswers
    ) {
      var tmp = [];
      for (var j = 0; j < questionKeys.length; j++) {
        var questionKey = questionKeys[j].key;
        var interval = questionKeys[j].interval;
        this.setInitialAnswers(tmp, questions, questionKey, interval);
        for (var i = 0; i < responses.length; i++) {
          var response = responses[i];
          this.fillAnswers(
            tmp,
            questions,
            response,
            questionKey,
            questionAnswers,
            interval
          );
        }
      }
      var result = this.normilizeChartData(tmp);
      return result;
    },
    setInitialAnswers: function (data, questions, questionKey, interval) {
      var responseType = "";
      for (var i = 0; i < questions.length; i++) {
        var question = questions[i];
        if (question["Key"] == questionKey) {
          responseType = question.ResponseType;
          break;
        }
      }
      if (responseType == "Multiple" || responseType == "Single") {
        for (var i = 0; i < question.questionAnswers.length; i++) {
          var answer = question.questionAnswers[i];
          data.push({
            group: questionKey,
            key: answer,
            position: i + 1,
            interval: interval,
          });
        }
      }
    },
    normilizeChartData: function (data) {
      var sum = 0;

      var groups = data.reduce(function (arr, item) {
        var found = false;
        for (var n = 0; n < arr.length; n++) {
          if (arr[n] === item.group) {
            found = true;
            break;
          }
        }
        if (!found) {
          arr.push(item.group);
        }
        return arr;
      }, []);

      var result = [];
      for (var i = 0; i < groups.length; i++) {
        var sum = 0;
        var group = groups[i];
        var groupResult = data.reduce(function (arr, item) {
          if (item.group == group) {
            var found = false;
            for (var n = 0; n < arr.length; n++) {
              if (arr[n].key === item.key) {
                found = true;
                arr[n].value++;
              }
            }
            if (!found) {
              item.value = 0;
              arr.push(item);
            } else {
              sum++;
            }
          }
          return arr;
        }, []);

        for (var j = 0; j < groupResult.length; j++) {
          var item = groupResult[j];
          item.groupColor = this.colors[i];
          if (sum > 0) {
            item.percent = ((item.value / sum) * 100).toFixed();
          } else {
            item.percent = 0;
          }
          item.color = this.colors[j];
        }

        groupResult = this.sortByKey(groupResult, "position");

        result = result.concat(groupResult);
      }

      return result;
    },
    sortByKey(array, key) {
      if (array[0][key] == null) return array;
      return array.sort(function (a, b) {
        var x = a[key];
        var y = b[key];
        return x < y ? -1 : x > y ? 1 : 0;
      });
    },
    fillAnswers: function (
      data,
      questions,
      response,
      questionKey,
      questionAnswers,
      interval
    ) {
      var answer = response[questionKey];
      var position = null;
      if (answer && answer != "") {
        var responseType = "";
        for (var i = 0; i < questions.length; i++) {
          var question = questions[i];
          if (question["Key"] == questionKey) {
            responseType = question.ResponseType;
            break;
          }
        }
        if (responseType == "Multiple") {
          var answerParts = answer.split(",");
          for (var k = 0; k < answerParts.length; k++) {
            var part = answerParts[k];
            if (this.isNumeric(part)) {
              position = answerParts[k] - 1;
              data.push({
                group: questionKey,
                key: questionAnswers[position],
                position: position,
                interval: interval,
              });
            } else {
              data.push({
                group: questionKey,
                key: part,
                position: position,
                interval: interval,
              });
            }
          }
        } else if (responseType == "Single") {
          if (this.isNumeric(answer)) {
            position = answer - 1;
            data.push({
              group: questionKey,
              key: questionAnswers[position],
              position: position,
              interval: interval,
            });
          } else {
            data.push({
              group: questionKey,
              key: answer,
              position: position,
              interval: interval,
            });
          }
        } else {
          data.push({
            group: questionKey,
            key: answer,
            position: position,
            interval: interval,
          });
        }
      }
    },
    getAnswers: function (question) {
      var answers = [];
      var fields = Object.keys(question);
      for (var i = 0; i < fields.length; i++) {
        var field = fields[i];
        this.addToAnswers(answers, question, field, i + 1);
      }

      return answers;
    },
    addToAnswers: function (answers, question, value, index) {
      var isAnswer = value.startsWith("Answer");
      var isNotNull = question[value] !== null;
      var isNotEmpty = question[value] !== "";

      if (isAnswer && isNotNull && isNotEmpty) {
        var answer = question[value];
        var prefAnswer = question["PreferredAnswer"];
        if (prefAnswer && value == "Answer" + prefAnswer) {
          answer = answer + " (Correct Answer)";
        }

        answers.push(answer);
      }
    },
    isNumeric: function (n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    handleChangeInterval: function (question) {
      var intervals = question.selectedIntervals;
      var answers = question.questionAnswers;
      var keys = [];

      for (var k = 0; k < intervals.length; k++) {
        var interval = intervals[k];
        if (question["Interval"] == interval) {
          keys.push({ key: question["Key"], interval: question["Interval"] });
          break;
        }
      }

      for (var i = 0; i < question.parentChildData.length; i++) {
        var parentData = question.parentChildData[i];
        for (var j = 0; j < intervals.length; j++) {
          var interval = intervals[j];
          if (parentData.interval == interval) {
            keys.push({ key: parentData.key, interval: interval });
          }
        }
      }

      var data = this.getChartDataFromResponses(
        this.survey.responses,
        this.survey.questions,
        keys,
        answers
      );

      data.forEach(function (d) {
        if (
          (d.key == question.questionAnswers[question["PreferredAnswer"] - 1] ||
            d.key + " (Correct Answer)" ==
              question.questionAnswers[question["PreferredAnswer"] - 1]) &&
          !d.key.endsWith("(Correct Answer)")
        ) {
          d.key = d.key + " (Correct Answer)";
        }
      });

      var legendData = this.getLegendData(data);

      question.chartData = {
        multiple: keys.length > 1,
        data: data,
        legendData: legendData,
        prefAnswer: question["PreferredAnswer"],
        rightLegend: [],
      };

      this.setChartRightLegend(question);
    },
    setChartRightLegend(question) {
      var sums = question.chartData.data.reduce(function (arr, item) {
        var found = false;
        for (var n = 0; n < arr.length; n++) {
          if (arr[n].group === item.group) {
            found = true;
            arr[n].sum = arr[n].sum + item.value;
            break;
          }
        }
        if (!found) {
          item.sum = item.value;
          arr.push(item);
        }
        return arr;
      }, []);

      for (var i = 0; i < sums.length; i++) {
        var sum = sums[i];
        question.chartData.rightLegend.push({
          text: "n=" + sum.sum + " (" + sum.interval + ")",
          color: question.chartData.multiple ? sum.groupColor : "black",
        });
      }
    },
    getLegendData: function (data) {
      var result = data.reduce(function (arr, item) {
        var found = null;
        for (var n = 0; n < arr.length; n++) {
          if (arr[n].question === item.key) {
            found = arr[n];
            break;
          }
        }
        if (!found) {
          arr.push({
            question: item.key,
            valuesText: item.value,
            percentsText: item.percent + "%",
            values: [
              {
                percent: item.percent,
                color: item.color,
                groupColor: item.groupColor,
              },
            ],
          });
        } else {
          found.valuesText += "/" + item.value;
          found.percentsText += "/" + item.percent + "%";
          found.values.push({
            percent: item.percent,
            color: item.color,
            groupColor: item.groupColor,
          });
          found.values.sort(function (a, b) {
            return b.percent - a.percent;
          });
        }

        return arr;
      }, []);

      return result;
    },
    handleDelete: function (questionKey) {
      var toDelete;
      for (var i = 0; i < this.survey.questions.length; i++) {
        var question = this.survey.questions[i];
        if (question["Key"] == questionKey) {
          toDelete = this.survey.questions[i];
          break;
        }
      }
      this.survey.questions;
      var index = this.survey.questions.indexOf(toDelete);
      if (index > -1) {
        this.survey.questions.splice(index, 1);
      }
    },
    handleChartTypeChange: function (question, evt) {
      if ((evt = "Pie")) {
        if (question.selectedIntervals.length) {
          var int = question.availableIntervals[0];
          question.selectedIntervals = [];
          question.selectedIntervals.push(int);
        }
        this.handleChangeInterval(question);
      }
    },
    handlePublish: function () {
      publishSurvey({
        activity: this.survey.activity,
        date: this.survey.date,
        notes: this.survey.notes,
        report: JSON.stringify(this.survey.questions),
      }).then((response) => {
        this.survey.reportId = response.data;
      });
    },
    handlePDF: function () {
      this.pdfButtonName = "";
      this.pdfInProgress = true;

      return downloadPdf({ surveyId: this.survey.reportId }).then(
        (response) => {
          var blob = this.b64toBlob(response.data.body);
          FileSaver.saveAs(blob, "report.pdf");
          this.pdfInProgress = false;
          this.pdfButtonName = "PDF";
        }
      );
    },
  },
};
</script>
