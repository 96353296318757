import request from '@/utils/request'

const baseUrl = request.baseUrl

export { baseUrl }

export function search(query) {
  return request({
    url: '/activities/search',
    method: 'Get',
    params: query
  })
}

export function getActivity(query) {
  return request({
    url: '/activities/GetActivity',
    method: 'Get',
    params: query
  })
}

export function create(data) {
  return request({
    url: '/activities/create',
    method: 'Post',
    data
  })
}

export function update(data) {
  return request({
    url: '/activities/update',
    method: 'Post',
    data
  })
}

export function remove(id) {
  return request({
    url: '/activities/delete',
    method: 'Delete',
    params: id
  })
}

export function deleteFile(query) {
  return request({
    url: '/activities/deleteFile',
    method: 'Delete',
    params: query
  })
}

export function exportQuestions(query) {
  return request({
    url: '/activities/exportQuestions',
    params: query,
    method: 'Get',
    // responseType: 'arraybuffer',
    headers: {
      'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    }
  })
}

export function exportResponses(query) {
  return request({
    url: '/activities/exportResponses',
    params: query,
    method: 'Get',
    // responseType: 'arraybuffer',
    headers: {
      'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    }
  })
}

export function getCertificatesForActivity(query) {
  return request({
    url: '/activities/getCertificatesForActivity',
    method: 'Get',
    params: query
  })
}

export function updateActivityCertificate(data) {
  return request({
    url: '/activities/updateActivityCertificate',
    method: 'Post',
    data
  })
}

export function downloadCertificate(query) {
  return request({
    url: '/activities/downloadCertificate',
    params: query,
    method: 'Get',
    // responseType: 'arraybuffer',
    headers: {
      'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    }
  })
}

export function getEmailTemplates(query){
  return request({
    url: '/activities/getEmailTemplates',
    method: 'Get',
    params: query
  })
}