import request from '@/utils/request'

const baseUrl = request.baseUrl

export { baseUrl }

export function editSurvey(query) {
  return request({
    url: '/survey/Edit',
    method: 'Get',
    params: query
  })
}

export function getSurvey(query) {
  return request({
    url: '/survey/Get',
    method: 'Get',
    params: query
  })
}

export function getWebSurvey(query) {
  return request({
    url: '/survey/GetWebSurvey',
    method: 'Get',
    params: query
  })
}

export function getQuestionsForWebSurvey(query) {
  return request({
    url: '/survey/GetQuestionsForWebSurvey',
    method: 'Get',
    params: query
  })
}

export function getWebSurveyForInterval(query){
  return request({
    url: '/survey/GetWebSurveyForInterval',
    method: 'Get',
    params: query
  })
}

export function getWebSurveyForUid(query){
  return request({
    url: '/survey/getWebSurveyForUid',
    method: 'Get',
    params: query
  })
}

export function saveWebSurvey(data){
  return request({
    url: '/survey/SaveWebSurvey',
    method: 'Post',
    data
  });
}

export function publishSurvey(data) {
  return request({
    url: '/survey/Publish',
    method: 'Post',
    data
  })
}

export function downloadPdf(query) {
  return request({
    url: '/survey/DownloadPdf',
    method: 'Get',
    params: query
  })
}

export function submitWebSurveyAnswers(data){
  return request({
    url: '/survey/SubmitWebSurveyAnswers',
    method: 'Post',
    data
  })
}

export function getLogo(query) {
  return request({
    url: '/survey/getLogo',
    method: 'Get',
    params: query
  })
}

export function getLogoByOrganizationId(query) {
  return request({
    url: '/evaluation/getLogoByOrganizationId',
    method: 'Get',
    params: query
  })
}