import request from '@/utils/request'

const baseUrl = request.baseUrl

export { baseUrl }

export function questions() {
  return request({
    url: '/template/questions',
    method: 'Get',
    headers: {
      'Content-Type': 'blob'
    }
  })
}

export function responses() {
  return request({
    url: '/template/responses',
    method: 'Get',
    headers: {
      'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    }
  })
}

export function attendees() {
  return request({
    url: '/template/attendees',
    method: 'Get',
    headers: {
      'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    }
  })
}

export function participants() {
  return request({
    url: '/template/participants',
    method: 'Get',
    headers: {
      'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    }
  })
}
