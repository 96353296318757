var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.survey && _vm.survey.activity
    ? _c(
        "div",
        { staticClass: "app-container" },
        [
          _c(
            "div",
            [
              _c("el-input", {
                staticStyle: { width: "400px" },
                model: {
                  value: _vm.survey.activity.name,
                  callback: function($$v) {
                    _vm.$set(_vm.survey.activity, "name", $$v)
                  },
                  expression: "survey.activity.name"
                }
              }),
              _vm._v(" "),
              _c("el-date-picker", {
                staticStyle: { width: "200px" },
                attrs: { format: "MM/dd/yyyy", type: "date" },
                model: {
                  value: _vm.survey.date,
                  callback: function($$v) {
                    _vm.$set(_vm.survey, "date", $$v)
                  },
                  expression: "survey.date"
                }
              }),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: { type: "primary", size: "mini" },
                  on: {
                    click: function($event) {
                      _vm.handlePublish()
                    }
                  }
                },
                [_vm._v("Publish")]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                {
                  attrs: {
                    loading: _vm.pdfInProgress,
                    type: "primary",
                    size: "mini"
                  },
                  on: {
                    click: function($event) {
                      _vm.handlePDF()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.pdfButtonName))]
              ),
              _vm._v(" "),
              _vm.pdfInProgress
                ? _c("span", [_vm._v(_vm._s(_vm.pdfProgress))])
                : _vm._e(),
              _vm._v(" "),
              _vm.survey.reportId > 0
                ? _c(
                    "a",
                    {
                      staticClass: "link-type",
                      attrs: {
                        href:
                          _vm.getLocation() +
                          "#/reports/view?surveyId=" +
                          _vm.survey.reportId,
                        target: "_blank"
                      }
                    },
                    [_vm._v("View Report")]
                  )
                : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticStyle: { "margin-top": "5px" } },
            [
              _c("el-input", {
                staticStyle: { width: "605px" },
                attrs: { rows: 2, type: "textarea", placeholder: "Notes" },
                model: {
                  value: _vm.survey.notes,
                  callback: function($$v) {
                    _vm.$set(_vm.survey, "notes", $$v)
                  },
                  expression: "survey.notes"
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _vm._l(_vm.survey.questions, function(question) {
            return _c(
              "div",
              {
                key: question.Key,
                staticStyle: { "margin-top": "30px" },
                attrs: { id: question.Key + "_content" }
              },
              [
                question.Question && question.visible
                  ? [
                      _c(
                        "el-row",
                        [
                          _c("el-col", { attrs: { span: 12 } }, [
                            _c("p", { staticStyle: { margin: "10px" } }, [
                              _vm._v(_vm._s(question.Question))
                            ])
                          ]),
                          _vm._v(" "),
                          _c(
                            "el-col",
                            { attrs: { span: 12 } },
                            [
                              _c(
                                "el-button",
                                {
                                  staticStyle: {
                                    "margin-top": "10px",
                                    "margin-left": "8px"
                                  },
                                  attrs: { size: "mini", type: "danger" },
                                  on: {
                                    click: function($event) {
                                      _vm.handleDelete(question.Key)
                                    }
                                  }
                                },
                                [_vm._v(_vm._s(_vm.$t("table.delete")))]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-row",
                        [
                          question.ResponseType != "Textarea"
                            ? [
                                question.chartData.data &&
                                question.chartData.data.length
                                  ? [
                                      _c(
                                        "el-col",
                                        { attrs: { span: 12 } },
                                        [
                                          question.chartType === "Bar"
                                            ? _c("d3-vertical-bar", {
                                                attrs: {
                                                  options: _vm.vBarChartOptions,
                                                  data: question.chartData,
                                                  width: "500px",
                                                  height: "400px"
                                                }
                                              })
                                            : _c("d3-pie", {
                                                attrs: {
                                                  options: _vm.pieChartOptions,
                                                  data: question.chartData.data,
                                                  width: "500px",
                                                  height: "400px"
                                                }
                                              })
                                        ],
                                        1
                                      )
                                    ]
                                  : [
                                      _c("el-col", { attrs: { span: 12 } }, [
                                        _c(
                                          "p",
                                          { staticStyle: { margin: "10px" } },
                                          [_vm._v("No data")]
                                        )
                                      ])
                                    ],
                                _vm._v(" "),
                                _c(
                                  "el-col",
                                  {
                                    staticStyle: {
                                      position: "relative",
                                      height: "370px"
                                    },
                                    attrs: { span: 12 }
                                  },
                                  [
                                    question.chartData.data.length > 0
                                      ? [
                                          _c(
                                            "span",
                                            {
                                              staticStyle: {
                                                "margin-left": "8px"
                                              }
                                            },
                                            [_vm._v("Chart type:")]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "el-select",
                                            {
                                              staticClass: "filter-item",
                                              staticStyle: {
                                                width: "140px",
                                                "margin-top": "15px"
                                              },
                                              on: {
                                                change: function($event) {
                                                  _vm.handleChartTypeChange(
                                                    question,
                                                    $event
                                                  )
                                                }
                                              },
                                              model: {
                                                value: question.chartType,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    question,
                                                    "chartType",
                                                    $$v
                                                  )
                                                },
                                                expression: "question.chartType"
                                              }
                                            },
                                            _vm._l(_vm.chartTypes, function(
                                              item
                                            ) {
                                              return _c("el-option", {
                                                key: item.key,
                                                attrs: {
                                                  label: item.label,
                                                  value: item.key
                                                }
                                              })
                                            })
                                          )
                                        ]
                                      : _vm._e(),
                                    _vm._v(" "),
                                    question.availableIntervals.length > 0 &&
                                    question.chartType == "Bar"
                                      ? [
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "margin-top": "10px"
                                              }
                                            },
                                            [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "margin-left": "8px"
                                                  }
                                                },
                                                [_vm._v("Intervals:")]
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "el-checkbox-group",
                                                {
                                                  on: {
                                                    change: function($event) {
                                                      _vm.handleChangeInterval(
                                                        question,
                                                        $event
                                                      )
                                                    }
                                                  },
                                                  model: {
                                                    value:
                                                      question.selectedIntervals,
                                                    callback: function($$v) {
                                                      _vm.$set(
                                                        question,
                                                        "selectedIntervals",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "question.selectedIntervals"
                                                  }
                                                },
                                                [
                                                  _c(
                                                    "div",
                                                    _vm._l(
                                                      question.availableIntervals,
                                                      function(interval) {
                                                        return _c(
                                                          "el-checkbox",
                                                          {
                                                            key: interval,
                                                            staticClass:
                                                              "intervalCheckbox",
                                                            attrs: {
                                                              label: interval
                                                            }
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(interval)
                                                            )
                                                          ]
                                                        )
                                                      }
                                                    )
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          )
                                        ]
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "div",
                                      {
                                        staticStyle: {
                                          position: "absolute",
                                          bottom: "0",
                                          "font-weight": "bold"
                                        }
                                      },
                                      _vm._l(
                                        question.chartData.rightLegend,
                                        function(legend) {
                                          return _c("div", { key: legend }, [
                                            _c(
                                              "div",
                                              {
                                                style: { color: legend.color }
                                              },
                                              [_vm._v(_vm._s(legend.text))]
                                            )
                                          ])
                                        }
                                      )
                                    )
                                  ],
                                  2
                                )
                              ]
                            : _vm._e()
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("el-row", [
                        question.ResponseType == "Textarea"
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-table",
                                  {
                                    key: question.Key,
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      data: question.chartData.legendData,
                                      fit: "",
                                      "highlight-current-row": ""
                                    }
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: { label: "Value", align: "left" },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "white-space": "pre-wrap",
                                                    "word-break": "normal",
                                                    "line-height": "normal"
                                                  },
                                                  style: {
                                                    "font-weight":
                                                      scope.row.question &&
                                                      scope.row.question.endsWith(
                                                        "(Correct Answer)"
                                                      )
                                                        ? "bold"
                                                        : "normal"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(scope.row.question)
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ])
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                          : _c(
                              "div",
                              { attrs: { id: question.Key + "legend_table" } },
                              [
                                _c(
                                  "el-table",
                                  {
                                    key: question.Key,
                                    staticStyle: { width: "100%" },
                                    attrs: {
                                      data: question.chartData.legendData,
                                      fit: "",
                                      "highlight-current-row": ""
                                    }
                                  },
                                  [
                                    _c("el-table-column", {
                                      attrs: {
                                        label: "Value",
                                        align: "left",
                                        width: "250px"
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _c(
                                                "span",
                                                {
                                                  staticStyle: {
                                                    "white-space": "pre-wrap",
                                                    "word-break": "normal",
                                                    "line-height": "normal"
                                                  },
                                                  style: {
                                                    "font-weight":
                                                      scope.row.question &&
                                                      scope.row.question.endsWith(
                                                        "(Correct Answer)"
                                                      )
                                                        ? "bold"
                                                        : "normal"
                                                  }
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(scope.row.question)
                                                  )
                                                ]
                                              )
                                            ]
                                          }
                                        }
                                      ])
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        label: "",
                                        width: "150px",
                                        align: "center"
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _c(
                                                "div",
                                                {
                                                  staticStyle: {
                                                    "background-color":
                                                      "#f1f1f1",
                                                    position: "relative",
                                                    height: "25px"
                                                  }
                                                },
                                                [
                                                  _vm._l(
                                                    scope.row.values,
                                                    function(value) {
                                                      return [
                                                        _c("div", {
                                                          key: value.color,
                                                          staticStyle: {
                                                            height: "25px",
                                                            position: "absolute"
                                                          },
                                                          style: {
                                                            "background-color": question
                                                              .chartData
                                                              .multiple
                                                              ? value.groupColor
                                                              : value.color,
                                                            width:
                                                              value.percent +
                                                              "%"
                                                          }
                                                        })
                                                      ]
                                                    }
                                                  )
                                                ],
                                                2
                                              )
                                            ]
                                          }
                                        }
                                      ])
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        label: "Percent",
                                        width: "170px",
                                        align: "right"
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(scope.row.percentsText)
                                                )
                                              ])
                                            ]
                                          }
                                        }
                                      ])
                                    }),
                                    _vm._v(" "),
                                    _c("el-table-column", {
                                      attrs: {
                                        label: "Responses",
                                        width: "100px",
                                        align: "right"
                                      },
                                      scopedSlots: _vm._u([
                                        {
                                          key: "default",
                                          fn: function(scope) {
                                            return [
                                              _c("span", [
                                                _vm._v(
                                                  _vm._s(scope.row.valuesText)
                                                )
                                              ])
                                            ]
                                          }
                                        }
                                      ])
                                    })
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                      ])
                    ]
                  : _vm._e()
              ],
              2
            )
          })
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }