var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "div",
        { staticClass: "filter-container" },
        [
          _c("el-input", {
            staticClass: "filter-item",
            staticStyle: { width: "200px" },
            attrs: { placeholder: _vm.$t(_vm.getSearchTypePlaceholder()) },
            nativeOn: {
              keyup: function($event) {
                if (
                  !("button" in $event) &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                ) {
                  return null
                }
                return _vm.handleFilter($event)
              }
            },
            model: {
              value: _vm.listQuery.term,
              callback: function($$v) {
                _vm.$set(_vm.listQuery, "term", $$v)
              },
              expression: "listQuery.term"
            }
          }),
          _vm._v(" "),
          _c(
            "el-select",
            {
              staticClass: "filter-item",
              staticStyle: { width: "140px" },
              on: { change: _vm.handleSearchtype },
              model: {
                value: _vm.listQuery.searchBy,
                callback: function($$v) {
                  _vm.$set(_vm.listQuery, "searchBy", $$v)
                },
                expression: "listQuery.searchBy"
              }
            },
            _vm._l(_vm.searchTypes, function(item) {
              return _c("el-option", {
                key: item.key,
                attrs: { label: item.label, value: item.key }
              })
            })
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              directives: [{ name: "waves", rawName: "v-waves" }],
              staticClass: "filter-item",
              attrs: { type: "primary", icon: "el-icon-search" },
              on: { click: _vm.handleFilter }
            },
            [_vm._v(_vm._s(_vm.$t("table.search")))]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              staticClass: "filter-item",
              staticStyle: { "margin-left": "10px" },
              attrs: { type: "primary", icon: "el-icon-edit" },
              on: { click: _vm.handleCreate }
            },
            [_vm._v(_vm._s(_vm.$t("table.add")))]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-table",
        {
          directives: [
            {
              name: "loading",
              rawName: "v-loading",
              value: _vm.listLoading,
              expression: "listLoading"
            }
          ],
          key: _vm.tableKey,
          staticStyle: { width: "100%" },
          attrs: {
            data: _vm.list,
            border: "",
            fit: "",
            "highlight-current-row": ""
          }
        },
        [
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("series.activityId"),
              align: "center",
              width: "150px"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.activityId))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("series.name"),
              "min-width": "200px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.name))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("series.seriesNumber"),
              "min-width": "200px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.number))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: { label: _vm.$t("series.area"), width: "220px" },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [_c("span", [_vm._v(_vm._s(scope.row.area.name))])]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("series.activitiesCount"),
              width: "130px",
              align: "center"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c("span", [_vm._v(_vm._s(scope.row.activitiesCount))])
                  ]
                }
              }
            ])
          }),
          _vm._v(" "),
          _c("el-table-column", {
            attrs: {
              label: _vm.$t("table.actions"),
              align: "center",
              width: "230",
              "class-name": "small-padding fixed-width"
            },
            scopedSlots: _vm._u([
              {
                key: "default",
                fn: function(scope) {
                  return [
                    _c(
                      "el-button",
                      {
                        attrs: { type: "primary", size: "mini" },
                        on: {
                          click: function($event) {
                            _vm.handleView(scope.row)
                          }
                        }
                      },
                      [_vm._v(_vm._s(_vm.$t("table.view")))]
                    ),
                    _vm._v(" "),
                    scope.row.canDelete
                      ? _c(
                          "el-button",
                          {
                            attrs: { size: "mini", type: "danger" },
                            on: {
                              click: function($event) {
                                _vm.handleDelete(scope.row)
                              }
                            }
                          },
                          [_vm._v(_vm._s(_vm.$t("table.delete")))]
                        )
                      : _vm._e()
                  ]
                }
              }
            ])
          })
        ],
        1
      ),
      _vm._v(" "),
      _c("pagination", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.total > 0,
            expression: "total>0"
          }
        ],
        attrs: {
          total: _vm.total,
          page: _vm.listQuery.page,
          limit: _vm.listQuery.limit
        },
        on: {
          "update:page": function($event) {
            _vm.$set(_vm.listQuery, "page", $event)
          },
          "update:limit": function($event) {
            _vm.$set(_vm.listQuery, "limit", $event)
          },
          pagination: _vm.getList
        }
      }),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.textMap[_vm.dialogStatus],
            visible: _vm.dialogViewFormVisible
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogViewFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              staticStyle: { width: "800px" },
              attrs: {
                rules: _vm.rules,
                model: _vm.temp,
                "label-position": "left",
                "label-width": "170px"
              }
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("series.activityId") } },
                        [
                          _c("label", { staticClass: "light-label" }, [
                            _vm._v(_vm._s(_vm.temp.activityId))
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("series.name") } },
                        [
                          _c("label", { staticClass: "light-label" }, [
                            _vm._v(_vm._s(_vm.temp.name))
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("series.seriesNumber") } },
                        [
                          _c("label", { staticClass: "light-label" }, [
                            _vm._v(_vm._s(_vm.temp.number))
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: _vm.$t("series.activitiesCountFull") }
                        },
                        [
                          _c("label", { staticClass: "light-label" }, [
                            _vm._v(_vm._s(_vm.temp.activitiesCount))
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("series.area") } },
                        [
                          _c("label", { staticClass: "light-label" }, [
                            _vm._v(_vm._s(_vm.temp.area.name))
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: _vm.$t("series.educationalPartner") }
                        },
                        [
                          _c("label", { staticClass: "light-label" }, [
                            _vm._v(_vm._s(_vm.temp.educationalPartner))
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: _vm.$t("series.commercialPartner") }
                        },
                        [
                          _c("label", { staticClass: "light-label" }, [
                            _vm._v(_vm._s(_vm.temp.commercialPartner))
                          ])
                        ]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "intervalsContainer",
                          staticStyle: { "margin-left": "10" },
                          attrs: { label: _vm.$t("series.interval") }
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { display: "block" } },
                            _vm._l(_vm.temp.intervals, function(interval) {
                              return _c("div", { key: interval }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "el-form-item__content",
                                    staticStyle: { clear: "both" }
                                  },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "light-label" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.intervals.find(function(item) {
                                              return item.id == interval
                                            }).name
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ])
                            })
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: {
                    click: function($event) {
                      _vm.closeViewDialog()
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("tagsView.close")))]
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "el-dialog",
        {
          attrs: {
            title: _vm.textMap[_vm.dialogStatus],
            visible: _vm.dialogCreateFormVisible
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogCreateFormVisible = $event
            }
          }
        },
        [
          _c(
            "el-form",
            {
              ref: "dataForm",
              staticStyle: { width: "800px" },
              attrs: {
                rules: _vm.rules,
                model: _vm.temp,
                "label-position": "left",
                "label-width": "170px"
              }
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 12 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("series.activityId"),
                            prop: "activityId"
                          }
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.temp.activityId,
                              callback: function($$v) {
                                _vm.$set(_vm.temp, "activityId", $$v)
                              },
                              expression: "temp.activityId"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: _vm.$t("series.name"), prop: "name" }
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.temp.name,
                              callback: function($$v) {
                                _vm.$set(_vm.temp, "name", $$v)
                              },
                              expression: "temp.name"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("series.seriesNumber"),
                            prop: "seriesNumber"
                          }
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.temp.number,
                              callback: function($$v) {
                                _vm.$set(_vm.temp, "number", $$v)
                              },
                              expression: "temp.number"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("series.activitiesCountFull"),
                            prop: "activitiesCount"
                          }
                        },
                        [
                          _c("el-input", {
                            nativeOn: {
                              keypress: function($event) {
                                return _vm.isNumber($event)
                              }
                            },
                            model: {
                              value: _vm.temp.activitiesCount,
                              callback: function($$v) {
                                _vm.$set(_vm.temp, "activitiesCount", $$v)
                              },
                              expression: "temp.activitiesCount"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: _vm.$t("series.area"), prop: "area" }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              staticStyle: { width: "100%" },
                              attrs: {
                                "value-key": "id",
                                placeholder: "Please select area"
                              },
                              model: {
                                value: _vm.temp.area,
                                callback: function($$v) {
                                  _vm.$set(_vm.temp, "area", $$v)
                                },
                                expression: "temp.area"
                              }
                            },
                            _vm._l(_vm.areas, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item }
                              })
                            })
                          )
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("series.commercialPartner"),
                            prop: "commercialPartner"
                          }
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.temp.commercialPartner,
                              callback: function($$v) {
                                _vm.$set(_vm.temp, "commercialPartner", $$v)
                              },
                              expression: "temp.commercialPartner"
                            }
                          })
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("series.educationalPartner"),
                            prop: "educationalPartner"
                          }
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.temp.educationalPartner,
                              callback: function($$v) {
                                _vm.$set(_vm.temp, "educationalPartner", $$v)
                              },
                              expression: "temp.educationalPartner"
                            }
                          })
                        ],
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "intervalsContainer",
                          staticStyle: { "margin-left": "10" },
                          attrs: {
                            label: _vm.$t("series.interval"),
                            prop: "intervals"
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { display: "block" } },
                            [
                              _c(
                                "el-checkbox-group",
                                {
                                  model: {
                                    value: _vm.temp.intervals,
                                    callback: function($$v) {
                                      _vm.$set(_vm.temp, "intervals", $$v)
                                    },
                                    expression: "temp.intervals"
                                  }
                                },
                                _vm._l(_vm.intervals, function(
                                  interval,
                                  $index
                                ) {
                                  return _c(
                                    "el-checkbox",
                                    {
                                      key: interval.id,
                                      staticClass: "intervalCheckbox",
                                      class: {
                                        "intervalCheckbox-even":
                                          $index % 2 === 0
                                      },
                                      attrs: { label: interval.id }
                                    },
                                    [_vm._v(_vm._s(interval.name))]
                                  )
                                })
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c(
                "el-button",
                {
                  on: {
                    click: function($event) {
                      _vm.dialogCreateFormVisible = false
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("table.cancel")))]
              ),
              _vm._v(" "),
              _c(
                "el-button",
                { attrs: { type: "primary" }, on: { click: _vm.createData } },
                [_vm._v(_vm._s(_vm.$t("table.confirm")))]
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }