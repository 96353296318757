import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

/* Layout */
import Layout from '@/views/layout/Layout'

/* Router Modules */
// import componentsRouter from './modules/components'
// import chartsRouter from './modules/charts'
// import tableRouter from './modules/table'
// import nestedRouter from './modules/nested'

/** note: Submenu only appear when children.length>=1
 *  detail see  https://panjiachen.github.io/vue-element-admin-site/guide/essentials/router-and-nav.html
 **/

/**
* hidden: true                   if `hidden:true` will not show in the sidebar(default is false)
* alwaysShow: true               if set true, will always show the root menu, whatever its child routes length
*                                if not set alwaysShow, only more than one route under the children
*                                it will becomes nested mode, otherwise not show the root menu
* redirect: noredirect           if `redirect:noredirect` will no redirect in the breadcrumb
* name:'router-name'             the name is used by <keep-alive> (must set!!!)
* meta : {
    roles: ['admin','editor']     will control the page roles (you can set multiple roles)
    title: 'title'               the name show in submenu and breadcrumb (recommend set)
    icon: 'svg-name'             the icon show in the sidebar,
    noCache: true                if true ,the page will no be cached(default is false)
  }
**/
export const constantRouterMap = [
  {
    path: '/redirect',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/redirect/:path*',
        component: () => import('@/views/redirect/index')
      }
    ]
  },
  {
    path: '/login',
    component: () => import('@/views/login/index'),
    hidden: true
  },
  {
    path: '/registration/reg',
    component: () => import('@/views/registration/reg'),
    hidden: true
  },
  {
    path: '/auth-redirect',
    component: () => import('@/views/login/authredirect'),
    hidden: true
  },
  {
    path: '/404',
    component: () => import('@/views/errorPage/404'),
    hidden: true
  },
  {
    path: '/401',
    component: () => import('@/views/errorPage/401'),
    hidden: true
  },
  {
    path: '/activities',
    redirect: '/activities/list',
    component: Layout,
    children: [
      {
        path: 'list',
        component: () => import('@/views/activities/activitiesGrid'),
        name: 'Activities List',
        meta: { title: 'Activities', icon: 'list', noCache: true }
      },
      {
        path: 'activityDetails',
        component: () => import('@/views/activities/activityDetails'),
        name: 'Edit Activity',
        meta: { title: 'Edit Activity', icon: 'list', noCache: true },
        hidden: true
      }
    ]
  },
  {
    path: '',
    redirect: '/activities',
    component: Layout
  },
  {
    path: '/series',
    redirect: '/series/list',
    component: Layout,
    children: [
      {
        path: 'list',
        component: () => import('@/views/series/seriesGrid'),
        name: 'Series List',
        meta: { title: 'Series', icon: 'list', noCache: true }
      }
    ]
  },
  {
    path: '/certificates',
    component: Layout,
    redirect: '/certificates/attendeeCertificates',
    alwaysShow: true,
    meta: { title: 'Certificates', icon: 'edit' },
    children: [
      {
        path: 'attendeeCertificates',
        component: () => import('@/views/certificates/attendeeCertificates'),
        name: 'Send for attendees',
        meta: { title: 'Send for attendees' }
      },
      {
        path: 'activitySeriesCertificatesList',
        component: () => import('@/views/certificates/activitySeriesCertificatesList'),
        name: 'View',
        meta: { title: 'View', noCache: true }
      }
    ]
  },
  {
    path: '/users',
    redirect: '/users/list',
    component: Layout,
    children: [
      {
        path: 'list',
        component: () => import('@/views/users/usersGrid'),
        name: 'Users List',
        meta: { title: 'Users', icon: 'list', noCache: true }
      }
    ]
  },
  {
    path: '/templates',
    redirect: '/templates/list',
    component: Layout,
    children: [
      {
        path: 'list',
        component: () => import('@/views/templates/all'),
        name: 'Excel templates',
        meta: { title: 'Templates', icon: 'list', noCache: true }
      }
    ]
  },
  {
    path: '/attendees',
    redirect: '/attendees/list',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '/attendees/list',
        component: () => import('@/views/attendees/attendeesGrid'),
        name: 'Attendees List',
        meta: { title: 'Web Survey', noCache: true },
        hidden: true
      }
    ]
  },
  {
    path: '/reports',
    redirect: '/reports/edit',
    component: Layout,
    hidden: true,
    children: [
      {
        path: 'edit',
        component: () => import('@/views/reports/edit'),
        name: 'Edit report',
        meta: { title: 'Report', icon: 'list', noCache: true }
      },
      {
        path: '/reports/webedit',
        component: () => import('@/views/reports/webedit'),
        name: 'Edit WebBased Report',
        meta: { title: 'Edit Web Based Report', noCache: true },
        hidden: true
      }
    ]
  },
  {
    path: '/reports/view',
    component: () => import('@/views/reports/view'),
    name: 'View report',
    meta: { title: 'Report', noCache: true },
    hidden: true
  },
  {
    path: '/reports/print',
    component: () => import('@/views/reports/print'),
    name: 'Print report',
    meta: { title: 'Report', noCache: true },
    hidden: true
  },
  {
    path: '/reports/webPreview',
    component: () => import('@/views/reports/webPreview'),
    name: 'Preview Web Based Survey',
    meta: { title: 'Web Survey', noCache: true },
    hidden: true
  },
  {
    path: '/survey/web',
    component: () => import('@/views/survey/web'),
    name: 'Web Survey',
    meta: { title: 'Web Survey', noCache: true },
    hidden: true
  },
  {
    path: '/evaluation',
    redirect: '/evaluation/edit',
    component: Layout,
    hidden: true,
    children: [
      {
        path: 'edit',
        component: () => import('@/views/evaluation/edit'),
        name: 'Edit Evaluation',
        meta: { title: 'Edit Evaluation', noCache: true },
        hidden: true
      }
    ]
  },
  {
    path: '/evaluation/preview',
    component: () => import('@/views/evaluation/preview'),
    name: 'Preview Evaluation',
    meta: { title: 'Preview Evaluation', noCache: true },
    hidden: true
  },
  {
    path: '/evaluation/evaluation',
    component: () => import('@/views/evaluation/evaluation'),
    name: 'Evaluation',
    meta: { title: 'Evaluation', noCache: true },
    hidden: true
  },
  {
    path: '/evaluation/preTest',
    component: () => import('@/views/evaluation/preTest'),
    name: 'Pre Test',
    meta: { title: 'Pre Test', noCache: true },
    hidden: true
  },
  {
    path: '/evaluation/postTest',
    component: () => import('@/views/evaluation/postTest'),
    name: 'Post Test',
    meta: { title: 'Post Test', noCache: true },
    hidden: true
  },
  {
    path: '/evaluation/certificate',
    component: () => import('@/views/evaluation/certificate'),
    name: 'Certificate',
    meta: { title: 'Certificate', noCache: true },
    hidden: true
  },
  {
    path: '/evaluation/thankyou',
    component: () => import('@/views/evaluation/thankyou'),
    name: 'Thank You',
    meta: { title: 'Thank You', noCache: true },
    hidden: true
  }
  // ,
  // {
  //   path: '/reports/webPreview',
  //   component: () => import('@/views/reports/webPreview'),
  //   name: 'Preview Web Based Survey',
  //   meta: { title: 'Web Survey', noCache: true },
  //   hidden: true
  // },
  // {
  //   path: '/survey/web',
  //   component: () => import('@/views/survey/web'),
  //   name: 'Web Survey',
  //   meta: { title: 'Web Survey', noCache: true },
  //   hidden: true
  // }
  // {
  //   path: '',
  //   component: Layout,
  //   redirect: 'dashboard',
  //   children: [
  //     {
  //       path: 'dashboard',
  //       component: () => import('@/views/dashboard/index'),
  //       name: 'Dashboard',
  //       meta: { title: 'dashboard', icon: 'dashboard', noCache: true }
  //     }
  //   ]
  // },
  // {
  //   path: '/documentation',
  //   component: Layout,
  //   redirect: '/documentation/index',
  //   children: [
  //     {
  //       path: 'index',
  //       component: () => import('@/views/documentation/index'),
  //       name: 'Documentation',
  //       meta: { title: 'documentation', icon: 'documentation', noCache: true }
  //     }
  //   ]
  // },
  // {
  //   path: '/guide',
  //   component: Layout,
  //   redirect: '/guide/index',
  //   children: [
  //     {
  //       path: 'index',
  //       component: () => import('@/views/guide/index'),
  //       name: 'Guide',
  //       meta: { title: 'guide', icon: 'guide', noCache: true }
  //     }
  //   ]
  // }
]

export default new Router({
  // mode: 'history', // require service support
  scrollBehavior: () => ({ y: 0 }),
  routes: constantRouterMap
})

export const asyncRouterMap = [
  // {
  //   path: '/permission',
  //   component: Layout,
  //   redirect: '/permission/index',
  //   alwaysShow: true, // will always show the root menu
  //   meta: {
  //     title: 'permission',
  //     icon: 'lock',
  //     roles: ['admin', 'editor'] // you can set roles in root nav
  //   },
  //   children: [
  //     {
  //       path: 'page',
  //       component: () => import('@/views/permission/page'),
  //       name: 'PagePermission',
  //       meta: {
  //         title: 'pagePermission',
  //         roles: ['admin'] // or you can only set roles in sub nav
  //       }
  //     },
  //     {
  //       path: 'directive',
  //       component: () => import('@/views/permission/directive'),
  //       name: 'DirectivePermission',
  //       meta: {
  //         title: 'directivePermission'
  //         // if do not set roles, means: this page does not require permission
  //       }
  //     }
  //   ]
  // },

  // {
  //   path: '/icon',
  //   component: Layout,
  //   children: [
  //     {
  //       path: 'index',
  //       component: () => import('@/views/svg-icons/index'),
  //       name: 'Icons',
  //       meta: { title: 'icons', icon: 'icon', noCache: true }
  //     }
  //   ]
  // },

  /** When your routing table is too long, you can split it into small modules**/
  // componentsRouter,
  // chartsRouter,
  // nestedRouter,
  // tableRouter,

  // {
  //   path: '/example',
  //   component: Layout,
  //   redirect: '/example/list',
  //   name: 'Example',
  //   meta: {
  //     title: 'example',
  //     icon: 'example'
  //   },
  //   children: [
  //     {
  //       path: 'create',
  //       component: () => import('@/views/example/create'),
  //       name: 'CreateArticle',
  //       meta: { title: 'createArticle', icon: 'edit' }
  //     },
  //     {
  //       path: 'edit/:id(\\d+)',
  //       component: () => import('@/views/example/edit'),
  //       name: 'EditArticle',
  //       meta: { title: 'editArticle', noCache: true },
  //       hidden: true
  //     },
  //     {
  //       path: 'list',
  //       component: () => import('@/views/example/list'),
  //       name: 'ArticleList',
  //       meta: { title: 'articleList', icon: 'list' }
  //     }
  //   ]
  // },

  // {
  //   path: '/tab',
  //   component: Layout,
  //   children: [
  //     {
  //       path: 'index',
  //       component: () => import('@/views/tab/index'),
  //       name: 'Tab',
  //       meta: { title: 'tab', icon: 'tab' }
  //     }
  //   ]
  // },

  // {
  //   path: '/error',
  //   component: Layout,
  //   redirect: 'noredirect',
  //   name: 'ErrorPages',
  //   meta: {
  //     title: 'errorPages',
  //     icon: '404'
  //   },
  //   children: [
  //     {
  //       path: '401',
  //       component: () => import('@/views/errorPage/401'),
  //       name: 'Page401',
  //       meta: { title: 'page401', noCache: true }
  //     },
  //     {
  //       path: '404',
  //       component: () => import('@/views/errorPage/404'),
  //       name: 'Page404',
  //       meta: { title: 'page404', noCache: true }
  //     }
  //   ]
  // },

  // {
  //   path: '/error-log',
  //   component: Layout,
  //   redirect: 'noredirect',
  //   children: [
  //     {
  //       path: 'log',
  //       component: () => import('@/views/errorLog/index'),
  //       name: 'ErrorLog',
  //       meta: { title: 'errorLog', icon: 'bug' }
  //     }
  //   ]
  // },

  // {
  //   path: '/excel',
  //   component: Layout,
  //   redirect: '/excel/export-excel',
  //   name: 'Excel',
  //   meta: {
  //     title: 'excel',
  //     icon: 'excel'
  //   },
  //   children: [
  //     {
  //       path: 'export-excel',
  //       component: () => import('@/views/excel/exportExcel'),
  //       name: 'ExportExcel',
  //       meta: { title: 'exportExcel' }
  //     },
  //     {
  //       path: 'export-selected-excel',
  //       component: () => import('@/views/excel/selectExcel'),
  //       name: 'SelectExcel',
  //       meta: { title: 'selectExcel' }
  //     },
  //     {
  //       path: 'upload-excel',
  //       component: () => import('@/views/excel/uploadExcel'),
  //       name: 'UploadExcel',
  //       meta: { title: 'uploadExcel' }
  //     }
  //   ]
  // },

  // {
  //   path: '/zip',
  //   component: Layout,
  //   redirect: '/zip/download',
  //   alwaysShow: true,
  //   meta: { title: 'zip', icon: 'zip' },
  //   children: [
  //     {
  //       path: 'download',
  //       component: () => import('@/views/zip/index'),
  //       name: 'ExportZip',
  //       meta: { title: 'exportZip' }
  //     }
  //   ]
  // },

  // {
  //   path: '/theme',
  //   component: Layout,
  //   redirect: 'noredirect',
  //   children: [
  //     {
  //       path: 'index',
  //       component: () => import('@/views/theme/index'),
  //       name: 'Theme',
  //       meta: { title: 'theme', icon: 'theme' }
  //     }
  //   ]
  // },

  // {
  //   path: '/clipboard',
  //   component: Layout,
  //   redirect: 'noredirect',
  //   children: [
  //     {
  //       path: 'index',
  //       component: () => import('@/views/clipboard/index'),
  //       name: 'ClipboardDemo',
  //       meta: { title: 'clipboardDemo', icon: 'clipboard' }
  //     }
  //   ]
  // },

  // {
  //   path: '/i18n',
  //   component: Layout,
  //   children: [
  //     {
  //       path: 'index',
  //       component: () => import('@/views/i18n-demo/index'),
  //       name: 'I18n',
  //       meta: { title: 'i18n', icon: 'international' }
  //     }
  //   ]
  // },

  // {
  //   path: 'external-link',
  //   component: Layout,
  //   children: [
  //     {
  //       path: 'https://github.com/PanJiaChen/vue-element-admin',
  //       meta: { title: 'externalLink', icon: 'link' }
  //     }
  //   ]
  // },

  { path: '*', redirect: '/404', hidden: true }
]
