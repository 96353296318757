var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "tab-container" },
    [
      _c(
        "el-tabs",
        {
          staticStyle: { "margin-top": "15px" },
          attrs: { type: "border-card" },
          model: {
            value: _vm.activeName,
            callback: function($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName"
          }
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "Activity", name: "Activity" } },
            [
              _c(
                "keep-alive",
                [
                  _vm.activeName == "Activity"
                    ? _c("activityTab", {
                        on: { activityLoaded: _vm.onActivityLoaded }
                      })
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _vm.activity != null && _vm.activity.questionsFile != null
            ? _c(
                "el-tab-pane",
                { attrs: { label: "Report", name: "Report" } },
                [
                  _c(
                    "keep-alive",
                    [_vm.activeName == "Report" ? _c("reportTab") : _vm._e()],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.activity != null &&
          _vm.activity.questionsFile != null &&
          _vm.surveyTabVisisble
            ? _c(
                "el-tab-pane",
                { attrs: { label: "Survey", name: "Survey" } },
                [
                  _c(
                    "keep-alive",
                    [_vm.activeName == "Survey" ? _c("surveyTab") : _vm._e()],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.activity != null && _vm.activity.attendeesFile != null
            ? _c(
                "el-tab-pane",
                { attrs: { label: "Attendees", name: "Attendees" } },
                [
                  _c(
                    "keep-alive",
                    [
                      _vm.activeName == "Attendees"
                        ? _c("attendeesTab")
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.activity != null && _vm.activity.questionsFile != null
            ? _c(
                "el-tab-pane",
                { attrs: { label: "Evaluation", name: "Evaluation" } },
                [
                  _c(
                    "keep-alive",
                    [
                      _vm.activeName == "Evaluation"
                        ? _c("evaluationTab")
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "Certificates", name: "Certificates" } },
            [
              _c(
                "keep-alive",
                [
                  _vm.activeName == "Certificates"
                    ? _c("certificatesTab")
                    : _vm._e()
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-tab-pane",
            { attrs: { label: "Email Templates", name: "EmailTemplates" } },
            [
              _c(
                "keep-alive",
                [
                  _vm.activeName == "EmailTemplates"
                    ? _c("emailTemplateTab")
                    : _vm._e()
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }