var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c(
        "el-form",
        {
          ref: "dataForm",
          staticStyle: { width: "800px" },
          attrs: {
            rules: _vm.rules,
            model: _vm.activity,
            "label-position": "left",
            "label-width": "170px"
          }
        },
        [
          _c(
            "el-row",
            [
              _c(
                "el-col",
                { attrs: { span: 12 } },
                [
                  _vm.activity.seriesId > 0
                    ? _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("activity.activityId") } },
                        [
                          _c("label", { staticClass: "light-label" }, [
                            _vm._v(_vm._s(_vm.activity.activityId))
                          ])
                        ]
                      )
                    : _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: _vm.$t("activity.activityId"),
                            prop: "activityId"
                          }
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.activity.activityId,
                              callback: function($$v) {
                                _vm.$set(_vm.activity, "activityId", $$v)
                              },
                              expression: "activity.activityId"
                            }
                          })
                        ],
                        1
                      ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: _vm.$t("activity.name"), prop: "name" } },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.activity.name,
                          callback: function($$v) {
                            _vm.$set(_vm.activity, "name", $$v)
                          },
                          expression: "activity.name"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("activity.additionToTitle"),
                        prop: "additionToTitle"
                      }
                    },
                    [
                      _c("el-input", {
                        model: {
                          value: _vm.activity.additionToTitle,
                          callback: function($$v) {
                            _vm.$set(_vm.activity, "additionToTitle", $$v)
                          },
                          expression: "activity.additionToTitle"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("activity.startDate"),
                        prop: "startDate"
                      }
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          format: "MM/dd/yyyy",
                          "value-format": "yyyy-MM-dd",
                          type: "date"
                        },
                        model: {
                          value: _vm.activity.startDate,
                          callback: function($$v) {
                            _vm.$set(_vm.activity, "startDate", $$v)
                          },
                          expression: "activity.startDate"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("activity.endDate"),
                        prop: "endDate"
                      }
                    },
                    [
                      _c("el-date-picker", {
                        attrs: {
                          format: "MM/dd/yyyy",
                          "value-format": "yyyy-MM-dd",
                          type: "date"
                        },
                        model: {
                          value: _vm.activity.endDate,
                          callback: function($$v) {
                            _vm.$set(_vm.activity, "endDate", $$v)
                          },
                          expression: "activity.endDate"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.activity.seriesId > 0
                    ? _c(
                        "el-form-item",
                        { attrs: { label: _vm.$t("series.area") } },
                        [
                          _c("label", { staticClass: "light-label" }, [
                            _vm._v(_vm._s(_vm.activity.area.name))
                          ])
                        ]
                      )
                    : _c(
                        "el-form-item",
                        {
                          attrs: { label: _vm.$t("series.area"), prop: "area" }
                        },
                        [
                          _c(
                            "el-select",
                            {
                              staticClass: "filter-item",
                              staticStyle: { width: "100%" },
                              attrs: {
                                "value-key": "id",
                                placeholder: "Please select area"
                              },
                              model: {
                                value: _vm.activity.area,
                                callback: function($$v) {
                                  _vm.$set(_vm.activity, "area", $$v)
                                },
                                expression: "activity.area"
                              }
                            },
                            _vm._l(_vm.areas, function(item) {
                              return _c("el-option", {
                                key: item.id,
                                attrs: { label: item.name, value: item }
                              })
                            })
                          )
                        ],
                        1
                      ),
                  _vm._v(" "),
                  _vm.activity.seriesId > 0
                    ? _c(
                        "el-form-item",
                        {
                          attrs: { label: _vm.$t("series.educationalPartner") }
                        },
                        [
                          _c("label", { staticClass: "light-label" }, [
                            _vm._v(_vm._s(_vm.activity.educationalPartner))
                          ])
                        ]
                      )
                    : _c(
                        "el-form-item",
                        {
                          attrs: { label: _vm.$t("series.educationalPartner") }
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.activity.educationalPartner,
                              callback: function($$v) {
                                _vm.$set(
                                  _vm.activity,
                                  "educationalPartner",
                                  $$v
                                )
                              },
                              expression: "activity.educationalPartner"
                            }
                          })
                        ],
                        1
                      ),
                  _vm._v(" "),
                  _vm.activity.seriesId > 0
                    ? _c(
                        "el-form-item",
                        {
                          attrs: { label: _vm.$t("series.commercialPartner") }
                        },
                        [
                          _c("label", { staticClass: "light-label" }, [
                            _vm._v(_vm._s(_vm.activity.commercialPartner))
                          ])
                        ]
                      )
                    : _c(
                        "el-form-item",
                        {
                          attrs: { label: _vm.$t("series.commercialPartner") }
                        },
                        [
                          _c("el-input", {
                            model: {
                              value: _vm.activity.commercialPartner,
                              callback: function($$v) {
                                _vm.$set(_vm.activity, "commercialPartner", $$v)
                              },
                              expression: "activity.commercialPartner"
                            }
                          })
                        ],
                        1
                      ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: _vm.$t("activity.description"),
                        prop: "description"
                      }
                    },
                    [
                      _c("el-input", {
                        attrs: { type: "textarea" },
                        model: {
                          value: _vm.activity.description,
                          callback: function($$v) {
                            _vm.$set(_vm.activity, "description", $$v)
                          },
                          expression: "activity.description"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _vm._v(" "),
              _vm.activity.seriesId > 0 || _vm.activity.questionsFile != null
                ? _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "intervalsContainer",
                          staticStyle: { "margin-left": "10" },
                          attrs: { label: _vm.$t("series.interval") }
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { display: "block" } },
                            _vm._l(_vm.activity.intervals, function(interval) {
                              return _c("div", { key: interval }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "el-form-item__content",
                                    staticStyle: { clear: "both" }
                                  },
                                  [
                                    _c(
                                      "label",
                                      { staticClass: "light-label" },
                                      [
                                        _vm._v(
                                          _vm._s(
                                            _vm.intervals.find(function(item) {
                                              return item.id == interval
                                            }).name
                                          )
                                        )
                                      ]
                                    )
                                  ]
                                )
                              ])
                            })
                          )
                        ]
                      )
                    ],
                    1
                  )
                : _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          staticClass: "intervalsContainer",
                          staticStyle: { "margin-left": "10" },
                          attrs: {
                            label: _vm.$t("series.interval"),
                            prop: "intervals"
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticStyle: { display: "block" } },
                            [
                              _c(
                                "el-checkbox-group",
                                {
                                  model: {
                                    value: _vm.activity.intervals,
                                    callback: function($$v) {
                                      _vm.$set(_vm.activity, "intervals", $$v)
                                    },
                                    expression: "activity.intervals"
                                  }
                                },
                                _vm._l(_vm.intervals, function(
                                  interval,
                                  $index
                                ) {
                                  return _c(
                                    "el-checkbox",
                                    {
                                      key: interval.id,
                                      staticClass: "intervalCheckbox",
                                      class: {
                                        "intervalCheckbox-even":
                                          $index % 2 === 0
                                      },
                                      attrs: { label: interval.id }
                                    },
                                    [_vm._v(_vm._s(interval.name))]
                                  )
                                })
                              )
                            ],
                            1
                          )
                        ]
                      )
                    ],
                    1
                  )
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c("el-col", { attrs: { span: 20 } }, [
                _c(
                  "div",
                  {
                    staticStyle: {
                      width: "100%",
                      "border-bottom": "1px solid black",
                      "padding-bottom": "10px"
                    }
                  },
                  [
                    _c("span", { staticStyle: { "font-weight": "bold" } }, [
                      _vm._v("Attendees")
                    ])
                  ]
                )
              ])
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "el-row",
            [
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 1 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _vm.activity.responsesFile != null
                          ? _c("svg-icon", {
                              attrs: { "icon-class": "documentation" }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c("div", { staticClass: "grid-content" }, [
                      _c("label", [_vm._v("Attendees")])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "grid-content" }, [
                      _vm.activity.attendeesFile != null
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.activity.attendeesFile != null
                                  ? _vm
                                      .moment(
                                        _vm.activity.attendeesFile.createdDate
                                      )
                                      .format("MM/DD/YYYY h:mm a")
                                  : ""
                              )
                            )
                          ])
                        : _vm._e()
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 4 } }, [
                    _c("div", [
                      _vm.activity.attendeesFile == null
                        ? _c(
                            "div",
                            {
                              staticClass: "grid-content",
                              attrs: {
                                disabled: _vm.activity.attendeesFile == null
                              }
                            },
                            [
                              _c(
                                "el-upload",
                                {
                                  staticClass: "upload",
                                  attrs: {
                                    action:
                                      _vm.uploadBaseUrl +
                                      "/activities/uploadFile",
                                    multiple: false,
                                    "auto-upload": true,
                                    "show-file-list": false,
                                    headers: _vm.headerInfo,
                                    data: _vm.fileDataAttendees(),
                                    "on-success": _vm.onAttendeeUploadSuccess,
                                    "on-error": _vm.onFileUploadError,
                                    name: "Content"
                                  }
                                },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "small", type: "primary" }
                                    },
                                    [_vm._v("Upload")]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.activity.attendeesFile != null
                        ? _c(
                            "div",
                            { staticClass: "grid-content" },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: { size: "small", type: "danger" },
                                  on: {
                                    click: function($event) {
                                      _vm.handleAttendeeDeleteFile()
                                    }
                                  }
                                },
                                [_vm._v("Delete")]
                              )
                            ],
                            1
                          )
                        : _vm._e()
                    ])
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 20 } }, [
                    _c(
                      "div",
                      {
                        staticStyle: {
                          width: "100%",
                          "border-bottom": "1px solid black",
                          "padding-bottom": "10px"
                        }
                      },
                      [
                        _c("span", { staticStyle: { "font-weight": "bold" } }, [
                          _vm._v("Survey")
                        ])
                      ]
                    )
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 1 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _vm.activity.questionsFile != null
                          ? _c("svg-icon", {
                              attrs: { "icon-class": "documentation" }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c("div", { staticClass: "grid-content" }, [
                      _c("label", [_vm._v("Questions")])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "grid-content" }, [
                      _vm.activity.questionsFile != null
                        ? _c("div", [
                            _vm._v(
                              "\n              " +
                                _vm._s(
                                  _vm.activity.questionsFile != null
                                    ? _vm
                                        .moment(
                                          _vm.activity.questionsFile.createdDate
                                        )
                                        .format("MM/DD/YYYY h:mm a")
                                    : ""
                                ) +
                                "\n            "
                            )
                          ])
                        : _vm._e()
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 3 } }, [
                    _vm.activity.questionsFile == null
                      ? _c(
                          "div",
                          { staticClass: "grid-content" },
                          [
                            _c(
                              "el-upload",
                              {
                                staticClass: "upload",
                                attrs: {
                                  action:
                                    _vm.uploadBaseUrl +
                                    "/activities/uploadFile",
                                  multiple: false,
                                  "auto-upload": true,
                                  "show-file-list": false,
                                  headers: _vm.headerInfo,
                                  data: _vm.fileDataQuestions(),
                                  "on-success": _vm.onQuestionsUploadSuccess,
                                  "on-error": _vm.onFileUploadError,
                                  name: "Content"
                                }
                              },
                              [
                                _c(
                                  "el-button",
                                  { attrs: { size: "small", type: "primary" } },
                                  [_vm._v("Upload")]
                                )
                              ],
                              1
                            )
                          ],
                          1
                        )
                      : _c(
                          "div",
                          { staticClass: "grid-content" },
                          [
                            _vm.activity.responsesFile == null
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { size: "small", type: "danger" },
                                    on: {
                                      click: function($event) {
                                        _vm.handleQuestionsDeleteFile()
                                      }
                                    }
                                  },
                                  [_vm._v("Delete")]
                                )
                              : _c(
                                  "el-button",
                                  {
                                    attrs: {
                                      hint:
                                        "Please remove responses file first",
                                      size: "small",
                                      type: "danger",
                                      disabled: ""
                                    }
                                  },
                                  [_vm._v("Delete")]
                                )
                          ],
                          1
                        )
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 3 } }, [
                    _vm.activity.questionsFile != null
                      ? _c(
                          "div",
                          { staticClass: "grid-content" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "small", type: "warning" },
                                on: {
                                  click: function($event) {
                                    _vm.exportQuestions(_vm.activity.id)
                                  }
                                }
                              },
                              [_vm._v("Export")]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "el-row",
                [
                  _c("el-col", { attrs: { span: 1 } }, [
                    _c(
                      "div",
                      { staticClass: "grid-content" },
                      [
                        _vm.activity.responsesFile != null
                          ? _c("svg-icon", {
                              attrs: { "icon-class": "documentation" }
                            })
                          : _vm._e()
                      ],
                      1
                    )
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 3 } }, [
                    _c("div", { staticClass: "grid-content" }, [
                      _c("label", [_vm._v("Responses")])
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 6 } }, [
                    _c("div", { staticClass: "grid-content" }, [
                      _vm.activity.responsesFile != null
                        ? _c("span", [
                            _vm._v(
                              _vm._s(
                                _vm.activity.responsesFile != null
                                  ? _vm
                                      .moment(
                                        _vm.activity.responsesFile.createdDate
                                      )
                                      .format("MM/DD/YYYY h:mm a")
                                  : ""
                              )
                            )
                          ])
                        : _vm._e()
                    ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 3 } }, [
                    _vm.activity.questionsFile == null
                      ? _c(
                          "div",
                          { staticClass: "grid-content" },
                          [
                            _c(
                              "el-button",
                              {
                                staticClass: "upload",
                                attrs: {
                                  title: "Please upload questions first",
                                  size: "small",
                                  type: "danger",
                                  disabled: ""
                                }
                              },
                              [_vm._v("Upload")]
                            )
                          ],
                          1
                        )
                      : _c("div", [
                          _vm.activity.responsesFile == null
                            ? _c(
                                "div",
                                {
                                  staticClass: "grid-content",
                                  attrs: {
                                    disabled: _vm.activity.questionsFile == null
                                  }
                                },
                                [
                                  _c(
                                    "el-upload",
                                    {
                                      staticClass: "upload",
                                      attrs: {
                                        action:
                                          _vm.uploadBaseUrl +
                                          "/activities/uploadFile",
                                        multiple: false,
                                        "auto-upload": true,
                                        "show-file-list": false,
                                        headers: _vm.headerInfo,
                                        data: _vm.fileDataResponses(),
                                        "on-success":
                                          _vm.onResponsesUploadSuccess,
                                        "on-error": _vm.onFileUploadError,
                                        name: "Content"
                                      }
                                    },
                                    [
                                      _c(
                                        "el-button",
                                        {
                                          attrs: {
                                            size: "small",
                                            type: "primary"
                                          }
                                        },
                                        [_vm._v("Upload")]
                                      )
                                    ],
                                    1
                                  )
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.activity.responsesFile != null
                            ? _c(
                                "div",
                                { staticClass: "grid-content" },
                                [
                                  _c(
                                    "el-button",
                                    {
                                      attrs: { size: "small", type: "danger" },
                                      on: {
                                        click: function($event) {
                                          _vm.handleResponsesDeleteFile()
                                        }
                                      }
                                    },
                                    [_vm._v("Delete")]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ])
                  ]),
                  _vm._v(" "),
                  _c("el-col", { attrs: { span: 3 } }, [
                    _vm.activity.questionsFile != null
                      ? _c(
                          "div",
                          { staticClass: "grid-content" },
                          [
                            _c(
                              "el-button",
                              {
                                attrs: { size: "small", type: "warning" },
                                on: {
                                  click: function($event) {
                                    _vm.exportResponses(_vm.activity.id)
                                  }
                                }
                              },
                              [_vm._v("Export")]
                            )
                          ],
                          1
                        )
                      : _vm._e()
                  ])
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dialog-footer",
          attrs: { slot: "footer" },
          slot: "footer"
        },
        [
          _c(
            "el-button",
            {
              on: {
                click: function($event) {
                  _vm.dialogFormVisible = false
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("table.cancel")))]
          ),
          _vm._v(" "),
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  _vm.updateData()
                }
              }
            },
            [_vm._v(_vm._s(_vm.$t("table.confirm")))]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }