<template>
  <div class="app-container">
    <p class="aligncenter">
      <img :src="'data:image/jpg;base64,' + logo" style="height: 100px" />
    </p>
    <div>
      <p style="text-align: center; padding: 30px">{{ evaluation.intro }}</p>
    </div>
    <template v-if="submitButtonVisible">
      <div
        v-for="(question, index) in evaluation.questions"
        :key="question.Key"
      >
        <div stle="margin-top:10px;">
          <div
            :class="{ required: question.Required }"
            style="margin-top: 10px; margin-bottom: 10px"
          >
            {{ index + 1 }}. {{ question.Question }}
          </div>
          <div
            v-if="question.NeedAnswer"
            style="margin-top: 5px; margin-bottom: 5px; color: red"
          >
            This is required question
          </div>
          <div v-if="question.ResponseType == 'Single'">
            <el-radio-group v-model="question.checkedAnswers">
              <el-radio
                v-for="answer in question.answers"
                :label="answer.label"
                :key="answer.value"
                >{{ answer.label }}</el-radio
              >
            </el-radio-group>
          </div>
          <div v-if="question.ResponseType == 'Multiple'">
            <el-checkbox-group v-model="question.checkedAnswers">
              <el-checkbox
                v-for="answer in question.answers"
                :label="answer.label"
                :key="answer.value"
                >{{ answer.label }}</el-checkbox
              >
            </el-checkbox-group>
          </div>
          <div v-if="question.ResponseType == 'Textarea'">
            <el-input
              v-model="question.textAnswer"
              type="textarea"
              placeholder="Your answer"
            />
          </div>
        </div>
        <hr />
      </div>
      <div style="margin-top: 30px">
        <el-button type="primary" @click="handleSubmitAnswers()"
          >Submit</el-button
        >
      </div>
    </template>
    <template v-else>
      <h2>
        Thank you for taking our survey. Your response is very important to us.
      </h2>
    </template>
  </div>
</template>

<style>
.required::after {
  content: "*";
  color: red;
}
</style>

<style scoped>
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 3em 0;
  padding: 0;
}

.aligncenter {
  text-align: center;
}
.app-container {
  background: white;
}

.intervalCheckbox {
  line-height: 30px;
  height: 30px;
  margin-left: 0;
  display: block;
}

.el-checkbox {
  display: block;
  margin-top: 5px !important;
  margin-left: 0px !important;
  margin-right: 30px !important;
}

.el-checkbox + .el-checkbox {
  margin-left: 0px !important;
  margin-right: 30px !important;
}

.el-radio {
  display: block;
  margin-top: 5px !important;
  margin-left: 0px !important;
}

.el-radio + .el-radio {
  display: block;
  margin-top: 5px !important;
  margin-left: 0px !important;
}
</style>

<script>
import { getToken } from "@/utils/auth";
import {
  baseUrl,
  getPostEvaluationByUid,
  submitPostEvaluationAnswers,
  getLogo,
} from "@/api/evaluation";
import { getAll as ResponseTypesGetAll } from "@/api/responseTypes";
import waves from "@/directive/waves";
import { parseTime } from "@/utils";
import moment from "moment";
export default {
  name: "PostEvaluation",
  directives: { waves },
  data() {
    return {
      logo: "",
      baseUrl: baseUrl,
      loading: false,
      evaluation: {},
      submitButtonVisible: false,
      temp: {
        Include: false,
        ResponseType: null,
        Interval: null,
        Question: "",
        PreferredAnswer: null,
        Answer1: null,
        Answer2: null,
        Answer3: null,
        Answer4: null,
        Answer5: null,
        Answer6: null,
        Answer7: null,
        Answer8: null,
        Answer9: null,
        Answer10: null,
        Answer11: null,
        Answer12: null,
        Answer13: null,
        Answer14: null,
        Answer15: null,
        Answer16: null,
        Answer17: null,
        Answer18: null,
        Answer19: null,
        Answer20: null,
        Answer21: null,
        Answer22: null,
        Answer23: null,
        Answer24: null,
        Answer25: null,
        Answer26: null,
        Answer27: null,
        Answer28: null,
        Answer29: null,
        Answer30: null,
      },
    };
  },
  created() {
    this.getLogo(), this.getEvaluation();
  },
  methods: {
    getLocation() {
      return this.baseUrl.replace("api", "");
    },
    getLogo() {
      getLogo({
        uid: this.$route.query.uid,
      }).then((response) => {
        this.logo = response.data;
      });
    },
    getEvaluation() {
      getPostEvaluationByUid({
        uid: this.$route.query.uid,
      }).then((response) => {
        this.evaluation = response.data;
        this.submitButtonVisible = !response.data.isCompleted;
        var parsedQuestions = JSON.parse(this.evaluation.questions);
        parsedQuestions = parsedQuestions.reduce(function (arr, question) {
          if (question.Include) {
            if (question.ResponseType == "Single") {
              question.checkedAnswers = "";
            } else {
              question.checkedAnswers = [];
            }
            question.textAnswer = "";
            arr.push(question);
          }
          return arr;
        }, []);
        for (var i = 0; i < parsedQuestions.length; i++) {
          var question = parsedQuestions[i];
          var answers = [];
          question.NeedAnswer = false;
          var fields = Object.keys(question);
          if (question["Answer1"]) {
            answers.push({
              value: 1,
              label: question["Answer1"],
            });
          }
          if (question["Answer2"]) {
            answers.push({
              value: 2,
              label: question["Answer2"],
            });
          }
          if (question["Answer3"]) {
            answers.push({
              value: 3,
              label: question["Answer3"],
            });
          }
          if (question["Answer4"]) {
            answers.push({
              value: 4,
              label: question["Answer4"],
            });
          }
          if (question["Answer5"]) {
            answers.push({
              value: 5,
              label: question["Answer5"],
            });
          }
          if (question["Answer6"]) {
            answers.push({
              value: 6,
              label: question["Answer6"],
            });
          }
          if (question["Answer7"]) {
            answers.push({
              value: 7,
              label: question["Answer7"],
            });
          }
          if (question["Answer8"]) {
            answers.push({
              value: 8,
              label: question["Answer8"],
            });
          }
          if (question["Answer9"]) {
            answers.push({
              value: 9,
              label: question["Answer9"],
            });
          }
          if (question["Answer10"]) {
            answers.push({
              value: 10,
              label: question["Answer10"],
            });
          }
          if (question["Answer11"]) {
            answers.push({
              value: 11,
              label: question["Answer11"],
            });
          }
          if (question["Answer12"]) {
            answers.push({
              value: 12,
              label: question["Answer12"],
            });
          }
          if (question["Answer13"]) {
            answers.push({
              value: 13,
              label: question["Answer13"],
            });
          }
          if (question["Answer14"]) {
            answers.push({
              value: 14,
              label: question["Answer14"],
            });
          }
          if (question["Answer15"]) {
            answers.push({
              value: 15,
              label: question["Answer15"],
            });
          }
          if (question["Answer16"]) {
            answers.push({
              value: 16,
              label: question["Answer16"],
            });
          }
          if (question["Answer17"]) {
            answers.push({
              value: 17,
              label: question["Answer17"],
            });
          }
          if (question["Answer18"]) {
            answers.push({
              value: 18,
              label: question["Answer18"],
            });
          }
          if (question["Answer19"]) {
            answers.push({
              value: 19,
              label: question["Answer19"],
            });
          }
          if (question["Answer20"]) {
            answers.push({
              value: 20,
              label: question["Answer20"],
            });
          }
          if (question["Answer21"]) {
            answers.push({
              value: 21,
              label: question["Answer21"],
            });
          }
          if (question["Answer22"]) {
            answers.push({
              value: 22,
              label: question["Answer22"],
            });
          }
          if (question["Answer23"]) {
            answers.push({
              value: 23,
              label: question["Answer23"],
            });
          }
          if (question["Answer24"]) {
            answers.push({
              value: 24,
              label: question["Answer24"],
            });
          }
          if (question["Answer25"]) {
            answers.push({
              value: 25,
              label: question["Answer25"],
            });
          }
          if (question["Answer26"]) {
            answers.push({
              value: 26,
              label: question["Answer26"],
            });
          }
          if (question["Answer27"]) {
            answers.push({
              value: 27,
              label: question["Answer27"],
            });
          }
          if (question["Answer28"]) {
            answers.push({
              value: 28,
              label: question["Answer28"],
            });
          }
          if (question["Answer29"]) {
            answers.push({
              value: 29,
              label: question["Answer29"],
            });
          }
          if (question["Answer30"]) {
            answers.push({
              value: 30,
              label: question["Answer30"],
            });
          }

          question.answers = answers;
        }
        console.log(parsedQuestions);
        this.evaluation.questions = parsedQuestions;
      });
    },
    isNumeric: function (n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    },
    handleSubmitAnswers: function () {
      var self = this;
      var answers = {};
      var valid = true;
      for (var i = 0; i < this.evaluation.questions.length; i++) {
        var question = this.evaluation.questions[i];
        self.checkRequiredQuestion(answers, question);
        if (question.NeedAnswer) {
          valid = false;
        }
      }
      if (!valid) {
        return;
      }
      answers = JSON.stringify(answers);
      var data = {
        uid: this.$route.query.uid,
        answers: answers,
      };
      submitPostEvaluationAnswers(data).then((response) => {
        var data = response.data;
        if (data.isOk) {
          this.submitButtonVisible = false;
          this.$notify({
            title: "Success",
            message: "",
            type: "success",
            duration: 1000,
          });

          var evaluationId = data.evaluationId;

          this.$router.push({
            path:
              "/evaluation/evaluation?uid=" + evaluationId + "&ignorePost=true",
          });
        } else {
          this.$notify({
            title:
              "You need " +
              this.evaluation.passingPercent +
              "% correct to pass this test. Please try again",
            message: "",
            type: "error",
            duration: 5000,
          });

          this.getEvaluation();
        }
      });
    },
    checkRequiredQuestion: function (answers, question) {
      if (question.ResponseType === "Single") {
        var result = "";
        if (question.checkedAnswers.length > 0) {
          answers[question.Key] = question.checkedAnswers;
        } else {
          answers[question.Key] = "";
        }
      }
      if (question.ResponseType === "Multiple") {
        answers[question.Key] = question.checkedAnswers
          ? question.checkedAnswers.join()
          : "";
      }
      if (question.ResponseType === "Textarea") {
        answers[question.Key] = question.textAnswer;
      }

      if (question.Required && !answers[question.Key]) {
        question.NeedAnswer = true;
      } else {
        question.NeedAnswer = false;
      }
    },
  },
};
</script>
