<template>
  <div class="app-container">
    <div v-for="template in templates" :key="template.emailType" :value="template">
      <el-row>
        <el-col :span="4">
          <span style="font-weight: bold;">{{template.emailType}}</span>
        </el-col>
        <el-col :span="20">
          <p style='white-space: pre; overflow-x: scroll;'>{{template.emailText}}</p>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<style>
</style>

<style scoped>
</style>

<script>
import { getEmailTemplates as GetEmailTemplates } from "@/api/activities";
import waves from "@/directive/waves";

export default {
  name: "ActivityEmailTemplate",
  directives: { waves },
  data() {
    return {
      templates: []
    };
  },
  created() {
    this.GetTemplates();
  },
  methods: {
    GetTemplates: function() {
      var $this = this;
      GetEmailTemplates({ activityId: this.$route.query.activityId }).then(response => {
        $this.templates = response.data;
      });
    }
  }
};
</script>
