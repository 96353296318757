var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "app-container" },
    [
      _c("p", { staticClass: "aligncenter" }, [
        _c("img", {
          staticStyle: { height: "100px" },
          attrs: { src: "data:image/jpg;base64," + _vm.logo }
        })
      ]),
      _vm._v(" "),
      _vm._m(0),
      _vm._v(" "),
      !_vm.certificateExists
        ? [
            _c(
              "p",
              [
                _vm._v("\n      I would like to claim\n      "),
                _c("el-input-number", {
                  attrs: {
                    precision: 2,
                    step: 0.25,
                    min: 0.25,
                    max: _vm.maxCredits
                  },
                  model: {
                    value: _vm.credits,
                    callback: function($$v) {
                      _vm.credits = $$v
                    },
                    expression: "credits"
                  }
                }),
                _vm._v(
                  "\n      of credit (0.25 - " +
                    _vm._s(_vm.maxCredits) +
                    ")\n    "
                )
              ],
              1
            )
          ]
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { "margin-top": "30px" } },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  _vm.handleDownload()
                }
              }
            },
            [_vm._v("Download certificate")]
          )
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticStyle: { "margin-top": "30px" } },
        [
          _c(
            "el-button",
            {
              attrs: { type: "primary" },
              on: {
                click: function($event) {
                  _vm.handleEmail()
                }
              }
            },
            [_vm._v("Email certificate")]
          )
        ],
        1
      )
    ],
    2
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [
      _c("p", { staticStyle: { "text-align": "center", padding: "30px" } }, [
        _vm._v("Certificates")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }