<template>
  <div class="app-container">
    <el-table
      v-loading="listLoading"
      :key="tableKey"
      :data="list"
      border
      fit
      highlight-current-row
      style="width: 100%"
    >
      <el-table-column prop="Include" label="Include" width="75">
        <template slot-scope="scope">
          <el-checkbox
            :checked="scope.row.isActive"
            @change="handleIncludeCertificate(scope.row, $event)"
          />
        </template>
      </el-table-column>
      <el-table-column label min-width="75px">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="handleUpdate(scope.row)"
            >Edit</el-button
          >
        </template>
      </el-table-column>
      <el-table-column label="Board" align="center" min-width="150px">
        <template slot-scope="scope">
          <span>{{ scope.row.certificateDTO.board }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Degree" min-width="150px" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.certificateDTO.degree.join() }}</span>
        </template>
      </el-table-column>

      <el-table-column label="Credits" min-width="150px" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.credits }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Pharm. Hours" min-width="150px" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.pharmHours }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Location" min-width="150px" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.location }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Speaker's names" min-width="150px" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.speakersNames }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="Educational method"
        min-width="150px"
        align="center"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.educationalMethod }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Completion code" min-width="150px" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.completionCode }}</span>
        </template>
      </el-table-column>
      <el-table-column label="CDE Hours" min-width="150px" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.cdehours }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Level" min-width="150px" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.level }}</span>
        </template>
      </el-table-column>
      <el-table-column label="UAN" min-width="150px" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.uan }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Learning format" min-width="150px" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.learningFormat }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Activity Type" min-width="150px" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.activityType }}</span>
        </template>
      </el-table-column>
      <el-table-column label="Activity Number" min-width="150px" align="center">
        <template slot-scope="scope">
          <span>{{ scope.row.activityNumber }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="AND CDR Registration Number"
        min-width="150px"
        align="center"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.andCdrRegistrationNumber }}</span>
        </template>
      </el-table-column>
      <el-table-column
        label="Performance Indicator(s)"
        min-width="150px"
        align="center"
      >
        <template slot-scope="scope">
          <span>{{ scope.row.performanceIndicator }}</span>
        </template>
      </el-table-column>
    </el-table>

    <el-dialog title="Edit Certificate" :visible.sync="aanpFormVisible">
      <el-form
        ref="aanpDataForm"
        :rules="aanpRules"
        :model="temp"
        label-position="left"
        label-width="170px"
        style="width: 800px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="Board">
              <span>{{ temp.certificateDTO.board }}</span>
            </el-form-item>
            <el-form-item label="Degree">
              <span>{{ temp.certificateDTO.degree.join() }}</span>
            </el-form-item>
            <el-form-item label="Credits" prop="credits">
              <el-input-number
                v-model="temp.credits"
                style="vertical-align: top"
                controls-position="right"
                :precision="2"
                :step="0.25"
                :min="0"
                :max="50"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="Pharm. Hours" prop="pharmHours">
              <el-input-number
                v-model="temp.pharmHours"
                style="vertical-align: top"
                controls-position="right"
                :precision="2"
                :step="0.1"
                :min="0"
                :max="1000"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="Location" prop="location">
              <el-input v-model="temp.location" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="aanpFormVisible = false">{{
          $t("table.cancel")
        }}</el-button>
        <el-button type="primary" @click="updateData">{{
          $t("table.confirm")
        }}</el-button>
      </div>
    </el-dialog>

    <el-dialog title="Edit Certificate" :visible.sync="accmeFormVisible">
      <el-form
        ref="accmeDataForm"
        :rules="accmeRules"
        :model="temp"
        label-position="left"
        label-width="170px"
        style="width: 800px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="Board">
              <span>{{ temp.certificateDTO.board }}</span>
            </el-form-item>
            <el-form-item label="Degree">
              <span>{{ temp.certificateDTO.degree.join() }}</span>
            </el-form-item>
            <el-form-item label="Credits" prop="credits">
              <el-input-number
                v-model="temp.credits"
                style="vertical-align: top"
                controls-position="right"
                :precision="2"
                :step="0.25"
                :min="0"
                :max="50"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="Location" prop="location">
              <el-input v-model="temp.location" />
            </el-form-item>
            <el-form-item label="Activity type" prop="activityType">
              <el-input v-model="temp.activityType" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="accmeFormVisible = false">{{
          $t("table.cancel")
        }}</el-button>
        <el-button type="primary" @click="updateData">{{
          $t("table.confirm")
        }}</el-button>
      </div>
    </el-dialog>

    <el-dialog title="Edit Certificate" :visible.sync="acpeFormVisible">
      <el-form
        ref="acpeDataForm"
        :rules="acpeRules"
        :model="temp"
        label-position="left"
        label-width="170px"
        style="width: 800px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="Board">
              <span>{{ temp.certificateDTO.board }}</span>
            </el-form-item>
            <el-form-item label="Degree">
              <span>{{ temp.certificateDTO.degree.join() }}</span>
            </el-form-item>
            <el-form-item label="Credits" prop="credits">
              <el-input-number
                v-model="temp.credits"
                style="vertical-align: top"
                controls-position="right"
                :precision="2"
                :step="0.25"
                :min="0"
                :max="50"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="Location" prop="location">
              <el-input v-model="temp.location" />
            </el-form-item>
            <el-form-item label="UAN " prop="uan">
              <el-input v-model="temp.uan" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="acpeFormVisible = false">{{
          $t("table.cancel")
        }}</el-button>
        <el-button type="primary" @click="updateData">{{
          $t("table.confirm")
        }}</el-button>
      </div>
    </el-dialog>

    <el-dialog title="Edit Certificate" :visible.sync="andcdrFormVisible">
      <el-form
        ref="andcdrDataForm"
        :rules="andcdrRules"
        :model="temp"
        label-position="left"
        label-width="170px"
        style="width: 800px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="Board">
              <span>{{ temp.certificateDTO.board }}</span>
            </el-form-item>
            <el-form-item label="Degree">
              <span>{{ temp.certificateDTO.degree.join() }}</span>
            </el-form-item>
            <el-form-item label="Location" prop="location">
              <el-input v-model="temp.location" />
            </el-form-item>
            <el-form-item label="Credits" prop="credits">
              <el-input-number
                v-model="temp.credits"
                style="vertical-align: top"
                controls-position="right"
                :precision="2"
                :step="0.25"
                :min="0"
                :max="50"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="CDE Hours" prop="cdehours">
              <el-input-number
                v-model="temp.cdehours"
                style="vertical-align: top"
                controls-position="right"
                :precision="2"
                :step="0.25"
                :min="0"
                :max="1000"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="Level" prop="level">
              <el-input v-model="temp.level" />
            </el-form-item>
            <el-form-item label="Activity Number" prop="activityNumber">
              <el-input v-model="temp.activityNumber" />
            </el-form-item>
            <el-form-item
              label="AND CDR Registration Number"
              prop="andCdrRegistrationNumber"
            >
              <el-input v-model="temp.andCdrRegistrationNumber" />
            </el-form-item>
            <el-form-item
              label="Performance Indicator(s)"
              prop="performanceIndicator"
            >
              <el-input v-model="temp.performanceIndicator" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="andcdrFormVisible = false">{{
          $t("table.cancel")
        }}</el-button>
        <el-button type="primary" @click="updateData">{{
          $t("table.confirm")
        }}</el-button>
      </div>
    </el-dialog>

    <el-dialog title="Edit Certificate" :visible.sync="agdFormVisible">
      <el-form
        ref="agdDataForm"
        :rules="agdRules"
        :model="temp"
        label-position="left"
        label-width="170px"
        style="width: 800px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="Board">
              <span>{{ temp.certificateDTO.board }}</span>
            </el-form-item>
            <el-form-item label="Degree">
              <span>{{ temp.certificateDTO.degree.join() }}</span>
            </el-form-item>
            <el-form-item label="Credits" prop="credits">
              <el-input-number
                v-model="temp.credits"
                style="vertical-align: top"
                controls-position="right"
                :precision="2"
                :step="0.25"
                :min="0"
                :max="50"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="Location" prop="location">
              <el-input v-model="temp.location" />
            </el-form-item>
            <el-form-item label="Speaker's names" prop="speakersNames">
              <el-input v-model="temp.speakersNames" />
            </el-form-item>
            <el-form-item label="Educational method" prop="educationalMethod">
              <el-input v-model="temp.educationalMethod" />
            </el-form-item>
            <el-form-item label="Completion code" prop="completionCode">
              <el-input v-model="temp.completionCode" />
            </el-form-item>
            <el-form-item label="CDE Hours" prop="cdehours">
              <el-input-number
                v-model="temp.cdehours"
                style="vertical-align: top"
                controls-position="right"
                :precision="2"
                :step="0.25"
                :min="0"
                :max="1000"
              ></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="agdFormVisible = false">{{
          $t("table.cancel")
        }}</el-button>
        <el-button type="primary" @click="updateData">{{
          $t("table.confirm")
        }}</el-button>
      </div>
    </el-dialog>

    <el-dialog title="Edit Certificate" :visible.sync="anccFormVisible">
      <el-form
        ref="anccDataForm"
        :rules="anccRules"
        :model="temp"
        label-position="left"
        label-width="170px"
        style="width: 800px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="Board">
              <span>{{ temp.certificateDTO.board }}</span>
            </el-form-item>
            <el-form-item label="Pharm. Hours" prop="pharmHours">
              <el-input-number
                v-model="temp.pharmHours"
                style="vertical-align: top"
                controls-position="right"
                :precision="2"
                :step="0.25"
                :min="0"
                :max="1000"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="Degree">
              <span>{{ temp.certificateDTO.degree.join() }}</span>
            </el-form-item>
            <el-form-item label="Location" prop="location">
              <el-input v-model="temp.location" />
            </el-form-item>
            <el-form-item label="Credits" prop="credits">
              <el-input-number
                v-model="temp.credits"
                style="vertical-align: top"
                controls-position="right"
                :precision="2"
                :step="0.25"
                :min="0"
                :max="50"
              ></el-input-number>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="anccFormVisible = false">{{
          $t("table.cancel")
        }}</el-button>
        <el-button type="primary" @click="updateData">{{
          $t("table.confirm")
        }}</el-button>
      </div>
    </el-dialog>

    <el-dialog title="Edit Certificate" :visible.sync="apaFormVisible">
      <el-form
        ref="apaDataForm"
        :rules="apaRules"
        :model="temp"
        label-position="left"
        label-width="170px"
        style="width: 800px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="Board">
              <span>{{ temp.certificateDTO.board }}</span>
            </el-form-item>
            <el-form-item label="Degree">
              <span>{{ temp.certificateDTO.degree.join() }}</span>
            </el-form-item>
            <el-form-item label="Credits" prop="credits">
              <el-input-number
                v-model="temp.credits"
                style="vertical-align: top"
                controls-position="right"
                :precision="2"
                :step="0.25"
                :min="0"
                :max="50"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="Location" prop="location">
              <el-input v-model="temp.location" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="apaFormVisible = false">{{
          $t("table.cancel")
        }}</el-button>
        <el-button type="primary" @click="updateData">{{
          $t("table.confirm")
        }}</el-button>
      </div>
    </el-dialog>

    <el-dialog title="Edit Certificate" :visible.sync="accmeOtherFormVisible">
      <el-form
        ref="accmeOtherDataForm"
        :rules="accmeOtherRules"
        :model="temp"
        label-position="left"
        label-width="170px"
        style="width: 800px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="Board">
              <span>{{ temp.certificateDTO.board }}</span>
            </el-form-item>
            <el-form-item label="Degree">
              <span>{{ temp.certificateDTO.degree.join() }}</span>
            </el-form-item>
            <el-form-item label="Credits" prop="credits">
              <el-input-number
                v-model="temp.credits"
                style="vertical-align: top"
                controls-position="right"
                :precision="2"
                :step="0.25"
                :min="0"
                :max="50"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="Location" prop="location">
              <el-input v-model="temp.location" />
            </el-form-item>
            <el-form-item label="Learning Format" prop="learningFormat">
              <el-input v-model="temp.learningFormat" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="accmeOtherFormVisible = false">{{
          $t("table.cancel")
        }}</el-button>
        <el-button type="primary" @click="updateData">{{
          $t("table.confirm")
        }}</el-button>
      </div>
    </el-dialog>

    <el-dialog title="Edit Certificate" :visible.sync="otherFormVisible">
      <el-form
        ref="otherDataForm"
        :rules="otherRules"
        :model="temp"
        label-position="left"
        label-width="170px"
        style="width: 800px"
      >
        <el-row>
          <el-col :span="12">
            <el-form-item label="Board">
              <span>{{ temp.certificateDTO.board }}</span>
            </el-form-item>
            <el-form-item label="Degree">
              <span>{{ temp.certificateDTO.degree.join() }}</span>
            </el-form-item>
            <el-form-item label="Credits" prop="credits">
              <el-input-number
                v-model="temp.credits"
                style="vertical-align: top"
                controls-position="right"
                :precision="2"
                :step="0.25"
                :min="0"
                :max="50"
              ></el-input-number>
            </el-form-item>
            <el-form-item label="Location" prop="location">
              <el-input v-model="temp.location" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="otherFormVisible = false">{{
          $t("table.cancel")
        }}</el-button>
        <el-button type="primary" @click="updateData">{{
          $t("table.confirm")
        }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<style>
.intervalsContainer .el-form-item__content {
  margin-left: 0 !important;
}
</style>

<style scoped>
.grid-content {
  height: 36px;
  line-height: 36px;
}

.intervalsContainer {
  border: 1px solid #ebeef5;
  border-radius: 5px;
  padding: 0 10px;
  margin-left: 10px;
}

.intervalCheckbox {
  padding-left: 5px;
  margin-left: 5px;
  width: 100%;
  line-height: 40px;
  height: 40px;
}

.intervalCheckbox-even {
  background: #ebeef5;
}

.light-label {
  font-weight: 100 !important;
}
</style>

<script>
import {
  baseUrl,
  search,
  getCertificatesForActivity as GetCertificatesForActivity,
  updateActivityCertificate as UpdateActivityCertificate,
  downloadCertificate as DownloadCertificate,
} from "@/api/activities";
import waves from "@/directive/waves"; // Waves directive

export default {
  name: "CertificatesGrid",
  directives: { waves },
  data() {
    return {
      uploadBaseUrl: baseUrl,
      tableKey: 533,
      list: [],
      total: 0,
      listLoading: true,
      temp: {
        id: undefined,
        activityId: undefined,
        credits: null,
        certificateDTO: {
          id: 0,
          board: "",
          degree: [""],
        },
        pharmHours: null,
        location: "",
        speakersNames: "",
        educationalMethod: [],
        completionCode: "",
        cdehours: "",
        level: null,
        aan: null,
        learningFormat: null,
        activityType: null,
        activityNumber: null,
        andCdrRegistrationNumber: null,
        performanceIndicator: null,
      },
      downloadLoading: false,
      aanpFormVisible: false,
      accmeFormVisible: false,
      acpeFormVisible: false,
      andcdrFormVisible: false,
      agdFormVisible: false,
      anccFormVisible: false,
      apaFormVisible: false,
      accmeOtherFormVisible: false,
      otherFormVisible: false,
      aanpRules: {
        credits: [
          {
            required: true,
            message: "Credits are required",
            trigger: "change",
          },
        ],
        pharmHours: [
          {
            required: true,
            message: "Pharm. Hours are required",
            trigger: "change",
          },
        ],
      },
      accmeRules: {
        credits: [
          {
            required: true,
            message: "Credits are required",
            trigger: "change",
          },
        ],
        activityType: [
          {
            required: true,
            message: "Activity type is required",
            trigger: "change",
          },
        ],
      },
      acpeRules: {
        credits: [
          {
            required: true,
            message: "Credits are required",
            trigger: "change",
          },
        ],
        uan: [
          { required: true, message: "UAN is required", trigger: "change" },
        ],
      },
      andcdrRules: {
        credits: [
          {
            required: true,
            message: "Credits are required",
            trigger: "change",
          },
        ],
        cdehours: [
          {
            required: true,
            message: "CDE Hours are required",
            trigger: "change",
          },
        ],
        level: [
          { required: true, message: "Level is required", trigger: "change" },
        ],
        activityNumber: [
          { required: true, message: "Activity Number", trigger: "change" },
        ],
        andCdrRegistrationNumber: [
          {
            required: true,
            message: "AND CDR Registration Number",
            trigger: "change",
          },
        ],
        performanceIndicator: [
          {
            required: true,
            message: "Performance Indicator(s)",
            trigger: "change",
          },
        ],
      },
      agdRules: {
        credits: [
          {
            required: true,
            message: "Credits are required",
            trigger: "change",
          },
        ],
        speakersNames: [
          {
            required: true,
            message: "Speaker's names are required",
            trigger: "change",
          },
        ],
        educationalMethod: [
          {
            required: true,
            message: "Educational method is required",
            trigger: "change",
          },
        ],
        completionCode: [
          {
            required: true,
            message: "Completion code is required",
            trigger: "change",
          },
        ],
        cdehours: [
          {
            required: true,
            message: "CDE Hours are required",
            trigger: "change",
          },
        ],
      },
      anccRules: {
        credits: [
          {
            required: true,
            message: "Credits are required",
            trigger: "change",
          },
        ],
      },
      apaRules: {
        credits: [
          {
            required: true,
            message: "Credits are required",
            trigger: "change",
          },
        ],
      },
      otherRules: {
        credits: [
          {
            required: true,
            message: "Credits are required",
            trigger: "change",
          },
        ],
      },
      accmeOtherRules: {
        credits: [
          {
            required: true,
            message: "Credits are required",
            trigger: "change",
          },
        ],
        learningFormat: [
          {
            required: true,
            message: "Learning Format is required",
            trigger: "change",
          },
        ],
      },
    };
  },
  created() {
    this.getCertificatesForActivity();
  },
  methods: {
    getCertificatesForActivity() {
      this.listLoading = true;

      GetCertificatesForActivity({
        activityId: this.$route.query.activityId,
      }).then((response) => {
        this.list = response.data;
        this.listLoading = false;
      });
    },
    resetTemp() {
      this.temp = {
        id: undefined,
        activityId: undefined,
        credits: null,
        certificateDTO: {
          board: "",
          degree: [""],
        },
        pharmHours: null,
        location: "",
        speakersNames: "",
        educationalMethod: [],
        completionCode: "",
        cdehours: "",
        level: null,
        aan: null,
        learningFormat: null,
        activityType: null,
        activityNumber: null,
        andCdrRegistrationNumber: null,
        performanceIndicator: null,
      };
    },
    handleUpdate(row) {
      this.temp = Object.assign({}, row);
      this.$nextTick(() => {
        for (var i = 0; i < this.$refs.length; i++) {
          this.$refs[i].clearValidate();
        }
      });
      if (this.temp.certificateDTO.board === "AANP") {
        this.aanpFormVisible = true;
      } else if (this.temp.certificateDTO.board === "ACCME") {
        this.accmeFormVisible = true;
      } else if (this.temp.certificateDTO.board === "ACPE") {
        this.acpeFormVisible = true;
      } else if (this.temp.certificateDTO.board === "AND CDR") {
        this.andcdrFormVisible = true;
      } else if (this.temp.certificateDTO.board === "AGD") {
        this.agdFormVisible = true;
      } else if (this.temp.certificateDTO.board === "ANCC") {
        this.anccFormVisible = true;
      } else if (this.temp.certificateDTO.board === "APA") {
        this.apaFormVisible = true;
      } else if (this.temp.certificateDTO.board === "OTHER") {
        this.otherFormVisible = true;
      } else if (this.temp.certificateDTO.board === "ACCME Other") {
        this.accmeOtherFormVisible = true;
      }
    },
    updateData() {
      var refs;
      if (this.temp.certificateDTO.board === "AANP") {
        refs = this.$refs["aanpDataForm"];
      } else if (this.temp.certificateDTO.board === "ACCME") {
        refs = this.$refs["accmeDataForm"];
      } else if (this.temp.certificateDTO.board === "ACPE") {
        refs = this.$refs["acpeDataForm"];
      } else if (this.temp.certificateDTO.board === "AND CDR") {
        refs = this.$refs["andcdrDataForm"];
      } else if (this.temp.certificateDTO.board === "AGD") {
        refs = this.$refs["agdDataForm"];
      } else if (this.temp.certificateDTO.board === "ANCC") {
        refs = this.$refs["anccDataForm"];
      } else if (this.temp.certificateDTO.board === "APA") {
        refs = this.$refs["apaDataForm"];
      } else if (this.temp.certificateDTO.board === "OTHER") {
        refs = this.$refs["otherDataForm"];
      } else if (this.temp.certificateDTO.board === "ACCME Other") {
        refs = this.$refs["accmeOtherDataForm"];
      }

      refs.validate((valid) => {
        if (valid) {
          UpdateActivityCertificate(this.temp).then(() => {
            this.aanpFormVisible = false;
            this.accmeFormVisible = false;
            this.acpeFormVisible = false;
            this.andcdrFormVisible = false;
            this.agdFormVisible = false;
            this.anccFormVisible = false;
            this.apaFormVisible = false;
            this.accmeOtherFormVisible = false;
            this.otherFormVisible = false;
            this.$notify({
              title: "Certificate updated",
              message: "",
              type: "success",
              duration: 1000,
            });
            this.getCertificatesForActivity();
          });
        }
      });
    },
    handleIncludeCertificate: function (certificate, checked) {
      if (!checked) {
        certificate.isActive = false;
      } else {
        certificate.isActive = true;
      }
      UpdateActivityCertificate(certificate).then(() => {
        this.getCertificatesForActivity();
        this.$notify({
          title: "Certificate updated",
          message: "",
          type: "success",
          duration: 1000,
        });
      });
    },
    isNumber: function (evt) {
      evt = evt || window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
  },
};
</script>
