var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "navbar" },
    [
      _c("hamburger", {
        staticClass: "hamburger-container",
        attrs: {
          "toggle-click": _vm.toggleSideBar,
          "is-active": _vm.sidebar.opened
        }
      }),
      _vm._v(" "),
      _c("breadcrumb", { staticClass: "breadcrumb-container" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "right-menu" },
        [
          _vm.device !== "mobile"
            ? [
                _c(
                  "el-dropdown",
                  {
                    staticClass: "avatar-container right-menu-item",
                    attrs: { trigger: "click" }
                  },
                  [
                    _c("div", { staticClass: "avatar-wrapper" }, [
                      _c("img", {
                        staticClass: "user-avatar",
                        attrs: { src: require("@/assets/user.png") }
                      }),
                      _vm._v(" "),
                      _c("i", { staticClass: "el-icon-caret-bottom" })
                    ]),
                    _vm._v(" "),
                    _c(
                      "el-dropdown-menu",
                      { attrs: { slot: "dropdown" }, slot: "dropdown" },
                      [
                        _c("el-dropdown-item", [
                          _c(
                            "span",
                            {
                              staticStyle: { display: "block" },
                              on: { click: _vm.logout }
                            },
                            [_vm._v(_vm._s(_vm.$t("navbar.logOut")))]
                          )
                        ])
                      ],
                      1
                    )
                  ],
                  1
                )
              ]
            : _vm._e()
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }